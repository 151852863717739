import Vue from 'vue';
import App from './App.vue';
import VueCookies from 'vue-cookies';
import router from './router';
import store from './store';
import auth from './auth';
import axios from 'axios';
import VueAxios from 'vue-axios';
import servers from './servers';
import VueToast from 'vue-toast-notification';
import errorHandler from './utils/errorHandler';
import Editor from 'v-markdown-editor';

import VueGtag from "vue-gtag";
import Track from './utils/tracking.js';
import mixin from './mixins/globalMixins';
import VueSessionStorage from 'vue-sessionstorage';
import GAuth from 'vue-google-oauth2';
import "external-svg-loader";

Vue.use(GAuth, {
  clientId: `${process.env.VUE_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
  scope: 'profile email',
  prompt: 'select_account'
})


Object.defineProperty(Vue.prototype, '$auth', {value: auth});
Object.defineProperty(Vue.prototype, '$axios', {value: axios});
Object.defineProperty(Vue.prototype, '$servers', {value: servers});

axios.defaults.baseURL = servers.server();
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    errorHandler(err);
    return Promise.reject(err.response);
  }
);

Vue.use(VueToast, {
  position: 'top',
  duration: 5000
});

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_MEASUREMENT_ID
  }
}, router);


Vue.use(Track);

Vue.use(Editor);

Vue.use(VueAxios, axios);

Vue.use(VueCookies);

Vue.config.productionTip = false;

Vue.mixin(mixin);

Vue.use(VueSessionStorage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
