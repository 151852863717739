<template>
  <div class="weights-list">
    <div
      v-for="weight in computedWeights"
      :key="weight.color"
      :class="{
        'weights-list__item--selected' : selectedKey === weight.name
      }"
      class="weights-list__item"
      @click="onClickAction(weight.name)"
    >
      {{ weight.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeightsList',
  data() {
    return {
      selectedKey: null
    };
  },
  computed: {
    computedWeights() {
      /*eslint-disable*/
      return Object.keys(this.weights)
        .map((key) => {
          this.weights[key].name = key;
          return this.weights[key]
        })
        .filter(weight => weight.isAvailable);
    }
  },
  props: {
    weights: Object,
  },
  methods: {
    onClickAction(key) {

      if (this.selectedKey === key) {
        this.selectedKey = null;

        this.$emit('weightSelected', null)

      } else {
        this.selectedKey = key;
        this.$emit('weightSelected', key)

      }

    },
    reset() {
      this.selectedKey = null;
      this.$emit('weightSelected', null)
    },
  },
}
</script>

<style lang="scss" src="./WeightsList.scss"/>

