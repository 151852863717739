<style lang="scss" scoped>
$medium: 1220px;
$tablet: 768px;
$tabletMini: 700px;
$phone: 500px;
.wrap {
    height: 220px;
    min-width: 303px;
    max-width: 303px;

    display: inline-block;
    overflow: hidden;
    &:hover {
        overflow: visible;
    }
}
.courses__card {
    width: 100%;
    max-height: 220px;
    background: #ffffff;
    border: none;
    position: relative;
    padding: 10px 40px;
    padding-top: 0px;

    transition: 0.5s;
}
.active .courses__card {
    &:hover {
        position: relative;
        z-index: 5;
        max-height: 700px;
        filter: drop-shadow(0px 40px 50px rgba(94, 118, 159, 0.2));
        // height: 100%;
        // overflow: visible;
    }
}
.card__title {
    padding-top: 20px;
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #404c61;

    /* identical to box height */

    text-transform: uppercase;
}
.card__title--sub {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;

    /* grey */

    color: #808fb0;
}
.card__stats {
    display: block;
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    transition: 0.5s;
    /* dark */
    position: relative;
    top: -150px;
    color: #404c61;
    opacity: 0;
}
.card__stat {
    margin-top: 8px;
}
.active .card__stats {
    opacity: 1;
    display: block;
    top: 0px;
}
.card__stats--sub {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    /* grey */

    color: #808fb0;
}
.card__divider--horizontal {
    border: 1px solid #dde5f3;
    margin: 0 30px;
}
.card__divider--vertical {
    border: 1px solid #dde5f3;
}
.card__action {
    display: grid;
    margin-top: 22px;
    grid-template-columns: 1fr 1px 1fr;
}
.card__button {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 15px 25px;
    gap: 10px;
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0 30px;
    /* dark */

    color: #404c61;
    /* dividers */

    border: 2px solid #dde5f3;
    border-radius: 100px;
    margin-top: 20px;
    margin-bottom: 33px;
    &:hover {
        font-family: 'proxima';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        border-color: #12b7db;
        /* blue */

        color: #12b7db;
    }
}
.card__placeholder {
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    margin-bottom: 80px;
}
.wrap:hover .card__placeholder {
    margin-bottom: 0px;
}
.card__statistic--main {
    margin-top: 40px;
    position: relative;
    z-index: 2;
}
.card__statistic--main__title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    /* blue */

    color: #12b7db;
}
.card__statistic__title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    /* dark */

    color: #404c61;
}
.card__statistic--value {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    text-transform: uppercase;

    /* blue */

    color: #12b7db;
}
.card__margin-bottom {
    margin-bottom: 80px;
    &:hover {
        margin-bottom: 0px;
    }
}
.card__statistic__progress_placeholder {
    height: 5px;
    background-color: #f2f5fa;
    border-radius: 100px;
    margin-top: 10px;
}
.card__statistic_progress--value {
    height: 5px;
    background-color: #404c61;
    position: relative;
    border-radius: 100px;
    top: -5px;
}
@media screen and (max-width: $medium) {
    .wrap {
        min-width: 220px;
        width: 32.6%;
        max-width: none;
    }
}
@media screen and (max-width: $tablet) {
    .wrap {
        min-width: 200px;
        width: 49.5%;
        max-width: none;
    }
}

@media screen and (max-width: $tabletMini) {
    .wrap {
        min-width: 220px;
        width: 49.5%;
        max-width: none;
    }
}
@media screen and (max-width: $phone) {
    .wrap {
        width: 100%;
    }
}
</style>

<template>
    <div
        :class="['wrap', isActive ? 'active' : '']"
        @mouseover="isMobile ? null : (isActive = true)"
        @mouseleave="isMobile ? null : (isActive = false)"
        @click="
            !isMobile ? null : isActive ? (isActive = false) : (isActive = true)
        "
    >
        <div :class="['courses__card']">
            <div class="card__placeholder">
                <div class="card__title">
                    {{ $props.item.localization[lang].name }}
                </div>
                <div class="card__statistic--main">
                    <div
                        class="card__statistic--main__title"
                        v-if="$props.item.levels[0]"
                    >
                        {{ getProgress($props.item.levels[0].progress) }}%
                    </div>
                    <div
                        v-if="$props.item.levels[0]"
                        class="card__statistic__progress_placeholder"
                    ></div>
                    <div
                        class="card__statistic_progress--value"
                        v-if="$props.item.levels[0]"
                        :style="{
                            width:
                                getProgress($props.item.levels[0].progress) +
                                '%',
                        }"
                    ></div>
                </div>
            </div>

            <div class="card__stats">
                <div
                    class="card__stat"
                    v-for="item in $props.item.levels.slice(1)"
                    :key="item.id"
                >
                    <div style="display: flex; justify-content: space-between;">
                        <div class="card__statistic__title">
                            {{ item.localization[lang].name }}
                        </div>
                        <div class="card__statistic--value">
                            {{ getProgress(item.progress) }}%
                        </div>
                    </div>
                    <div class="card__statistic__progress_placeholder"></div>
                    <div
                        class="card__statistic_progress--value"
                        :style="{ width: getProgress(item.progress) + '%' }"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import marked from 'marked'
export default {
    name: 'CoursesCard',

    props: ['item'],
    data() {
        return {
            isActive: false,
            isMobile: false,
        }
    },
    computed: {
        lang() {
            return this.$store.state.ui.activeLang
        },
    },
    methods: {
        marked(text) {
            return marked(text)
        },
        mq() {
            this.isMobile = window.matchMedia('(max-width: 760px)').matches
        },
        getProgress(progress) {
            return (progress * 100).toString().slice(0, 2)
        },
    },

    created() {},

    beforeDestroy() {
        window.removeEventListener('resize', this.mq)
    },
    mounted() {
        window.addEventListener('resize', this.mq)
    },
}
</script>
