import { mapGetters } from 'vuex';

import Slider from '@/components/RangeSlider';
import WeightSlider from '@/components/WeightSlider';
import WeightsList from '@/components/WeightsList';
import OptionsSelector from '@/components/UI/OptionsSelector';
import { Expander, ExpanderItem } from '@/components/UI/Expander';
import RangeTableEditor from '@/components/RangeTableEditor';
import RangeStringParser from '@/components/RangeStringParser';

import ItemRangeOptionsEditor from '@/components/ItemOptionsEditor/ItemRangeOptionsEditor';
import ItemGroupOptionsEditor from '@/components/ItemOptionsEditor/ItemGroupOptionsEditor';

import CreateGroupDialog from '@/components/CreateGroupDialog';
import CreateRangeDialog from '@/components/CreateRangeDialog';
import DeleteItemsDialog from '@/components/DeleteItemsDialog';

import List from '@/components/UI/List';

import {
  RANGES_VIEW_EDITOR,
  RANGES_VIEW_LIST,
  RangeViewChanger
} from '@/components/RangeViewChanger';
import { ITEM_TYPE_GROUP, ITEM_TYPE_RANGE } from '@/constants/itemTypes';
import { DEFAULT_PARENT_ID } from '@/constants/items';

export default {
  components: {
    CreateGroupDialog,
    CreateRangeDialog,
    DeleteItemsDialog,

    ItemRangeOptionsEditor,
    ItemGroupOptionsEditor,

    RangeStringParser,
    RangeTableEditor,

    ExpanderItem,
    Expander,
    OptionsSelector,
    WeightsList,
    WeightSlider,
    Slider,

    List,

    RangeViewChanger
  },
  data() {
    return {
      linkedItems: [],
      linkingMode: false,

      updateStatisticsRequestPending: false,

      currentItem: null,
      selectedItems: [],

      showDeleteDialog: false,
      showCreateGroupDialog: false,
      showCreateRangeDialog: false,

      rangesFooterCurrentView: RANGES_VIEW_LIST,

      rangesOfGroup: []
    };
  },

  computed: {
    ...mapGetters({ actionColors: 'user/actionColors' }),
    menuItemsForCreating() {
      return [
        {
          label: this.localization.general.MainPage.CreateGroupButton,
          handler: this.openAddGroup,
          icon: '/icons/group1.svg'
        },
        {
          label: this.localization.general.MainPage.CreateRangeButton,
          handler: this.openAddDiapason,
          icon: '/icons/diapason1.svg'
        },
        {
          label: this.localization.general.MainPage.CopyButton,
          disabled: !(this.currentItem && this.currentItem.type),
          handler: this.copyItem,
          async: true,
          icon: '/icons/copy.svg'
        }
      ];
    },

    computedSelectedItems() {
      if (this.selectedItems.length === 0 && !this.currentItem) return [];

      return this.selectedItems.length > 0
        ? this.selectedItems
        : [this.currentItem];
    },
    checkIfWeightSelected() {
      return this.selectedWeight != null;
    },

    isRange() {
      return this.currentItem?.type === ITEM_TYPE_RANGE;
    },
    isGroup() {
      return this.currentItem?.type === ITEM_TYPE_GROUP;
    },
    currentItemParentId() {
      if (this.isGroup) {
        console.log('GROUP', this.currentItem.id);
        return this.currentItem.id;
      }

      if (this.isRange) {
        console.log('Range', this.currentItem.parentId);
        return this.currentItem.parentId;
      }

      return DEFAULT_PARENT_ID;
    },
    options() {
      return [
        {
          key: 'weight',
          text: this.localization.general.MainPage.FillingWeights
        },
        {
          key: 'test',
          text: this.localization.general.MainPage.SelectingHandsForTest
        }
      ];
    },
    rangesFooterViewEditor() {
      return RANGES_VIEW_EDITOR;
    },
    rangesFooterViewList() {
      return RANGES_VIEW_LIST;
    },
    isBlocked() {
      return !this.currentItem;
    }
  },
  methods: {
    onLinkedItemsChange(items) {
      this.linkedItems = items;
    },
    onLinkingModeToggle(linkingMode) {
      this.linkingMode = linkingMode;
    },
    linkItem(item) {
      let indexOfLinkedItems = this.linkedItems.findIndex(
        linkedItem => item.id === linkedItem.id
      );
      if (indexOfLinkedItems === -1) {
        this.linkedItems.push(item);
      } else {
        this.linkedItems.splice(indexOfLinkedItems, 1);
      }

      this.$refs.rangeEditor.passLinkedItems(this.linkedItems);
    },
    itemIsLinked(item) {
      return this.linkedItems.find(linkedItem => linkedItem.id === item.id);
    },
    sortItems(items) {
      return items.sort((a, b) => {
        if (a.value.name === 'store') {
          return -1;
        }
        if (b.value.name === 'store') {
          return 1;
        }

        if (a.value.type === b.value.type) {
          return a.value.order < b.value.order ? -1 : 1;
        }

        if (a.value.type === ITEM_TYPE_GROUP) {
          return -1;
        }

        if (a.value.type === ITEM_TYPE_RANGE) {
          return 0;
        }
      });
    },
    //TODO:
    addGroupParticipantsToPlay(ranges) {
      console.log(ranges, 'AGP: add ranges in addGroup');
      for (let item of ranges) {
        console.log(item, 'AGP: ITEM IN RANGES');
        let val = item.diapason.additionalProperties.hp;
        let tempHeroObj = {};
        tempHeroObj[item.id] = val;
        this.activeParicipantsToPlay = {
          ...this.activeParicipantsToPlay,
          ...tempHeroObj
        };
      }
      console.log('AGP: activeParticipants', this.activeParicipantsToPlay);
    },
    onGroupOpen(ranges) {
      console.log(ranges, 'ranges on open');
      this.addGroupParticipantsToPlay(ranges);
      this.rangesOfGroup = ranges;
    },
    itemParentChangeHandler(data, request) {
      let resetListOrder = false;
      let newOrder = 0;

      // IF GROUP IS EMPTY, THEN JUST SET ORDER TO 1000
      if (data.newParentChildren.length === 0) {
        data.droppedItem.order = 1000;
      } else {
        /*
          DRAG TYPES
          1. Diapason to Main && Diapason to Group
          3. Diapason to Diapason
          4. Group to Diapason
          5. Group to Group
        */

        if (
          (data.droppedItem.type === 'Diapason' &&
            data.toItem.type === 'Main') ||
          (data.droppedItem.type === 'Diapason' && data.toItem.type === 'Group')
        ) {
          let firstRange;
          // FIRSTLY, CHECK IF GROUP IN PARENT CHILDREN ARRAY EXISTS, THEN GET LAST GROUP INDEX
          // IF GROUPS NOT EXIST IN ARRAY, GET FIRST INDEX OF PARENT CHILDREN ARRAY
          let ranges = data.newParentChildren.filter(
            item => item.type === 'Diapason'
          );
          if (ranges.length > 0) {
            firstRange = ranges.reduce((prev, next) => {
              return prev.order < next.order ? prev : next;
            });
          } else {
            firstRange = data.newParentChildren[0];
          }

          if (firstRange) {
            newOrder = firstRange.order / 2;
            resetListOrder = firstRange.order - newOrder <= 1;
          } else {
            newOrder = 1000;
          }
        } else if (
          data.droppedItem.type === 'Diapason' &&
          data.toItem.type === 'Diapason'
        ) {
          let toItemIndex = data.newParentChildren.findIndex(
            item => item.id === data.toItem.id
          );
          let nextToItem = data.newParentChildren[toItemIndex + 1];

          if (nextToItem) {
            newOrder = (data.toItem.order + nextToItem.order) / 2;
            resetListOrder = nextToItem.order - newOrder <= 1;
          } else {
            newOrder = data.toItem.order + 1000;
          }
        } else if (
          data.droppedItem.type === 'Group' &&
          data.toItem.type === 'Diapason'
        ) {
          let groups = data.newParentChildren.filter(
            item => item.type === 'Group'
          );
          if (groups.length > 0) {
            let lastGroup = groups.reduce((prev, next) => {
              return prev.order > next.order ? prev : next;
            });
            newOrder = lastGroup.order + 1000;
          } else {
            newOrder = 1000;
          }
        } else if (
          data.droppedItem.type === 'Group' &&
          data.toItem.type === 'Main'
        ) {
          let groups = data.newParentChildren.filter(
            item => item.type === 'Group'
          );
          if (groups.length > 0) {
            let firstGroup = groups.reduce((prev, next) => {
              return prev.order < next.order ? prev : next;
            });
            newOrder = firstGroup.order / 2;
            resetListOrder = firstGroup.order - newOrder <= 1;
          } else {
            newOrder = 1000;
          }
        } else if (
          data.droppedItem.type === 'Group' &&
          data.toItem.type === 'Group'
        ) {
          if (data.groupOpened) {
            let groups = data.newParentChildren.filter(item => {
              return item.type === 'Group' && item.id !== data.droppedItem.id;
            });
            if (groups.length > 0) {
              let firstGroup = groups.reduce((prev, next) => {
                return prev.order < next.order ? prev : next;
              });
              newOrder = firstGroup.order / 2;
              resetListOrder = firstGroup.order - newOrder <= 1;
            } else {
              newOrder = 1000;
            }
          } else {
            let toItemIndex = data.newParentChildren.findIndex(
              item => item.id === data.toItem.id
            );
            let nextToItem = data.newParentChildren[toItemIndex + 1];

            if (nextToItem) {
              if (nextToItem.type === 'Group') {
                newOrder = (data.toItem.order + nextToItem.order) / 2;
                resetListOrder = newOrder - data.toItem.order <= 1;
                console.log(nextToItem, 'next group exists');
              } else {
                newOrder = data.toItem.order + 1000;
                console.log(newOrder, 'next group not exists');
              }
            } else {
              newOrder = data.toItem.order + 1000;
              console.log(newOrder, 'next group not exists');
            }
          }
        }
      }
      data.droppedItem.order = newOrder;

      request({
        id: data.droppedItem.id,
        order: newOrder,
        parentId: data.droppedItem.parentId,
        resetListOrder: resetListOrder
      });

      if (resetListOrder) {
        let rangeOrder = 0;
        let groupOrder = 0;

        const newParentChildrenWithValue = data.newParentChildren.map(item => {
          return { value: item };
        });
        const sortedNewParentChildrenWithValue = this.sortItems(
          newParentChildrenWithValue
        );
        data.newParentChildren = sortedNewParentChildrenWithValue.map(item => {
          if (item.value.type === 'Group') {
            groupOrder += 1000;
            item.value.order = groupOrder;
          } else {
            rangeOrder += 1000;
            item.value.order = rangeOrder;
          }

          return item.value;
        });
      }
    },
    getLastOrder(groups) {
      if (groups.length > 0) {
        const lastGroup = groups.reduce((prev, next) => {
          return prev.order > next.order ? prev : next;
        });
        return lastGroup.order + 1000;
      }

      return 1000;
    },

    onCurrentItemChange(value) {
      if (this.linkingMode) return;
      this.currentItem = value;

      /* DONT RESET SLIDER RANGE, IF PREVIOUS CURRENT ITEM IS PREMIUM */
      if (this.$refs.rangeEditor) this.$refs.rangeEditor.resetSliderRange();
    },
    onSelectedItemsChange(value) {
      this.selectedItems = value;
    },
    openAddGroup() {
      this.showCreateGroupDialog = true;
    },
    openAddDiapason() {
      this.showCreateRangeDialog = true;
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    async handleDeletion(request) {
      let itemIds = this.computedSelectedItems.map(item => item.id).join(',');
      await request(itemIds);
    },
    onItemsDelete() {
      if (this.selectedItems.length > 0) {
        this.$refs.rangesList.deleteSelectedItems();
      } else {
        this.$refs.rangesList.deleteCurrentItem();
      }
    },
    changeRangesFooterView(view) {
      this.rangesFooterCurrentView = view;
    },

    async handleCopyItem(request) {
      if (this.currentItem) {
        await request([this.currentItem.id], this.currentItem.parentId, true);

        if (
          this.currentItem.parentId === '00000000-0000-0000-0000-000000000000'
        ) {
          this.$refs.rangesList.refresh();
        } else {
          this.$refs.rangesList.refreshParent();
        }
      }
    }
  },
  watch: {
    linkingMode(val) {
      let rangesListComponent = this.$refs.rangesList;
      let currentItemID = this.currentItem.id;
      if (!val && rangesListComponent.currentItem.value.id !== currentItemID) {
        rangesListComponent.setCurrentItemByID(currentItemID);
      }
    }
  }
};
