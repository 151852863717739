<template>
  <div class="range-table-editor">
    <table class="range-table-editor__matrix">
      <tbody>
        <tr
          v-for="(row, x) in rowIterator"
          :key="x"
          class="range-table-editor__row"
        >
          <td
            v-for="(hand, y) in row"
            :key="y"
            :class="[
              'range-table-editor__cell',
              `range-table-editor__cell__${hand.name}`,
              {
                'range-table-editor__cell--selected-for-test': hand.useForTest,
                'range-table-editor__cell--has-weight': isWeightValid(
                  hand.weight
                ),
                'range-table-editor__cell--highlighted':
                  highlightHands && highlightHands.includes(hand.name)
              }
            ]"
            @mousedown="startSelection(hand)"
            @mouseover="selectHand(hand)"
          >
            <div class="range-table-editor__background" />
            <div
              v-if="isWeightValid(hand.weight)"
              class="range-table-editor__weight"
            >
              <span
                v-for="weightName in weights"
                :key="weightName"
                :style="{
                  width: hand.weight[weightName] * 100 + '%',
                  'background-color': actionColors[weightName]
                }"
                :weight-type="weightName"
                class="range-table-editor__weight-value"
              />
            </div>

            <div class="absolute-position range-table-editor__foreground" />

            <div class="absolute-position range-table-editor__info">
              <div class="range-table-editor__hand-name">
                {{ hand.name }}
              </div>
              <p
                v-if="
                  hand.statistics &&
                    showStatistics &&
                    (hand.statistics.correct > 0 ||
                      hand.statistics.incorrect > 0)
                "
                :class="[
                  'range-table-editor__statistics',
                  {
                    'range-table-editor__statistics--with-mistakes':
                      hand.statistics.incorrect > 0
                  }
                ]"
              >
                {{ formatStatisticsValue(hand.statistics.incorrect) }}
                /
                {{
                  formatStatisticsValue(
                    hand.statistics.correct + hand.statistics.incorrect
                  )
                }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="range-table-editor__state">
      <div class="range-table-editor__state__item">
        <b>Fold: </b>
        <div class="range-table-editor__state__item__value">
          {{ (parseFloat(rangeState.fold) * 100).toFixed(2) }}% ({{
            parseInt(rangeState.foldCount)
          }})
        </div>
      </div>
      <div class="range-table-editor__state__item">
        <b>Call: </b>
        <div class="range-table-editor__state__item__value">
          {{ (parseFloat(rangeState.call) * 100).toFixed(2) }}% ({{
            parseInt(rangeState.callCount)
          }})
        </div>
      </div>
      <div class="range-table-editor__state__item">
        <b>Raise: </b>
        <div class="range-table-editor__state__item__value">
          {{ (parseFloat(rangeState.raise) * 100).toFixed(2) }}% ({{
            parseInt(rangeState.raiseCount)
          }})
        </div>
      </div>
      <div class="range-table-editor__state__item">
        <b>Allin: </b>
        <div class="range-table-editor__state__item__value">
          {{ (parseFloat(rangeState.allin) * 100).toFixed(2) }}% ({{
            parseInt(rangeState.allinCount)
          }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ORDERED_HANDS_RANGE,
  SEQUENTIAL_HANDS_RANGE
} from '@/constants/handsRange.js';
import {
  GET_PERCENT_FROM_TABLE_DATA,
  GET_COUNT_FROM_TABLE_DATA
} from '@/utils/percent.js';
import { GET_CURRENT_WEIGHT } from '@/utils/weight.js';

const SELECTION_MODE_WEIGHT = 'weight';
const SELECTION_MODE_TEST = 'test';

export default {
  name: 'RangeTableEditor',
  props: {
    hands: Object,
    statistics: Object,
    highlightHands: Array,
    activeParticipant: {
      type: [Object, String]
    },
    actionColors: {
      type: Object,
      default() {
        return {
          fold: 'rgb(115, 216, 255)',
          call: 'rgb(164, 221, 0)',
          raise: 'rgb(159, 4, 27)',
          allin: 'rgb(250, 40, 255)'
        };
      }
    },
    //available types: weight, test
    selectionMode: String,
    //weight: { fold: 0.3, call: 0.3, raise: 0.0, allin: 0.4 }
    weight: {
      type: Object,
      default() {
        return {
          fold: 0.25,
          call: 0.25,
          raise: 0.25,
          allin: 0.25,
          check: 0.25
        };
      }
    },
    isReadOnly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showStatistics: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      hasScroll: false,
      endOfScroll: false,
      weights: ['fold', 'check', 'call', 'raise', 'allin'],
      //each hand:
      //hand.name = name;
      //hand.useForTest = false;
      //hand.weight = { fold: 0.3, call: 0.3, raise: 0.0, allin: 0.4 }
      //hand.statistics = { correct: 87, incorrect: 32 }
      handsRange: [],
      //null - selection not started, true - select, false - deselect
      currentFillState: null,
      //selection states: true - started, false - not started
      selectionState: false,
      rangeState: {
        fold: 0,
        check: 0,
        call: 0,
        raise: 0,
        allin: 0
      }
    };
  },
  methods: {
    ///////////////////////////////////FUNCTIONS FOR PUBLIC USAGE
    //Fills specified hands with selected weight
    //[ { "data": [ "TT", "JJ", "T8s" ], "weight": 0.5 }, { "data": [ "TT", "JJ", "T8s" ], "weight": 1 },... ]
    fillWeights(hands) {
      console.log(hands);
      if (
        this.weight.call === 1 ||
        this.weight.raise === 1 ||
        this.weight.allin === 1 ||
        this.weight.check === 1
      ) {
        hands.forEach(current => {
          if (Array.isArray(current?.data)) {
            current.data.forEach(hand => {
              if (hand && current.weight) {
                let pos = this.getHandPosition(hand);
                let target = this.getHandByPosition(pos.x, pos.y);
                if (this.selectionMode === SELECTION_MODE_WEIGHT) {
                  let weight = target.weight ?? this.emptyWeight();

                  let type = '';
                  if (this.weight.call === 1) type = 'call';
                  else if (this.weight.raise === 1) type = 'raise';
                  else if (this.weight.allin === 1) type = 'allin';
                  else if (this.weight.check === 1) type = 'check';

                  const buildWeight = this.buildWeight(
                    weight.fold * 100,
                    weight.check * 100,
                    weight.call * 100,
                    weight.raise * 100,
                    weight.allin * 100
                  );
                  let converted = GET_CURRENT_WEIGHT(
                    type,
                    current.weight,
                    buildWeight
                  );

                  weight.fold = converted.fold / 100;
                  weight.check = (converted.check ?? 0) / 100;
                  weight.call = converted.call / 100;
                  weight.raise = converted.raise / 100;
                  weight.allin = converted.allin / 100;

                  this.$set(target, 'weight', weight);
                }

                target.useForTest = true;
              }
            });
          }
        });
      }
      this.recalculateState();
    },
    //Specifies range of rands which have to be filled
    setHandsRangeSize(value) {
      console.log('THIS RANGE HANDS123', this.handsRange);

      if (value >= 0 && value <= 169) {
        let hands = ORDERED_HANDS_RANGE().slice(0, value);
        this.handsRange.forEach(hand => {
          if (hands.some(h => hand.name == h)) {
            if (this.selectionMode == SELECTION_MODE_WEIGHT) {
              this.$set(
                hand,
                'weight',
                this.buildWeight(
                  this.weight.fold,
                  this.weight.check,
                  this.weight.call,
                  this.weight.raise,
                  this.weight.allin
                )
              );
              hand.useForTest = true;
            } else if (this.selectionMode == SELECTION_MODE_TEST) {
              hand.useForTest = true;
            }
          } else {
            if (this.selectionMode == SELECTION_MODE_WEIGHT) {
              this.$set(hand, 'weight', null);
              hand.useForTest = false;
            } else if (this.selectionMode == SELECTION_MODE_TEST) {
              hand.useForTest = false;
            }
          }
        });
      } else if (value == 0) {
        if (this.selectionMode == SELECTION_MODE_WEIGHT) {
          this.clearWeights();
        } else if (this.selectionMode == SELECTION_MODE_TEST) {
          this.clearTestHands();
        }
      } else throw 'Value must be in range [0; 169]';
      this.recalculateState();
      let { data } = this.applyChanges();

      this.$emit('scrollChanges', data.hands);
    },
    //Clears statistics for all hands
    clearStatistics() {
      this.handsRange.forEach(hand => {
        this.$set(hand, 'statistics', this.emptyStatistics());
      });
    },
    //Clears weights for all hands
    clearWeights() {
      this.handsRange.forEach(hand => {
        this.$set(hand, 'weight', this.emptyWeight());
      });
      this.recalculateState();
    },
    //Set useForTest to false for all hands
    clearTestHands() {
      this.handsRange.forEach(hand => {
        hand.useForTest = false;
      });
    },
    //Clears statistics and weights for all hands
    clearHandsRange() {
      this.handsRange.forEach(hand => {
        this.$set(hand, 'weight', this.emptyWeight());
        this.$set(hand, 'statistics', this.emptyStatistics());
      });
      this.recalculateState();
    },
    applyChanges() {
      let range = {};
      range.hands = {};
      console.log('apppppplied');

      this.handsRange.forEach(localHand => {
        let key = localHand.name;
        let rangeHand = null;

        if (range.hands.hasOwnProperty(key)) {
          rangeHand = range.hands[key];
        } else {
          rangeHand = {};
          range.hands[key] = rangeHand;
        }

        if (this.isWeightValid(localHand.weight)) {
          rangeHand.allin = localHand.weight.allin;
          rangeHand.call = localHand.weight.call;
          rangeHand.fold = localHand.weight.fold;
          rangeHand.raise = localHand.weight.raise;
          rangeHand.check = localHand.weight.check;

          rangeHand.useForTest = localHand.useForTest ?? false;
        } else if (localHand.useForTest) {
          rangeHand.allin = 0;
          rangeHand.call = 0;
          rangeHand.fold = 0;
          rangeHand.raise = 0;
          rangeHand.check = 0;

          rangeHand.useForTest = true;
        } else {
          if (range.hands.hasOwnProperty(key)) {
            delete range.hands[key];
          }
        }
      });

      range.statistics = {
        hands: {}
      };

      this.handsRange.forEach(localHand => {
        let key = localHand.name;
        let rangeHand = null;

        if (range.statistics.hands.hasOwnProperty(key)) {
          rangeHand = range.statistics.hands[key];
        } else {
          rangeHand = {};
          range.statistics.hands[key] = rangeHand;
        }

        if (localHand.statistics) {
          rangeHand.correct = localHand.statistics.correct;
          rangeHand.incorrect = localHand.statistics.incorrect;
        } else {
          if (range.statistics.hands.hasOwnProperty(key)) {
            delete range.statistics.hands[key];
          }
        }
      });
      console.log(range, 'RANGE IN APPLY');
      return {
        validated: true,
        data: range
      };
    },

    ///////////////////////////////////FUNCTIONS FOR PRIVATE USAGE
    updateTable() {
      this.loadTableCells();

      if (this.hands) {
        Object.keys(this.hands).forEach(key => {
          let element = this.hands[key];
          let weight = this.buildWeight(
            element.fold,
            element.check,
            element.call,
            element.raise,
            element.allin
          );
          let index = this.getHandIndex(key);
          let hand = this.handsRange[index];

          hand.useForTest = element.useForTest;
          this.$set(hand, 'weight', weight);
        });
      }
      if (this.statistics) {
        Object.keys(this.statistics.hands).forEach(key => {
          let element = this.statistics.hands[key];
          let statistics = {
            correct: element.correct,
            incorrect: element.incorrect
          };
          let index = this.getHandIndex(key);
          let hand = this.handsRange[index];

          this.$set(hand, 'statistics', statistics);
        });
      }

      this.recalculateState();
    },
    completeSelection() {
      this.selectionState = false;
      this.currentFillState = null;

      const changes = this.applyChanges();
      console.log('CHANGES IN RTE', changes);
      this.$emit('handsSelect', changes);
    },
    startSelection(hand) {
      this.selectionState = true;
      this.selectHand(hand);
    },
    selectHand(hand) {
      if (this.selectionState && hand && this.isReadOnly == false) {
        if (this.selectionMode == SELECTION_MODE_TEST) {
          if (this.currentFillState == true) {
            hand.useForTest = true;
          } else if (this.currentFillState == false) {
            hand.useForTest = false;
          } else if (this.currentFillState == null) {
            if (hand.useForTest == true) {
              this.currentFillState = false;
            } else if (hand.useForTest == false) {
              this.currentFillState = true;
            } else {
              this.currentFillState = true;
            }
            hand.useForTest = this.currentFillState;
          }
          this.recalculateState();
        } else if (this.selectionMode == SELECTION_MODE_WEIGHT && this.weight) {
          if (this.currentFillState == true) {
            this.$set(hand, 'weight', this.cloneWeight(this.weight));
            hand.useForTest = true;
          } else if (this.currentFillState == false) {
            this.$set(hand, 'weight', null);
          } else if (this.currentFillState == null) {
            if (this.compareWeights(this.weight, hand.weight)) {
              this.currentFillState = false;
              this.$set(hand, 'weight', null);
            } else {
              this.currentFillState = true;
              this.$set(hand, 'weight', this.weight);
              hand.useForTest = true;
            }
          }
          this.recalculateState();
        }
      }
    },
    emptyStatistics() {
      return { correct: 0, incorrect: 0 };
    },
    emptyWeight() {
      return this.buildWeight();
    },
    cloneWeight(weight) {
      return this.buildWeight(
        weight.fold,
        weight.check,
        weight.call,
        weight.raise,
        weight.allin
      );
    },
    buildWeight(fold, check, call, raise, allin) {
      return {
        fold: fold ?? 0,
        call: call ?? 0,
        check: check ?? 0,
        raise: raise ?? 0,
        allin: allin ?? 0
      };
    },
    //w1: weight, w2: weight
    compareWeights(w1, w2) {
      if (w1 == w2) {
        return true;
      } else {
        if (w1 == null || w2 == null) return false;

        return (
          w1.fold === w2.fold &&
          w1.check === w2.check &&
          w1.call === w2.call &&
          w1.raise === w2.raise &&
          w1.allin === w2.allin
        );
      }
    },
    isWeightValid(weight) {
      if (weight) {
        if (
          weight.fold > 0 ||
          weight.call > 0 ||
          weight.raise > 0 ||
          weight.allin > 0 ||
          weight.check > 0
        )
          return true;
      }
      return false;
    },
    //value: number
    formatStatisticsValue(value) {
      if (value >= 99) return 99;
      return value;
    },
    getHandByPosition(x, y) {
      let index = this.convertPositionToIndex(x, y);
      let hand = this.handsRange[index];
      return hand;
    },
    convertPositionToIndex(x, y) {
      let index = x * 13 + y;
      return index;
    },
    // hand: string (AKs, AA, 98o, ...)
    getHandPosition(hand) {
      let index = this.getHandIndex(hand);
      let x = parseInt(index / 13);
      let y = parseInt(index % 13);
      return { x, y };
    },
    // hand: string (AKs, AA, 98o, ...)
    getHandIndex(hand) {
      return SEQUENTIAL_HANDS_RANGE().indexOf(hand);
    },
    // recalculates table state (percent of raise/call/fold/allin actions)
    recalculateState() {
      let percent = GET_PERCENT_FROM_TABLE_DATA(this.handsRange);
      let count = GET_COUNT_FROM_TABLE_DATA(this.handsRange);

      let state = {
        ...percent,
        ...count
      };
      console.log(state, 'stateData');

      this.rangeState.fold = state.fold;
      this.rangeState.foldCount = state.foldCount;
      this.rangeState.check = state.check;
      this.rangeState.call = state.call;
      this.rangeState.callCount = state.callCount;
      this.rangeState.raise = state.raise;
      this.rangeState.raiseCount = state.raiseCount;
      this.rangeState.allin = state.allin;
      this.rangeState.allinCount = state.allinCount;
      console.log(state, 'STATE CHANGED');
      this.$emit('stateChanged', state);
    },
    loadTableCells() {
      let source = SEQUENTIAL_HANDS_RANGE();
      let result = [];
      source.forEach(name => {
        let hand = {};
        hand.name = name;
        hand.useForTest = false;

        result.push(hand);
      });
      this.handsRange = result;
    },
    tickListener() {
      if (this.isReadOnly) return;
      if (this.selectionState) this.completeSelection();
    }
  },
  computed: {
    rowIterator() {
      let rows = [];
      if (this.handsRange) {
        if (this.handsRange.length === 169) {
          for (let i = 0; i < 13; i++) {
            let start = i * 13;
            let end = i * 13 + 13;
            let row = this.handsRange.slice(start, end);
            rows.push(row);
          }
        }
      }
      return rows;
    }
  },
  mounted() {
    this.loadTableCells();

    this.$nextTick(() => {
      document.addEventListener('mouseup', this.tickListener);
    });
  },
  watch: {
    activeParticipant: {
      immediate: true,
      handler() {
        console.log(
          this.$props.activeParticipant,
          'ACTIVE TABLE PART FROM Range table editor'
        );
      }
    },
    hands: {
      immediate: true,
      deep: false,
      handler() {
        this.updateTable();
      }
    },
    statistics: {
      immediate: true,
      deep: false,
      handler() {
        this.updateTable();
      }
    }
  }
};
</script>

<style lang="scss" src="./RangeTableEditor.scss" />
