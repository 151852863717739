<template>
  <div
    :class="[
      'expander__item',
      {
        'expander__item--active': isActive,
        'expander__item--accent': accent
      }
    ]"
  >
    <div
      :class="[
        'expander__item__header',
        { 'expander__item--tabs_height': preventAction }
      ]"
      @click="toggle()"
    >
      <img
        class="expander__item__header__opened-icon"
        src="/icons/chevron-right.svg"
        v-show="!preventAction"
      />
      <div class="expander__item__header__title">
        <slot name="header" />
      </div>

      <div class="expander__item__header__right">
        <slot name="header-buttons" />
      </div>
    </div>
    <div ref="content" class="expander__item__content">
      <div class="expander__item__content__wrapper">
        <div ref="inner">
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpanderItem',
  props: {
    isLinked: {
      type: Boolean,
      default: false
    },
    hideExpanderBtn: {
      type: Boolean,
      default: true
    },
    accent: Boolean,
    icon: {
      type: String,
      default: 'fa fa-chevron-down'
    },
    preventAction: {
      type: Boolean,
      default: false
    },
    linkedIsActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: null,
      isActive: false,
      isLink: this.isLinked
    };
  },
  watch: {
    isActive() {
      this.$emit('getIsActiveState', this.isActive);
    },
    linkedIsActive() {
      this.toggleLinked();
    }
  },
  methods: {
    toggleLinked() {
      this.isActive = this.$props.linkedIsActive;
    },
    toggle() {
      if (this.$props.preventAction) {
        return;
      }
      this.$parent.expandItem(this.key, this.isLink);
    },

    onEnter(el) {
      this.setWrapperHeightTo(this.getContentHeight(), el);
    },
    onAfterEnter(el) {
      this.setWrapperHeightTo('auto', el);
    },
    onBeforeLeave(el) {
      this.setWrapperHeightTo(this.getContentHeight(), el);
    },
    onLeave(el) {
      this.setWrapperHeightTo(0, el);
    },
    getContentHeight() {
      const inner = this.$refs.inner;
      return inner.getBoundingClientRect().height;
    },
    setWrapperHeightTo(height, el = this.$refs.content) {
      el.style.height = typeof height === 'number' ? `${height}px` : height;
    }
  },
  mounted() {
    if (this.isActive) {
      this.setWrapperHeightTo('auto');
    }
  }
};
</script>
