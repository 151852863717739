import Vue from 'vue';
import Vuex from 'vuex';
import ui from './ui';
import ranges from './ranges';
import user from './user';
import play from './play';
import admin from './admin';
import landing from './landing';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ranges,
    user,
    ui,
    play,
    admin,
    landing
  },

});