<template>
  <AuthBlockComponent>
    <template v-slot:body>
      <template v-if="$auth.isLogged()">
        <div class="auth__tab">
          <div class="auth__tab__item" style="pointer-events: none">
            {{ localization.general.FormElements.Authorized }}
          </div>
        </div>
        <div class="auth__inner">
          <div class="auth__inputs">
            <div class="form-block">
              <label class="form-label-text">
                {{ localization.general.FormElements.AuthorizedEmail }}
              </label>
              <input
                :value="$store.state.user.userEmail"
                class="form-input"
                readonly
                type="text"
              />
            </div>
          </div>
        </div>
        <Btn
          class="auth__button"
          width="100%"
          @click="$router.push({
            name: 'Main'
          })"
        >
          {{ localization.general.FormElements.toLogin }}
        </Btn>
      </template>
      <template v-else>
        <AuthBlockToggler/>
        <div class="auth__inner">
          <div class="auth__socials">
            <div class="auth__socials__title">{{ localization.general.FormElements.AuthorizeWithSocials }}</div>
            <div class="auth__socials__row">
              <div
                class="auth__socials__item"
                @click="signInWithExternalServices('google')"
              >
                <img src="/icons/socials/google-circle.svg"/>
              </div>
            </div>
            <div class="auth__socials__divider">
              <span>{{ localization.general.FormElements.or }}</span>
            </div>
          </div>
          <div class="auth__inputs">
            <div class="form-block">
              <label
                class="form-label-text"
                for="email"
              >
                {{ localization.general.FormElements.EmailLabel }}
              </label>
              <input
                id="email"
                v-model="email"
                class="form-input"
                type="text"
                @keyup.enter="signin()"
              />
            </div>
            <div class="form-block">
              <div class="form-label-row">
                <label
                  class="form-label-text"
                  for="password"
                >
                  {{ localization.general.FormElements.PasswordLabel }}
                </label>
                <router-link
                  class="auth__forgot-password"
                  :to="{
                    name: 'Restore Password'
                  }"
                >
                  {{ localization.general.FormElements.ForgetPassword }}
                </router-link>
              </div>

              <input
                id="password"
                v-model="password"
                class="form-input"
                type="password"
                @keyup.enter="signin()"
              />
            </div>
          </div>
        </div>
        <Btn
          :loading="requestPending"
          class="auth__button"
          width="100%"
          @click="signin()"
        >
          {{ localization.general.FormElements.toLogin }}
        </Btn>
      </template>
    </template>
    <template v-slot:footer>
      <div
        v-if="$auth.isLogged()"
        class="auth__additional-links"
      >
        <div
          class="auth__additional-links__item"
          @click="logout()"
        >
          {{ localization.general.FormElements.AuthorizedLogout }}
        </div>
      </div>
    </template>
  </AuthBlockComponent>
</template>

<script>

import AuthBlockComponent from "@/components/Auth/AuthBlockComponent";
import AuthBlockToggler from "@/components/Auth/AuthBlockToggler";
import Btn from "@/components/UI/Btn";

export default {
  name: 'SignIn',
  components: {Btn, AuthBlockToggler, AuthBlockComponent},
  data() {
    return {
      email: '',
      password: '',
      requestPending: false,
      errors: [],
    };
  },
  methods: {
    logout() {
      this.$auth.logout((isSuccess) => {
        if (isSuccess) this.$router.push({
          name: 'Promo'
        })
      });
    },
    async signInWithExternalServices(provider) {
      const AuthCode = await this.$gAuth.getAuthCode();

      this.requestPending = true;
      await this.$auth.signInWithExternalServices({
        provider,
        AuthCode,
        referrer: null
      }).then(response => {
        this.$router.push({
          name: 'Main'
        });
        console.log(response)
      }).catch(error => {
        this.$toast.error(this.localization.general.Simple.SomethingWentWrong);
        console.log(error)
      }).then(() => {
        this.requestPending = false;
      })
    },
    signin() {
      let error = false;

      if (!this.email) {
        error = true;
        this.$toast.error(this.localization.general.FormElements.EmailPlaceholder + " " + this.localization.general.FrontErrors.FieldIsEmpty);
      }
      if (this.email && !this.validateEmail(this.email)) {
        error = true;
        this.$toast.error(this.localization.general.FrontErrors.EmailIsInvalid);
      }
      if (!this.password) {
        error = true;
        this.$toast.error(this.localization.general.FormElements.PasswordPlaceholder + " " + this.localization.general.FrontErrors.FieldIsEmpty);

      }
      if (error) return

      this.requestPending = true;
      this.$auth.signin(this.email, this.password, async (isSuccess, data) => {
        if (isSuccess) {
          await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');
          await this.$router.push({
            name: 'Main'
          });
        } else {
          let code = data.data.Code;
          switch (code) {
            case 'invalid_login':
              this.$toast.error(this.localization.general.ErrorCodes.invalid_login);
              break;
            default:
              this.$toast.error(this.localization.general.FormElements.CheckData);
          }
        }
        this.requestPending = false;
      });
    },
  },
  created() {
    this.$track.openSignIn();
  },
};
</script>

