<template>
  <div>
    <header class="header">
      <div class="container">
        <div class="row">
          <router-link
            :to="{
              name: 'Main'
            }"
          >
            <img class="header__logo" src="/img/logo.svg" />
          </router-link>
          <div
            :style="{
              'border-color': isDanger ? '#CC2D3F' : null
            }"
            class="header__license-expiration"
          >
            {{ licenseIsActiveTill }}
          </div>
          <div class="header__menu">
            <router-link
              v-for="item in menuItems"
              :key="item.route.name"
              :class="[
                'header__menu__item',
                {
                  'header__menu__item--active': $route.name === item.route.name
                }
              ]"
              :to="item.route"
            >
              {{ item.label }}
            </router-link>
          </div>

          <LangSwitcher class="header__langs" />

          <div class="header__user">
            <div
              :class="[
                'header__user__dropdown',
                {
                  'header__user__dropdown--active': headerDropdownActive
                }
              ]"
            >
              <div
                class="header__user__dropdown__header"
                @click="openHeaderDropdown()"
              >
                <div
                  :style="{
                    'background-color': userBG
                  }"
                  class="header__user__dropdown__header__ava"
                >
                  <span
                    :style="{
                      color: userBGIsLight ? 'dark' : 'white'
                    }"
                  >
                    {{ computeFirstEmailLetter }}
                  </span>
                </div>
                {{ computeUserName }}
                <svg
                  class="header__user__dropdown__header__icon"
                  data-src="/icons/triangle.svg"
                />
              </div>
              <div class="header__user__dropdown__body">
                <div class="header__user__dropdown__list">
                  <a
                    class="header__user__dropdown__list__item"
                    @click="
                      goToLink({
                        name: 'Profile'
                      })
                    "
                  >
                    {{ localization.general.Header.Profile }}
                  </a>
                </div>
                <div class="header__user__dropdown__logout" @click="logout()">
                  {{ localization.general.Header.Logout }}
                </div>
              </div>
            </div>
          </div>
          <div
            :class="[
              'header__mobile-menu-icon',
              {
                'header__mobile-menu-icon--active': mobileMenu
              }
            ]"
            @click="toggleMenu()"
          >
            <div class="header__mobile-menu-icon__line" />
            <div class="header__mobile-menu-icon__line" />
            <div class="header__mobile-menu-icon__line" />
          </div>
        </div>
      </div>
    </header>
    <div
      :class="[
        'overlay',
        {
          'overlay--active': mobileMenu
        }
      ]"
      @click="mobileMenu = !mobileMenu"
    />
    <div
      :class="[
        'mobile-nav',
        {
          'mobile-nav--active': mobileMenu
        }
      ]"
    >
      <div class="mobile-nav__list">
        <router-link
          v-for="item in menuItems"
          :key="item.route.name"
          :class="[
            'mobile-nav__list__item',
            {
              'mobile-nav__list__item--active': $route.name === item.route.name
            }
          ]"
          :to="item.route"
          @click.native="mobileMenu = false"
        >
          {{ item.label }}
        </router-link>
        <div class="mobile-nav__list__divider" />
        <router-link
          class="mobile-nav__list__item"
          :to="{
            name: 'Profile'
          }"
          @click.native="mobileMenu = false"
        >
          {{ localization.general.Header.Profile }}
        </router-link>
        <a
          class="mobile-nav__list__item"
          href="javascript:void(0);"
          @click="
            mobileMenu = false;
            logout();
          "
        >
          {{ localization.general.Header.Logout }}
        </a>
        <div class="mobile-nav__list__divider" />
        <div class="mobile-nav__langs">
          <LangSwitcher dark uppercase />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LangSwitcher from '@/components/LangSwitcher';
import { CHECK_IF_COLOR_IS_LIGHT, GET_COLOR_BY_STRING } from '@/utils/colors';

export default {
  name: 'Header',
  components: { LangSwitcher },
  data() {
    return {
      mobileMenu: false,
      today: new Date(),

      mobileHidden: true,
      headerDropdownActive: false
    };
  },
  computed: {
    ...mapState('user', ['userEmail']),
    ...mapState('ui', ['activeBreakpoint']),
    ...mapGetters('user', ['licenses']),

    userBG() {
      return GET_COLOR_BY_STRING(this.userEmail);
    },
    userBGIsLight() {
      return CHECK_IF_COLOR_IS_LIGHT(this.userBG);
    },
    userLicense() {
      return this.licenses
        .filter(item => item.tag === 'prefloper-user-base')
        .reduce((prev, next) => {
          if (!prev) return next;

          let endTimeOfNextItem = new Date(next.endDateTime).getTime();
          let endTimeOfPrevItem = new Date(prev.endDateTime).getTime();

          return endTimeOfNextItem > endTimeOfPrevItem ? next : prev;
        }, null);
    },
    endDateTime() {
      return this.userLicense
        ? new Date(this.userLicense.endDateTime).toLocaleDateString()
        : null;
    },
    licenseIsActiveTill() {
      if (this.endDateTime) {
        return `${this.localization.general.Header.AccessTo} ${this.endDateTime}`;
      }

      return this.localization.general.Header.NoLicense;
    },
    isDanger() {
      return this.endDateTime
        ? this.endDateTime - this.today < 1000 * 60 * 60 * 24 * 7
        : 'danger';
    },
    computeFirstEmailLetter() {
      if (!this.userEmail) return;

      return this.userEmail.charAt(0);
    },
    computeUserName() {
      if (!this.userEmail) return;

      return this.userEmail.substr(0, this.userEmail.lastIndexOf('@'));
    },
    menuItems() {
      const menuItems = [
        {
          route: {
            name: 'Admin Users'
          },
          label: this.localization.general.Header.Admin,
          show: this.$store.getters['user/userLevel'] > 1
        },
        {
          activeOnPath: 'Main',
          route: {
            name: 'Main'
          },
          label: this.localization.general.Header.MainPage
        },
        {
          activeOnPath: '/faq',
          route: {
            name: 'FaQ'
          },
          label: this.localization.general.Header.Faq
        },
        {
          activeOnPath: '/price',
          route: {
            name: 'Price'
          },
          label: this.localization.general.Header.Price
        }
      ];

      return menuItems.filter(menu => {
        if (!menu.hasOwnProperty('show')) return true; // Show menu if property just not exists;

        return menu.show;
      });
    }
  },
  methods: {
    openHeaderDropdown() {
      if (['md', 'sm', 'xs'].includes(this.activeBreakpoint)) {
        this.openMobileMenu();
      } else {
        this.headerDropdownActive = !this.headerDropdownActive;
      }
    },
    openMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    goToLink(link) {
      this.$router.push(link);
      this.headerDropdownActive = false;
    },
    toggleMenu() {
      this.headerDropdownActive = false;
      this.mobileMenu = !this.mobileMenu;
    },
    logout() {
      this.$auth.logout((isSuccess, data) => {
        if (isSuccess) {
          this.$router.push({
            name: 'Promo'
          });
        } else {
          this.$toast.error(
            this.localization.general.Simple.SomethingWentWrong
          );
          window.console.log(data);
        }
      });
    }
  },
  mounted() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.headerDropdownActive = false;
      }
    });
  }
};
</script>
<style lang="scss" src="./Header.scss" scoped />
