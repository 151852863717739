<template>
  <Dialog
    v-model="computedValue"
    class="delete-items-dialog modal-dialog--size-to-content modal-dialog--height-small"
  >
    <DialogHeader>
      {{ localization.general.MainPage.RemoveSelectedItems }} ({{ items.length }})
    </DialogHeader>
    <DialogContent>
      <div
        v-for="item in items"
        :key="item.id"
        class="item"
      >
        {{ item.name }}
      </div>
    </DialogContent>
    <DialogFooter>
      <Btn
        :loading="requestPending"
        @click="confirmDelete()"
      >
        {{ localization.general.Simple.Remove }}
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "@/components/UI/Dialog";
import Btn from "@/components/UI/Btn";

export default {
  name: 'DeleteItemsDialog',
  data() {
    return {
      requestPending: false
    };
  },
  props: {
    handler: Function,
    items: Array,
    value: Boolean
  },
  components: {Btn, Dialog, DialogContent, DialogFooter, DialogHeader},
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    async confirmDelete() {
      this.requestPending = true;

      await this.handler();
      this.closeModal();
      this.$emit('itemsDelete');

      this.requestPending = false;
    },
    closeModal() {
      this.$emit('input', false);
    }
  },
};
</script>

<style lang="scss" scoped src="./DeleteItemsDialog.scss"/>
