<template>
  <div class="c-toggler__row">
    <button
      v-for="item in computedItems"
      :key="item.value"
      :class="[
        'c-toggler__btn',
        {
          'c-toggler__btn--stretch': stretched,
          'c-toggler__btn--active': activeItem === item[itemValue]
        }
      ]"
      @click="updateItem(item[itemValue])"
    >
      {{ item[itemLabel] }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Toggler',
  data: () => ({
    activeItem: null
  }),
  props: {
    inOneRow: Boolean,
    items: Array,
    itemValue: {
      type: String,
      default: 'value'
    },
    itemLabel: {
      type: String,
      default: 'label'
    },
    stretched: Boolean,
    value: {}
  },
  computed: {
    itemIsObject() {
      if (this.items.length === 0) return false;

      return typeof this.items[0] === 'object';
    },
    computedItems() {
      if (this.itemIsObject) return this.items;

      return this.items.map(item => {
        let transformItemToObj = {};
        transformItemToObj[this.itemValue] = item;
        transformItemToObj[this.itemLabel] = item;

        return transformItemToObj;
      });
    }
  },
  methods: {
    updateItem(item) {
      this.activeItem = item;
      this.$emit('input', item);
    },
    updateValue() {
      this.activeItem = this.value;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateValue();
      }
    }
  }
};
</script>

<style lang="scss" src="./Toggler.scss" />
