import { UPDATE_ITEMS_STATISTICS } from '@/api/items';

const state = {
  gameMode: 'Simple',
  game: null,
  activePlayers: null
};

const getters = {
  getGameMode: state => {
    return state.gameMode;
  }
};

const actions = {
  async quitAndSaveStatistics({ commit }, { games }) {
    let source = games;
    games = [];

    let prevGameResult = null;
    source.forEach(game => {
      if (prevGameResult) {
        if (
          prevGameResult.id == game.id &&
          prevGameResult.hand.name == game.hand.name &&
          prevGameResult.hand.cards != null &&
          game.hand.cards != null &&
          prevGameResult.hand.cards.length == game.hand.cards.length &&
          prevGameResult.hand.cards[0].card == game.hand.cards[0].card &&
          prevGameResult.hand.cards[0].m == game.hand.cards[0].m &&
          prevGameResult.hand.cards[1].card == game.hand.cards[1].card &&
          prevGameResult.hand.cards[1].m == game.hand.cards[1].m
        ) {
          return;
        } else {
          games.push(game);
          prevGameResult = game;
        }
      } else {
        if (!game.result) {
          prevGameResult = game;
        }
        games.push(game);
      }
    });

    const getPlayedDiapasons = (item, index, arr) =>
      arr.findIndex(el => el.id == item.id) == index;
    // function to get needed diapasons (selected and played) by id in .filter below
    window.console.log('result', games);

    // used for group render update
    if (games.length > 0) {
      // if any set played
      let diapasons = games.filter(getPlayedDiapasons);
      // get diapasons objs that were played (seems correct func)
      // then proccess each one
      console.log(diapasons);

      let updatedItems = [];
      diapasons.forEach(diapason => {
        let diapasonGames = games.filter(el => el.id == diapason.id);
        // get current diapason sets (games) - object with all set data and result (0 or 1)
        let hands = games
          .filter(el => el.id == diapason.id)
          .reduce((acc, val) => {
            let name = val.hand.name;
            let los = diapasonGames
              .filter(n => n.result == 0)
              .filter(n => n.hand.name == name).length;
            let win = diapasonGames
              .filter(n => n.result)
              .filter(n => n.hand.name == name).length;

            acc.push({
              name,
              correct: win,
              incorrect: los
            });
            return acc;
          }, []);

        // hands - correct/wrond answers for each hand (stat obj for each hand (correct, incorrect, handName))
        // ALERT: hands let gives wrong stat sent to API (to fix)
        // los count
        let los = diapasonGames.filter(r => r.result == 0).length;
        // win count
        let win = diapasonGames.filter(r => r.result).length;
        // create DTO(data transfer obj) for current diapason

        let DTO = {
          itemId: diapason.id,
          correctAnswers: win,
          incorrectAnswers: los,
          hands: {}
        };

        hands.forEach(({ name, correct, incorrect }) => {
          DTO.hands[name] = {
            correct,
            incorrect
          };
        });

        updatedItems.push(DTO);
      });
      await UPDATE_ITEMS_STATISTICS(updatedItems);
    }
    commit('FINISH_GAME');
  }
};

const mutations = {
  START_GAME(state, data) {
    state.game = data;
  },

  SET_ACTIVE_PLAYERS(state, data) {
    state.activePlayers = data;
  },

  FINISH_GAME: state => {
    state.game = null;
  },

  SET_ACTIVE_MODE(state, value) {
    state.gameMode = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
