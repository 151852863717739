var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",style:({ cursor: _vm.$props.actionDisabled && 'pointer' }),on:{"click":_vm.onPartnerCourse}},[_c('div',{staticClass:"card__image",style:({
            'background-image':
                'url(' +
                _vm.$props.item.localization[_vm.lang].previewImageUrl +
                ')',
        })}),_c('div',{staticClass:"card__title card__title--padding"},[_vm._v(" "+_vm._s(_vm.$props.item.localization[_vm.lang].name)+" ")]),_c('div',{staticClass:"card__title--sub card__title--sub--padding",style:({
            marginBottom: _vm.$props.actionDisabled ? 10 + 'px' : undefined,
        })},[_vm._v(" "+_vm._s(_vm.$props.item.localization[_vm.lang].description)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$props.actionDisabled),expression:"!$props.actionDisabled"}],staticClass:"card__divider--horizontal"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$props.actionDisabled),expression:"!$props.actionDisabled"}],staticClass:"card__action"},[_c('div',{staticClass:"block"},[_c('p',{staticClass:"card__stats"},[_vm._v(" "+_vm._s(_vm.$props.item.lessonsCount)+" ")]),_c('p',{staticClass:"card__stats--sub"},[_vm._v(" "+_vm._s(_vm.localization.courses.lessonsSub)+" ")])]),_c('div',{staticClass:"card__divider--vertical"}),_c('div',{staticClass:"block"},[_c('p',{staticClass:"card__stats"},[_vm._v(" "+_vm._s(_vm.getProgress(_vm.$props.item.progress))+"% ")]),_c('p',{staticClass:"card__stats--sub"},[_vm._v(" "+_vm._s(_vm.localization.courses.progressSub)+" ")])])]),_c('div',{staticClass:"card__wrap__button"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$props.actionDisabled),expression:"!$props.actionDisabled"}],staticClass:"card__button",on:{"click":_vm.onCourse}},[_vm._v(" "+_vm._s(_vm.localization.courses.openCourse)+" "),_c('svg',{attrs:{"width":"23","height":"22","viewBox":"0 0 23 22","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.5 15L15.5 11M15.5 11L11.5 7M15.5 11H7.5M21.5 11C21.5 16.5228 17.0228 21 11.5 21C5.97715 21 1.5 16.5228 1.5 11C1.5 5.47715 5.97715 1 11.5 1C17.0228 1 21.5 5.47715 21.5 11Z","stroke":"#12B7DB","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }