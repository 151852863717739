export const getColodesFromString = data => {
  //data example: [25.00]4h2h, 5h2h, 4d2d[/25.00], [50]AsKc[/50], [75] AcJc[/75], 2d2h, 2s2h, 2c2h

  if (!data) return [];

  //all valid suits
  let suits = ['h', 'd', 'c', 's'];
  //all valid hands
  let cards = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
  //start weight ([25.00], [25]) or end weight ([/25.00], [/25]) regex
  let weightRegex = /\[[/]?((\d+)([.](\d+))?)\]/;

  let defaultWeight = 100;
  let currentWeight = 100;
  let resetNextWeight = false;
  // const fromShortHand = (short) => { //short hands: AA, QQ, AKs, AJs, KQo, 23o, etc.
  //   let result = [];
  //   if(short.length == 2){ //AA, QQ
  //     let card = short[0];
  //     suits.forEach((suit1, index1) => {
  //       suits.forEach((suit2, index2) => {
  //         if(suit1 != suit2 && index2 > index1){
  //           let hand = card.toUpperCase() + suit1.toLowerCase() + card.toUpperCase() + suit2.toLowerCase();
  //           result.push(hand);
  //         }
  //       });
  //     });
  //   }
  //   else if(short.length == 3){
  //     let card1 = short[0].toUpperCase();
  //     let card2 = short[1].toUpperCase();
  //     let type = short[2].toLowerCase(); // 's' or 'o'

  //     if(cards.includes(card1) && cards.includes(card2)){
  //       suits.forEach((suit1, index1) => {
  //         suits.forEach((suit2, index2) => {
  //           if(type == 's'){
  //             if(suit1 == suit2 && index2 > index1){
  //               let hand = card1.toUpperCase() + suit1.toLowerCase() + card2.toUpperCase() + suit2.toLowerCase();
  //               result.push(hand);
  //             }
  //           }
  //           else if(type == 'o'){
  //             if(suit1 != suit2 && index2 > index1){
  //               let hand = card1.toUpperCase() + suit1.toLowerCase() + card2.toUpperCase() + suit2.toLowerCase();
  //               result.push(hand);
  //             }
  //           }
  //         });
  //       });
  //     }
  //   }
  //   else if(short.length == 4){
  //     result.push(short);
  //   }
  //   return result;
  // }

  let splitted = data.split(',');

  let weighstMap = new Map();

  splitted.forEach(element => {
    element = element.trim();

    if (resetNextWeight) {
      resetNextWeight = false;
      currentWeight = defaultWeight;
    }

    let weightMatch = weightRegex.exec(element);
    if (weightMatch != null) {
      if (element.includes('/')) {
        resetNextWeight = true;
      }
      let weightValue = weightMatch[1];
      let weightValueFloat = Number.parseFloat(weightValue);
      if (weightValueFloat >= 0 && weightValueFloat <= 100) {
        currentWeight = weightValueFloat;
      }

      element = element
        .replace(weightMatch, '')
        .replace(`[${weightValue}]`, '')
        .replace(`[/${weightValue}]`, '')
        .trim();
    }

    let handWeight = currentWeight;
    let handsToProcess = [];

    const addHand = hand => {
      if (hand.length == 2) {
        let card1 = hand[0].toUpperCase();
        let card2 = hand[1].toUpperCase();
        if (cards.includes(card1) && cards.includes(card2)) {
          handsToProcess.push(hand);
        }
      } else if (hand.length == 3) {
        let card1 = hand[0].toUpperCase();
        let card2 = hand[1].toUpperCase();
        if (cards.includes(card1) && cards.includes(card2)) {
          handsToProcess.push(hand);
        }
      } else if (hand.length == 4) {
        let card1 = hand[0].toUpperCase();
        let card2 = hand[2].toUpperCase();
        let suit1 = hand[1].toLowerCase();
        let suit2 = hand[3].toLowerCase();

        if (card1 != card2) {
          let type = '';

          if (suit1 == suit2) type = 's';
          else if (card1 != card2) type = 'o';

          if (
            type &&
            cards.includes(card1) &&
            cards.includes(card2) &&
            suits.includes(suit1) &&
            suits.includes(suit2)
          ) {
            hand = card1 + card2 + type;
            handsToProcess.push(hand);
          }
        } else {
          hand = card1 + card2;
          handsToProcess.push(hand);
        }
      }
    };
    // const addHands = (hands) => {
    //   hands.forEach(hand => {
    //     addHand(hand);
    //   });
    // };

    if (element.includes('-')) {
      //AA-QQ, AKs-AJs, KQo-23o, etc.
      let rangeBordersRaw = element.split('-');

      let rangeBorders = [];
      rangeBordersRaw.forEach(rb => {
        rangeBorders.push(rb.trim());
      });

      if (
        rangeBorders.length == 2 &&
        rangeBorders[0].length >= 2 && rangeBorders[0].length <= 3 &&
        rangeBorders[1].length >= 2 && rangeBorders[1].length <= 3
      ) {
        if (rangeBorders[0].length == 2 && rangeBorders[1].length == 2) {
          //AA-QQ, 77-22
          rangeBorders[0] = rangeBorders[0].toUpperCase();
          rangeBorders[1] = rangeBorders[1].toUpperCase();

          if (
            rangeBorders[0][0] == rangeBorders[0][1] &&
            rangeBorders[1][0] == rangeBorders[1][1]
          ) {
            let borderIndex = [
              cards.indexOf(rangeBorders[0][0]),
              cards.indexOf(rangeBorders[1][0])
            ];

            if (borderIndex[0] >= 0 && borderIndex[1] >= 0) {
              //swap borders to make first smaller than second (TT-22 => 22-TT)
              if (borderIndex[0] > borderIndex[1]) {
                let temp = borderIndex[0];
                borderIndex[0] = borderIndex[1];
                borderIndex[1] = temp;
              }

              for (let i = borderIndex[0]; i <= borderIndex[1]; i++) {
                let cardValue = cards[i];
                let shortHand = cardValue + cardValue;
                addHand(shortHand);
              }
            }
          }
        } else {
          //AKs-AJs, KQs-23o, AA-QTs, A9o-A7o, A9s-A7s
          let x1 = cards.indexOf(rangeBorders[0][0].toUpperCase());
          let x2 = cards.indexOf(rangeBorders[1][0].toUpperCase());
          let y1 = cards.indexOf(rangeBorders[0][1].toUpperCase());
          let y2 = cards.indexOf(rangeBorders[1][1].toUpperCase());

          if (
            rangeBorders[0].length == 3 &&
            rangeBorders[0][2].toLowerCase() == 's'
          ) {
            let xBuffer = x1;
            x1 = y1;
            y1 = xBuffer;
          }
          if (
            rangeBorders[1].length == 3 &&
            rangeBorders[1][2].toLowerCase() == 's'
          ) {
            let xBuffer = x2;
            x2 = y2;
            y2 = xBuffer;
          }

          for (let x = x1; x <= x2; x++) {
            for (let y = y1; y <= y2; y++) {
              let type = '';
              if (x > y) type = 's';
              else if (x < y) type = 'o';

              let card1Index = x;
              let card2Index = y;

              if (card1Index > card2Index) {
                let cardIndexBuffer = card1Index;
                card1Index = card2Index;
                card2Index = cardIndexBuffer;
              }

              let card1 = cards[card1Index];
              let card2 = cards[card2Index];
              let shortHand = card1 + card2 + type;
              addHand(shortHand);
            }
          }
        }
      }
    } else {
      let hand = element.trim();
      addHand(hand);
    }

    if (handsToProcess.length > 0) {
      if (!weighstMap.has(handWeight)) weighstMap.set(handWeight, []);

      handsToProcess.forEach(hand => {
        let weightHands = weighstMap.get(handWeight);
        weightHands.push(hand);
      });
    }
  });

  let result = [];

  if (weighstMap.size > 0) {
    weighstMap.forEach((value, key) => {
      if (key > 0) {
        let handsRange = {};
        handsRange.weight = key;
        handsRange.data = value;
        result.push(handsRange);
      }
    });
  }

  console.log(result);

  return result;
};

// getColodesFromString end

function shuffle(arr) {
  let j, temp;
  for (let i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[j];
    arr[j] = arr[i];
    arr[i] = temp;
  }
  return arr;
}

export const GET_CARDS_FROM_NAME = name => {
  const suits = shuffle(['h', 'd', 'c', 's']);
  console.log(name, 'name in colode');
  let result = [];
  if (name.length == 2) {
    let item = {
      m: suits[0],
      card: name[0].toLowerCase()
    };
    let item2 = {
      m: suits[1],
      card: name[0].toLowerCase()
    };
    result.push(item);
    result.push(item2);
  }
  if (name[2] == 's') {
    let m = suits[0];
    let item = {
      m,
      card: name[0]
    };
    let item2 = {
      m,
      card: name[1]
    };
    result.push(item);
    result.push(item2);
  }
  if (name[2] == 'o') {
    let item = {
      m: suits[0],
      card: name[0]
    };
    let item2 = {
      m: suits[1],
      card: name[1]
    };
    result.push(item);
    result.push(item2);
  }
  return result;
};
