<template>
  <div>
    <h2 class="admin-title">
      {{ localization.general.Profile.Subscriptions }}
    </h2>
    <div class="c-card">
      <div class="filter-block">
        <div class="form-block filter-block__item">
          <label class="form-label-text">Charge</label>
          <Select
            v-model="selected.charge"
            :items="chargeTypes"
            clearable
            @input="getSubscriptions()"
          />
        </div>
        <div class="form-block filter-block__item">
          <label class="form-label-text">States</label>
          <Select
            v-model="selected.state"
            :items="states"
            clearable
            @input="getSubscriptions()"
          />
        </div>
        <Btn @click="getSubscriptions()">
          {{ localization.general.Simple.Refresh }}
        </Btn>
      </div>

      <table class="c-simple-table c-simple-table--tr-is-link">
        <thead>
        <tr>
          <th>
            {{ localization.general.Profile.SubscriptionCreationDateTime }}
          </th>
          <th>
            {{ localization.general.Profile.SubscriptionPrice }}
          </th>
          <th>
            {{ localization.general.Profile.SubscriptionLastPaymenthateTime }}
          </th>
          <th>
            Charge
          </th>
          <th>
            {{ localization.general.Profile.SubscriptionChargeState }}
          </th>
        </tr>
        </thead>
        <tbody>
        <router-link
          v-for="subscription in subscriptionsByDate"
          :key="subscription.id"
          :to="{
            name: 'Admin Users Single',
            params: {
              id: subscription.userId
            }
          }"
          tag="tr"
        >
          <td>
            {{ subscription.creationDateTime }}
          </td>
          <td>
            {{ subscription.header }}
          </td>
          <td>
            {{ subscription.lastPaymentDateTime }}
          </td>
          <td>
            {{ localization.general.Profile['SubscriptionChargeState' + subscription.result] }}
          </td>
          <td>
            {{ subscription.state }}
          </td>
        </router-link>
        </tbody>
      </table>

      <Pagination
        v-model="activePage"
        :total="maxPage"
      />

    </div>
  </div>
</template>

<script>
import moment from 'moment';

import {SEARCH_SUBSCRIPTIONS} from '@/api/subscriptions';
import {GET_LANG_STORE} from '@/api/localizations';
import Select from "@/components/UI/Select/Select";
import Pagination from "@/components/UI/Pagination/Pagination";
import Btn from "@/components/UI/Btn";

export default {
  name: 'AdminSubscriptions',
  components: {
    Btn,
    Pagination,
    Select
  },
  data() {
    return {
      states: [
        {
          value: 'Active',
          label: 'Active'
        },
        {
          value: 'Deactivated',
          label: 'Deactivated'
        }
      ],
      chargeTypes: [
        {
          value: 'Success',
          label: 'Success'
        },
        {
          value: 'Error',
          label: 'Error'
        }
      ],
      activePage: 0,
      maxPage: 1,
      itemsPerPage: 25,

      selected: {
        charge: null,
        state: null
      },

      subscriptions: [],
      isActive: true,
      activeTab: 1,
      active: false
    };
  },
  computed: {
    subscriptionsByDate() {
      if (this.subscriptions) {
        const arrOr = this.subscriptions;
        const newarr = arrOr.sort((a, b) => {
          return moment(b.creationDateTime).diff(a.creationDateTime);
        });
        console.log(newarr);

        return newarr;
      } else {
        return null;
      }
    }
  },
  methods: {

    nextPage() {
      this.activePage += 1;
      this.changePage();
    },
    prevPage() {
      this.activePage -= 1;
      this.changePage();
    },
    changePage(){
      this.getSubscriptions()
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.activePage
        }
      })
    },

    isNotActive(date) {
      return (
        moment(date).valueOf() < moment('01-01-2019', 'dd-mm-yyyy').valueOf()
      );
    },
    async getSubscriptions() {
      let DTO = {
        pageNumber: this.activePage,
        itemsPerPage: this.itemsPerPage,
        state: this.selected.state,
        charge: this.selected.charge
      };

      const subscriptions = await SEARCH_SUBSCRIPTIONS(DTO);
      const pLangStore = await GET_LANG_STORE(this.$store.getters['user/language'], 'store');

      this.activePage = subscriptions.pageNumber;
      this.maxPage = subscriptions.pagesCount;
      this.subscriptions = this.translationsSubscriptionsToPriceBlocks(pLangStore, subscriptions.data);
    }
  },
  created() {
    if (this.$route.query.page) {
      this.activePage = this.$route.query.page;
    }
  },
  async mounted() {

    await this.getSubscriptions();

  },
};
</script>