<template>
  <section class="auth">
    <picture class="auth__bg">
      <source srcset="/img/auth/bg.webp" type="image/webp">
      <source srcset="/img/auth/bg.png" type="image/png">

      <img src="/img/auth/bg.png"/>
    </picture>
    <div class="auth__wrapper">

      <router-link :to="{
        name: 'Promo'
      }">
        <img
          class="auth__logo"
          src="/img/logo.svg"
        />
      </router-link>
      <slot name="header"/>
      <div class="auth__block">
        <slot name="body"/>
      </div>
      <slot name="footer"/>
      <LangSwitcher class="auth__langs"/>
    </div>

  </section>
</template>


<script>
import LangSwitcher from "@/components/LangSwitcher";

export default {
  name: "AuthBlockComponent",
  components: {LangSwitcher},
}
</script>

<style lang="scss" src="../../assets/scss/auth.scss"/>