const trackingProperties = {
  categories: {
    engagement: "engagement"
  },
  actions: {
    createDiapason: "create_diapason",
    createGroup: "create_group",
    editDiapason: "edit_diapason",
    editGroup: "edit_group",
    removeDiapason: "remove_diapason",
    removeGroup: "remove_group",
    startGame: "start_game",
    finishGame: "finish_game",
    login: "login",
    logout: "logout",
    beginRegistration: "begin_registration",
    confirmRegistration: "confirm_registration",
    restorePassword: "restore_password",
    buyLicense: "buy_license",
    buyLicenseCompleted: "buy_license_completed",
    buyLicenseCompletedError: "buy_license_completed_error",
    openRanges: "open_ranges",
    openFaq: "open_faq",
    openProfile: "open_profile",
    openPrice: "open_price",
    landingSignIn: "landing_open_sign_in",
    openSignIn: "open_sign_in",
    openLanding: "open_landing"
  },
  labels: {
    method: "method"
  }
};

export default {
  install(Vue) {
    const gtag = Vue.$gtag;
    Vue.$track = Vue.prototype.$track = {
      createDiapason() {
        gtag.event(trackingProperties.actions.createDiapason, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      createGroup() {
        gtag.event(trackingProperties.actions.createGroup, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },

      editDiapason() {
        gtag.event(trackingProperties.actions.editDiapason, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      editGroup() {
        gtag.event(trackingProperties.actions.editGroup, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },

      deleteDiapason() {
        gtag.event(trackingProperties.actions.removeDiapason, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      deleteGroup() {
        gtag.event(trackingProperties.actions.removeGroup, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },

      startGame() {
        gtag.event(trackingProperties.actions.startGame, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      finishGame() {
        gtag.event(trackingProperties.actions.finishGame, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },

      login() {
        gtag.event(trackingProperties.actions.login, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      logout() {
        gtag.event(trackingProperties.actions.logout, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      beginRegistration() {
        gtag.event(trackingProperties.actions.beginRegistration, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      confirmRegistration() {
        gtag.event(trackingProperties.actions.confirmRegistration, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      restorePassword() {
        gtag.event(trackingProperties.actions.restorePassword, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      buyLicense() {
        gtag.event(trackingProperties.actions.buyLicense, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      openRanges() {
        gtag.event(trackingProperties.actions.openRanges, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      openFaq() {
        gtag.event(trackingProperties.actions.openFaq, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      openProfile() {
        gtag.event(trackingProperties.actions.openProfile, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      openPrice() {
        gtag.event(trackingProperties.actions.openPrice, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      landingSignIn() {
        gtag.event(trackingProperties.actions.landingSignIn, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      openSignIn() {
        gtag.event(trackingProperties.actions.openSignIn, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      openLanding() {
        gtag.event(trackingProperties.actions.openLanding, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      buyLicenseCompleted() {
        gtag.event(trackingProperties.actions.buyLicenseCompleted, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      },
      buyLicenseCompletedError() {
        gtag.event(trackingProperties.actions.buyLicenseCompletedError, {
          'event_category': trackingProperties.categories.engagement,
          'event_label': trackingProperties.labels.method
        });
      }
    }
  }
};