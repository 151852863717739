const MAX_COMB = 1326;
//const isChosen = (item) => item.chosen;
const isWeight = item => item.weight;
const formatter = data =>
  data
    //    .filter(isChosen)
    .filter(isWeight)
    .map(item => ({
      name: item.name,
      ...item.weight
    }));
const getCoeff = type => {
  if (type.length == 2) {
    return 6;
  }
  if (type[2] == 'o') {
    return 12;
  } else {
    return 4;
  }
};
const isRaise = item => item.raise;
const getPercentRaise = (acc, item) => {
  let percent = (getCoeff(item.name) / MAX_COMB) * item.raise;
  return acc + percent;
};
// const getCountRaise = (acc, item) => {
//   console.log(getCoeff(item.name), 'item name coed');
//   // let count = getCoeff(item.name);
//   return acc + item;
// };
const isAllin = item => item.allin;
const getPercentAllin = (acc, item) => {
  let percent = (getCoeff(item.name) / MAX_COMB) * item.allin;
  return acc + percent;
};
const isCall = item => item.call;
const getPercentCall = (acc, item) => {
  let percent = (getCoeff(item.name) / MAX_COMB) * item.call;
  return acc + percent;
};
const isFold = item => item.fold;
const getPercentFold = (acc, item) => {
  let percent = (getCoeff(item.name) / MAX_COMB) * item.fold;
  return acc + percent;
};

export const GET_COUNT_FROM_TABLE_DATA = tableData => {
  const data = formatter(tableData);
  let raise = data.filter(isRaise).length;
  let call = data.filter(isCall).length;
  let fold = data.filter(isFold).length;
  let allIn = data.filter(isAllin).length;
  return {
    raiseCount: raise > 0 ? raise : 0,
    callCount: call > 0 ? call : 0,
    allinCount: allIn > 0 ? allIn : 0,
    foldCount: fold > 0 ? fold : 0
  };
};

export const GET_PERCENT_FROM_TABLE_DATA = tableData => {
  const data = formatter(tableData);
  let raise = data.filter(isRaise).reduce(getPercentRaise, 0);
  let allin = data.filter(isAllin).reduce(getPercentAllin, 0);
  let call = data.filter(isCall).reduce(getPercentCall, 0);
  let fold = data.filter(isFold).reduce(getPercentFold, 0);
  return {
    raise: raise.toFixed(5),
    call: call.toFixed(5),
    fold: fold.toFixed(5),
    allin: allin.toFixed(5)
  };
};
