import store from '@/store';

export const getTablePositions = (size) => {
  switch (size) {
    case 2:
      return ['sb', 'bb'];
    case 3:
      return ["bu", "sb", "bb"];
    case 6:
      return ["ep", "mp", "co", "bu", "sb", "bb"];
    case 8:
      return ["ep1", "ep2", "ep3", "mp", "co", "bu", "sb", "bb"];
  }
}

export const getCustomNameOfPosition = (name) => {
  if (typeof name !== 'string') return '';
  name = name.toLowerCase();

  let globalPreferencesPositions = store.state.user.globalPreferences.positions;

  if (globalPreferencesPositions) {
    if (globalPreferencesPositions.hasOwnProperty(name)) {
      return globalPreferencesPositions[name];
    }
  }
  return name;
}