<template>
  <div>
    <router-view  v-if="ready"/>
    <WarPopup/>
  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import {DEFAULT_LANG} from "@/constants/languages";
import VueCookies from "vue-cookies";
import WarPopup from "@/components/WarPopup/WarPopup";

export default {
  name: 'App',
  components: {WarPopup},
  data() {
    return {
      ready: false
    }
  },
  computed: {
    ...mapState('user', ['globalPreferences']),
  },
  methods: {
    ...mapMutations('ui', ['SET_BREAKPOINT']),

    runDetectBreakpointCheck() {
      const detectCurrentBreakpoint = () => {
        // FIXME: Some DIRTY hack: pseudo-element of body will contain current breakpoint type: xl, lg, md, sm or xs
        const body = document.querySelector('body');
        const bodyBeforeStyles = window.getComputedStyle(body, ':before');
        const bodyPseudoElementWhichContainsBreakpoint = bodyBeforeStyles.getPropertyValue('content').replace(/"/g, '');
        this.SET_BREAKPOINT(bodyPseudoElementWhichContainsBreakpoint);
      }

      detectCurrentBreakpoint();
      window.addEventListener('resize', detectCurrentBreakpoint);
    },
    setLocalization() {
      let lang = localStorage.getItem('activeLang') ?? DEFAULT_LANG;
      this.$store.commit('ui/SET_LOCALIZATION', lang);
    },
    setReferrals() {
      const referralCode = this.$route.query.referralCode;
      if (referralCode) {
        VueCookies.set('referral_code', referralCode, '1D');
      }
    }
  },
  async mounted() {
    await this.$auth.getAuthUser();

    this.setLocalization();
    this.setReferrals()
    this.runDetectBreakpointCheck();

    this.ready = true;
  }
};
</script>

<style lang="scss" src="./assets/scss/app.scss"/>
