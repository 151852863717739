<template>
  <div
    v-if='sliderLength > 1'
    class="weight-slider"
  >
    <!--  Range Slider  -->
    <div class="weight-slider__inputs">
      <div class="weight-slider__inputs__item">
        <input
          ref="weightInput1"
          :value="lineOneComputedValue"
          maxlength="2"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="number"
          @input="changeFirstInput($event)"
        />
        %
      </div>
      <div class="weight-slider__inputs__item">
        <input
          ref="weightInput2"
          :value="lineTwoComputedValue"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="number"
          @input="changeSecondInput($event)"
        />
        %
      </div>
      <div
        v-if='sliderLength > 2'
        class="weight-slider__inputs__item"
      >
        <input
          ref="weightInput3"
          :value="lineThreeComputedValue"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="number"
          @input="changeThirdInput($event)"
        />
        %
      </div>
      <div
        v-if='sliderLength === 4'
        class="weight-slider__inputs__item"
      >
        <input
          ref="weightInput4"
          :value="lineFourComputedValue"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="number"
          @input="changeFourthInput($event);"
        />
        %
      </div>
    </div>

    <section
      class="weight-slider__container"
      @click="selectSlider"
    >
      <div class="weight-slider__inputs-row">
        <input
          v-if='sliderLength >= 2'
          :value="lineOneRawValue"
          class="weight-slider__range-input"
          max="100"
          min="0"
          step="1"
          type="range"
          @input="changeFirstInput($event)"
        />
        <input
          v-if="sliderLength >= 3"
          :value="lineTwoRawValue"
          class="weight-slider__range-input"
          max="100"
          min="0"
          step="1"
          type="range"
          @input="changeSecondRange($event)"
        />
        <input
          v-if="sliderLength === 4"
          :value="lineFourRawValue"
          class="weight-slider__range-input"
          max="100"
          min="0"
          step="1"
          type="range"
          @input="changeFourthRange($event, true)"
        />
      </div>

      <div class="weight-slider__indicators">
        <div
          v-if='sliderLength >= 1'
          :style="{
            'width': lineOneComputedValue + '%',
            'background-color': weight[weightNames[0]].color
          }"
          class="weight-slider__indicators__item weight-slider__indicators__item--first"
        />
        <div
          v-if='sliderLength >= 2'
          :style="{
            'width': lineTwoComputedValue + '%',
            'background-color': weight[weightNames[1]].color
          }"
          class="weight-slider__indicators__item weight-slider__indicators__item--second"
        />

        <div
          v-if='sliderLength >= 3'
          :style="{
            'width': lineThreeComputedValue + '%',
            'background-color': weight[weightNames[2]].color
          }"
          class="weight-slider__indicators__item weight-slider__indicators__item--third"
        />
        <div
          v-if="sliderLength === 4"
          :style="{
            'width': lineFourComputedValue + '%',
            'background-color': weight[weightNames[3]].color
          }"
          class="weight-slider__indicators__item weight-slider__indicators__item--fourth"
        >
        </div>

      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: "WeightSlider",
  data() {
    return {
      localCurrentWeight: {
        fold: {
          value: 25,
          isAvailable: true,
          color: "rgb(115, 216, 255)"
        },
        call: {
          value: 25,
          isAvailable: true,
          color: "rgb(164, 221, 0)"
        },
        raise: {
          value: 25,
          isAvailable: true,
          color: "rgb(159, 4, 27)"
        },
        allin: {
          value: 25,
          isAvailable: true,
          color: "rgb(250, 40, 255)"
        },
      },
      weightNames: []
    }
  },
  props: {
    weight: {
      type: Object,
    },
  },
  computed: {
    sliderLength() {
      return this.weightNames.length
    },
    // 1st input button % length
    lineOneRawValue() {
      return Math.floor(this.localCurrentWeight[0].value);
    },
    // 2nd input button % length
    lineTwoRawValue() {


      return this.localCurrentWeight[0].value + this.localCurrentWeight[1].value
    },
    //3rd input button
    lineFourRawValue() {
      // default - 4 lines
      return Math.floor(100 - this.localCurrentWeight[3].value);
    },
    lineOneComputedValue() {
      // 1st div (line) % length
      return this.lineOneRawValue;
    },
    lineTwoComputedValue() {
      if (this.sliderLength === 2) {
        return Math.floor(100 - this.lineOneRawValue);
      }
      if (this.sliderLength === 3) {
        return Math.floor(this.lineTwoRawValue - this.lineOneRawValue);
      } else {
        return Math.floor(this.lineFourRawValue - this.lineOneRawValue - this.lineThreeComputedValue);
      }
    },
    lineThreeComputedValue() {
      if (this.sliderLength === 3) {
        return Math.floor(100 - this.lineTwoRawValue);
      } else {
        return Math.floor(this.lineFourRawValue - this.lineTwoRawValue);
      }
    },
    lineFourComputedValue() {
      // appears if 4 lines
      return Math.floor(100 - this.lineFourRawValue);
    },
  },
  methods: {
    checkMaxInput(inputNum, value) {
      if (parseInt(value) > 100) {
        this.$toast.error('Max allowed value: 100, you`re trying to enter ' + value);
        value = value.slice(0, -1);
        this.$refs['weightInput' + inputNum].value = value;
      }
      return Math.floor(value);
    },
    changeFirstRange(event) {
      let value = this.checkMaxInput(1, event.target.value);

      if (this.sliderLength === 2) {
        let lineOne = Math.floor(value);
        let lineTwo = Math.floor(100 - value);

        // save local result
        this.localCurrentWeight[0].value = lineOne
        this.localCurrentWeight[1].value = lineTwo
      }

      if (this.sliderLength === 3) {
        // 3 values/lines
        let lineOne = Math.floor(value);
        let lineTwo = Math.floor(this.lineTwoRawValue - value)
        let lineThree = Math.floor(100 - (this.lineTwoRawValue))

        if (lineTwo < 0) {
          lineThree = lineThree + lineTwo;
          lineTwo = 0;
        }
        if (lineThree < 0) {
          lineThree = 0;
        }

        // save local result
        this.localCurrentWeight[0].value = lineOne
        this.localCurrentWeight[1].value = lineTwo
        this.localCurrentWeight[2].value = lineThree

      } else {
        // default calculation - 4 lines
        let lineOne = Math.floor(value);
        let lineThree = Math.floor(this.lineFourRawValue) - this.lineTwoRawValue;
        let lineFour = Math.floor(100 - this.lineFourRawValue);
        let lineTwo = Math.floor(this.lineFourRawValue - Math.floor(value) - lineThree);

        if (lineTwo < 0) {
          lineThree = lineThree + lineTwo;
          lineTwo = +0;
        }
        if (lineThree < 0) {
          lineFour = lineFour + lineThree;
          lineThree = +0;
        }
        // save local result
        this.localCurrentWeight[0].value = lineOne
        this.localCurrentWeight[1].value = lineTwo
        this.localCurrentWeight[2].value = lineThree
        this.localCurrentWeight[3].value = lineFour
      }

      this.emitWeightChange();

    },
    changeFirstInput(event) {
      let value = this.checkMaxInput(1, event.target.value);
      let oneTwoBefore = Math.floor(this.lineOneComputedValue + this.lineTwoComputedValue);

      if (value > oneTwoBefore) {

        this.localCurrentWeight[0].value = value;
        this.localCurrentWeight[1].value = 0;

        let valueForThird = 100 - Math.floor(value + this.lineFourComputedValue);
        if (valueForThird < 0) {
          this.localCurrentWeight[2].value = 0;

          const valueForFour = 100 - value;
          this.localCurrentWeight[3].value = valueForFour < 0 ? 0 : valueForFour;
        } else {
          this.localCurrentWeight[2].value = valueForThird;
        }

      } else {
        this.localCurrentWeight[0].value = value;
        this.localCurrentWeight[1].value = oneTwoBefore - value;
      }


      this.emitWeightChange();

    },

    changeSecondRange(event) {
      let value = this.checkMaxInput(2, event.target.value);

      /* eslint-disable */
      if (this.sliderLength == 3) {
        let lineOne = this.lineOneRawValue;
        let lineTwo = Math.floor(value - this.lineOneRawValue)
        let lineThree = Math.floor(100 - (value))


        if (lineTwo < 0) {
          lineOne = lineOne + lineTwo;
          lineTwo = 0;
        }
        if (lineThree < 0) {
          lineThree = 0;
        }
        // save local result
        this.localCurrentWeight[0].value = lineOne
        this.localCurrentWeight[1].value = lineTwo
        this.localCurrentWeight[2].value = lineThree
      } else {
        // debugger;
        // default calculation - 4 lines
        let lineOne = this.lineOneRawValue;
        let lineFour = Math.floor(100 - this.lineFourRawValue);
        let lineThree = Math.floor(this.lineFourRawValue - Math.floor(value));
        let lineTwo = Math.floor(this.lineFourRawValue - this.lineOneRawValue - lineThree);


        if (lineTwo < 0) {
          lineOne = lineOne + lineTwo;
          lineTwo = 0;
        }
        if (lineThree < 0) {
          lineFour = lineFour + lineThree;
          lineThree = 0;
        }

        // save local result
        this.localCurrentWeight[0].value = lineOne
        this.localCurrentWeight[1].value = lineTwo
        this.localCurrentWeight[2].value = lineThree
        this.localCurrentWeight[3].value = lineFour
      }

      this.emitWeightChange();

    },
    changeSecondInput(event) {
      let value = this.checkMaxInput(2, event.target.value);
      let twoThreeBefore = Math.floor(this.lineTwoComputedValue + this.lineThreeComputedValue);

      if (value > twoThreeBefore) {

        this.localCurrentWeight[1].value = value;
        this.localCurrentWeight[2].value = 0;

        let valueForFourth = 100 - Math.floor(value + this.lineOneComputedValue);
        if (valueForFourth < 0) {
          this.localCurrentWeight[3].value = 0;

          const valueForOne = 100 - value;
          this.localCurrentWeight[0].value = valueForOne < 0 ? 0 : valueForOne;
        } else {
          this.localCurrentWeight[3].value = valueForFourth;
        }

      } else {
        this.localCurrentWeight[1].value = value;
        this.localCurrentWeight[2].value = twoThreeBefore - value;
      }

      this.emitWeightChange();

    },

    changeThirdInput(event) {
      let value = this.checkMaxInput(2, event.target.value);
      let threeFourBefore = Math.floor(this.lineThreeComputedValue + this.lineFourComputedValue);

      if (value > threeFourBefore) {

        this.localCurrentWeight[2].value = value;
        this.localCurrentWeight[3].value = 0;

        let valueForSecond = 100 - Math.floor(value + this.lineOneComputedValue);
        if (valueForSecond < 0) {
          this.localCurrentWeight[1].value = 0;

          const valueForOne = 100 - value;
          this.localCurrentWeight[0].value = valueForOne < 0 ? 0 : valueForOne;
        } else {
          this.localCurrentWeight[1].value = valueForSecond;
        }

      } else {
        this.localCurrentWeight[2].value = value;
        this.localCurrentWeight[3].value = threeFourBefore - value;
      }

      this.emitWeightChange();

    },
    changeFourthRange(event) {
      let value = this.checkMaxInput(4, event.target.value);

      this.localCurrentWeight[3].value = 100 - value;
      // default - 4 lines

      value = this.lineFourRawValue;
      let lineFour = Math.floor(100 - Math.floor(value));
      let lineOne = Math.floor(this.lineOneRawValue);
      let lineThree = Math.floor(value - this.lineTwoRawValue);
      let lineTwo = Math.floor(Math.floor(value) - this.lineOneRawValue - lineThree);

      if (lineThree < 0) {
        lineTwo = lineThree + lineTwo;
        lineThree = 0;
      }
      if (lineTwo < 0) {
        lineOne = lineOne + lineTwo;
        lineTwo = 0;
      }
      // save local result
      this.localCurrentWeight[0].value = lineOne
      this.localCurrentWeight[1].value = lineTwo
      this.localCurrentWeight[2].value = lineThree
      this.localCurrentWeight[3].value = lineFour

      this.emitWeightChange();

    },
    changeFourthInput(event) {
      let value = this.checkMaxInput(4, event.target.value);
      let threeFourBefore = Math.floor(this.lineThreeComputedValue + this.lineFourComputedValue);

      if (value > threeFourBefore) {

        this.localCurrentWeight[3].value = value;
        this.localCurrentWeight[2].value = 0;

        let valueForSecond = 100 - Math.floor(value + this.lineOneComputedValue);
        if (valueForSecond < 0) {
          this.localCurrentWeight[1].value = 0;

          const valueForOne = 100 - value;
          this.localCurrentWeight[0].value = valueForOne < 0 ? 0 : valueForOne;
        } else {
          this.localCurrentWeight[1].value = valueForSecond;
        }

      } else {
        this.localCurrentWeight[3].value = value;
        this.localCurrentWeight[2].value = threeFourBefore - value;
      }


      this.emitWeightChange();
    },


    selectSlider() {
      this.$emit('rangeSliderSelected')
    },
    emitWeightChange() {
      this.$emit('weightSliderChange', this.weight);
    },
    getWeight() {
      return this.weight
    },
    getReceivedWeightNames(weight) {
      let tempArrayOfweightNames = []
      for (let prop in weight) {
        if (weight[prop].isAvailable) {
          tempArrayOfweightNames.push(prop)
        }
      }
      this.weightNames = tempArrayOfweightNames
      let biggestValue = 0
      let biggestName = ''
      let weightObj = this.localCurrentWeight


      for (let prop in weightObj) {
        if (weightObj[prop].value > biggestValue) {
          biggestValue = weightObj[prop].value
          biggestName = prop
        }
      }

      let total = this.weightNames.reduce((acc, cur) => {
        weight = this.localCurrentWeight.find(weight => weight.name === cur);
        return acc + weight.value
      }, 0)

      let diff = 100 - total
      if (diff < 0) {
        let extra = total - 100;

        this.localCurrentWeight[biggestName].value = this.localCurrentWeight[biggestName].value - extra
      }
      if (diff > 0) {
        this.localCurrentWeight[0].value += diff;
      }

      if (diff === 0) {

      }
    },
    loadValues(weights) {
      this.localCurrentWeight = Object.keys(weights)
        .map((key) => {
          weights[key].name = key;
          return weights[key]
        })
        .filter(weight => weight.isAvailable);
      this.getReceivedWeightNames(weights)
    }
  },
  mounted() {
    this.loadValues(this.weight);
  },
  watch: {
    weight: {
      handler(newVal) {
        this.loadValues(newVal);
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" src="./WeightSlider.scss"/>
