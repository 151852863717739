<template>

  <div
    :class="[
      'card',
      {
        'card--undefined': undefinedCard,
        'card--no-card': !(name && suit)
      }
    ]"
  >
    <template v-if="!undefinedCard">
      <img
        :src="iconUrl"
        class="card__suit-icon card__suit-icon--top"
      />
      <div class="card__name">
        {{ computedName }}
      </div>
      <img
        :src="iconUrl"
        class="card__suit-icon card__suit-icon--bottom"
      />
    </template>

  </div>
</template>

<script>
export default {
  name: "Card",
  computed: {
    undefinedCard() {
      return !this.suit || !this.name
    },
    computedName() {
      return this.name.toUpperCase();
    },
    iconUrl() {
      let fileName = '';
      switch (this.suit) {
        case 's':
          fileName = 'spades';
          break;
        case 'd':
          fileName = 'diamonds';
          break;
        case 'c':
          fileName = 'clubs';
          break;
        case 'h':
          fileName = 'hearts';
          break;
      }
      return '/icons/card-suits/' + fileName + '.svg';
    }
  },
  props: {
    suit: String,
    name: String,
    width: [Number, String],
    height: [Number, String]
  }
}
</script>
