<template>
  <div
    :class="computedClasses"
    :data-position-number="positionNumber"
    :data-table-size="tableSize"
  >
    <div class="player__hand">
      <Card v-for="({ m, card }, i) in cards" :key="i" :name="card" :suit="m" />
    </div>
    <div class="game__player-position__block">
      <div class="game__player-position__ava">
        <div
          :style="{
            'background-color': color,
            color: checkIfBGColorIsLight ? 'inherit' : 'white'
          }"
          class="game__player-position__ava__circle"
        >
          {{ getCustomNameOfPosition(playerName) }}
        </div>
      </div>
      <div class="game__player-position__info">
        <div class="game__player-position__info__wrapper">
          <h4 class="game__player-position__name">
            <span>
              <!--              {{ notes }}-->
            </span>
          </h4>
          <h5 class="game__player-position__stack">
            {{ pot - bet }}
            <template v-if="cards && cards.length > 0">(Fold)</template>
          </h5>
        </div>
      </div>
    </div>
    <div v-if="isDealer" class="game__player-position__dealer-icon">
      D
    </div>
    <!--
      разметка для показа фишек - я бы мог просто загрузить иконку в img,
      но с этим вариантом можно менять размер, кол-во, отступ между фишками
    !-->
    <div v-if="bet" class="game__player-position__bet">
      <div class="game__player-position__bet__chips">
        <div
          class="game__player-position__bet__chips__item game__player-position__bet__chips__item--blue"
        >
          <div class="game__player-position__bet__chip" />
          <div class="game__player-position__bet__chip" />
          <div class="game__player-position__bet__chip" />
        </div>
        <div
          class="game__player-position__bet__chips__item game__player-position__bet__chips__item--aqua"
        >
          <div class="game__player-position__bet__chip" />
          <div class="game__player-position__bet__chip" />
          <div class="game__player-position__bet__chip" />
        </div>
      </div>
      <div class="game__player-position__bet__value">
        {{ bet }}
      </div>
    </div>
    <slot name="timer" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { CHECK_IF_COLOR_IS_LIGHT } from '@/utils/colors';
import { getCustomNameOfPosition } from '@/utils/positions';
import Card from '@/components/Game/Card';

export default {
  name: 'Position',
  components: {
    Card
  },
  props: {
    cards: {
      type: Array,
      default: () => []
    },
    tableSize: [Number, String],
    playerName: String,
    positionNumber: [String, Number],
    isDealer: [Boolean, undefined],
    isMe: Boolean,
    bet: [Number, undefined],
    pot: Number,
    color: String
  },
  methods: {
    getCustomNameOfPosition
  },
  computed: {
    ...mapGetters({
      globalPreferences: 'user/globalPreferences'
    }),

    checkIfBGColorIsLight() {
      return CHECK_IF_COLOR_IS_LIGHT(this.color);
    },
    computedClasses() {
      let classes = ['game__player-position'];

      /*
       Нулевая позиция всегда у пользователя,
       и в любом размере стола он должен находиться
       вертикально внизу и
       горизонтально по центру.

       Если позиция принадлежит оппоненту - задаем ему соответсвующий класс
      */
      const OWN_PLAYER_POSITION = 0;
      if (this.positionNumber === OWN_PLAYER_POSITION) {
        classes.push(
          'game__player-position--is-me',
          'game__player-position--v-bottom-h-center',
          'game__player-position--ava-from-left',
          'game__player-position--position-left'
        );
        return classes;
      } else {
        classes.push('game__player-position--is-opponent');
      }

      if (!this.bet) {
        classes.push('game__player-position--no-bet');
      }

      /* Выбираем расположение исходя из размера и номера позиции */

      /* TODO: переписать это волокно из свитчей */
      switch (this.tableSize) {
        case 2:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
          }
          break;
        case 3:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
          }
          break;
        case 6:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 1:
              classes.push(
                'game__player-position--v-bottom-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 5:
              classes.push(
                'game__player-position--v-bottom-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
          }
          break;
        case 8:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 1:
              classes.push(
                'game__player-position--v-bottom-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-center-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
            case 5:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 6:
              classes.push(
                'game__player-position--v-center-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 7:
              classes.push(
                'game__player-position--v-bottom-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
          }
          break;
      }

      return classes;
    }
  }
};
</script>
