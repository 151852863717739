<template>
  <div>
    <GroupBox class="premium-items-selector">
      <template v-slot:header>
        Premium Items Selector
        <div class="premium-items-selector__buttons">
          <Btn
            outlined
            @click="deleteSelectedItems"
          >
            Delete
          </Btn>
          <Btn
            @click="openRangesSelector()"
          >
            Add items
          </Btn>

        </div>
      </template>
      <template v-slot:content>
        <RangesRow
          ref="rangesRow"
          :passedItems="items"
          @itemSelect="onItemsSelect"
        />
      </template>
    </GroupBox>
    <UserRangesSelectorDialog
      v-model="rangesSelectorDialog"
      premium
      @rangesSelect="onRangesSelect"
    />
  </div>
</template>

<script>

import GroupBox from "../GroupBox";
import UserRangesSelectorDialog from "@/components/UserRangesSelectorDialog";
import RangesRow from "@/components/RangesRow";
import {GET_PREMIUM_ITEMS_BY_ID} from '@/api/premiumItems';
import Btn from "@/components/UI/Btn";

export default {
  name: "PremiumItemsSelector",
  components: {Btn, RangesRow, UserRangesSelectorDialog, GroupBox},
  data() {
    return {
      selectedItems: [],
      items: [],
      rangesSelectorDialog: false,
    }

  },
  props: {
    passedItems: Array
  },
  computed: {},
  methods: {
    getItems() {
      return this.items.map(item => item.id)
    },
    onItemsSelect(value) {
      this.selectedItems = value
    },
    deleteSelectedItems() {
      this.$refs.rangesRow.deleteSelectedItems();
    },
    openRangesSelector() {
      this.rangesSelectorDialog = true
    },
    onRangesSelect(value) {
      this.items = [...this.items, ...value.selectedItems]
    }
  },
  created() {
  },
  watch: {
    async passedItems(items) {
      if (items.length === 0) return

      const itemsIdString = items.join(',');
      this.items = await GET_PREMIUM_ITEMS_BY_ID(itemsIdString)
    }
  }
}
</script>
<style lang="scss" src="./PremiumItemsSelector.scss"/>