var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('router-link',{attrs:{"to":{
            name: 'Main'
          }}},[_c('img',{staticClass:"header__logo",attrs:{"src":"/img/logo.svg"}})]),_c('div',{staticClass:"header__license-expiration",style:({
            'border-color': _vm.isDanger ? '#CC2D3F' : null
          })},[_vm._v(" "+_vm._s(_vm.licenseIsActiveTill)+" ")]),_c('div',{staticClass:"header__menu"},_vm._l((_vm.menuItems),function(item){return _c('router-link',{key:item.route.name,class:[
              'header__menu__item',
              {
                'header__menu__item--active': _vm.$route.name === item.route.name
              }
            ],attrs:{"to":item.route}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1),_c('LangSwitcher',{staticClass:"header__langs"}),_c('div',{staticClass:"header__user"},[_c('div',{class:[
              'header__user__dropdown',
              {
                'header__user__dropdown--active': _vm.headerDropdownActive
              }
            ]},[_c('div',{staticClass:"header__user__dropdown__header",on:{"click":function($event){return _vm.openHeaderDropdown()}}},[_c('div',{staticClass:"header__user__dropdown__header__ava",style:({
                  'background-color': _vm.userBG
                })},[_c('span',{style:({
                    color: _vm.userBGIsLight ? 'dark' : 'white'
                  })},[_vm._v(" "+_vm._s(_vm.computeFirstEmailLetter)+" ")])]),_vm._v(" "+_vm._s(_vm.computeUserName)+" "),_c('svg',{staticClass:"header__user__dropdown__header__icon",attrs:{"data-src":"/icons/triangle.svg"}})]),_c('div',{staticClass:"header__user__dropdown__body"},[_c('div',{staticClass:"header__user__dropdown__list"},[_c('a',{staticClass:"header__user__dropdown__list__item",on:{"click":function($event){return _vm.goToLink({
                      name: 'Profile'
                    })}}},[_vm._v(" "+_vm._s(_vm.localization.general.Header.Profile)+" ")])]),_c('div',{staticClass:"header__user__dropdown__logout",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" "+_vm._s(_vm.localization.general.Header.Logout)+" ")])])])]),_c('div',{class:[
            'header__mobile-menu-icon',
            {
              'header__mobile-menu-icon--active': _vm.mobileMenu
            }
          ],on:{"click":function($event){return _vm.toggleMenu()}}},[_c('div',{staticClass:"header__mobile-menu-icon__line"}),_c('div',{staticClass:"header__mobile-menu-icon__line"}),_c('div',{staticClass:"header__mobile-menu-icon__line"})])],1)])]),_c('div',{class:[
      'overlay',
      {
        'overlay--active': _vm.mobileMenu
      }
    ],on:{"click":function($event){_vm.mobileMenu = !_vm.mobileMenu}}}),_c('div',{class:[
      'mobile-nav',
      {
        'mobile-nav--active': _vm.mobileMenu
      }
    ]},[_c('div',{staticClass:"mobile-nav__list"},[_vm._l((_vm.menuItems),function(item){return _c('router-link',{key:item.route.name,class:[
          'mobile-nav__list__item',
          {
            'mobile-nav__list__item--active': _vm.$route.name === item.route.name
          }
        ],attrs:{"to":item.route},nativeOn:{"click":function($event){_vm.mobileMenu = false}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),_c('div',{staticClass:"mobile-nav__list__divider"}),_c('router-link',{staticClass:"mobile-nav__list__item",attrs:{"to":{
          name: 'Profile'
        }},nativeOn:{"click":function($event){_vm.mobileMenu = false}}},[_vm._v(" "+_vm._s(_vm.localization.general.Header.Profile)+" ")]),_c('a',{staticClass:"mobile-nav__list__item",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){_vm.mobileMenu = false;
          _vm.logout();}}},[_vm._v(" "+_vm._s(_vm.localization.general.Header.Logout)+" ")]),_c('div',{staticClass:"mobile-nav__list__divider"}),_c('div',{staticClass:"mobile-nav__langs"},[_c('LangSwitcher',{attrs:{"dark":"","uppercase":""}})],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }