var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'board',
    {
      'board--wrong': _vm.wrong
    }
  ]},[_c('div',{staticClass:"board__bg"},[_c('div',{staticClass:"board__bg__item board__bg__item--bordered-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--inner-border-top-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--outer-border-top-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--outer-border-bottom-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--cascade"}),_c('div',{staticClass:"board__bg__item board__bg__item--green-layer",style:({
        'background': _vm.bgColor
      })}),_c('div',{staticClass:"board__bg__item board__bg__item--wrong board__bg__item--wrong-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--inner-shadow"})]),_vm._l((_vm.players),function(player){return _c('Position',{key:player.name,attrs:{"bet":player.rate,"cards":player.cards,"color":_vm.getColor(player.name),"isDealer":player.d,"isMe":player.position === 0,"playerName":player.name,"positionNumber":player.position,"pot":player.bank,"tableSize":player.size},scopedSlots:_vm._u([(player.isMe)?{key:"timer",fn:function(){return [_vm._t("timer")]},proxy:true}:null],null,true)})}),_c('div',{staticClass:"pot"},[_vm._v(" Pot: "+_vm._s(_vm.potNumber)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }