<template>
  <div class="container" v-if="loaded">
    <div class="main-row">
      <div
        :class="[
          'ranges-list',
          {
            'hidden-sm': rangesFooterCurrentView !== rangesFooterViewList,
            'visible-sm': rangesFooterCurrentView === rangesFooterViewList
          }
        ]"
      >
        <div class="ranges-list__actions">
          <DropdownMenu
            :header-label="localization.general.MainPage.CreateItemButton"
            :items="menuItemsForCreating"
            background-color="blue"
            class="ranges-list__actions__item"
            height="38"
          />
          <button
            :disabled="selectedItems.length === 0 && !currentItem"
            class="ranges-list__actions__item ranges-list__actions__item--delete"
            @click="openDeleteDialog()"
          >
            {{ localization.general.MainPage.DeleteItemButton }}
          </button>
        </div>
        <List
          ref="rangesList"
          :childrenProvider="getItems"
          :sortFunction="sortItems"
          class="ranges-list__items"
          @currentItemChange="onCurrentItemChange"
          @groupOpen="onGroupOpen"
          @itemParentChange="onItemParentChange"
          @selectedItemsChange="onSelectedItemsChange"
        >
          <template v-slot:card-outer="item">
            <div
              v-if="linkingMode && item.type === ITEM_TYPE_RANGE"
              class="ranges-list__items__disabled"
            />
          </template>
          <template v-slot:card-body-content="item">
            <div v-if="item.name === ITEM_TYPE_STORE" class="store" />
            <div v-if="item.purchaseInfo" class="purchased" />
          </template>
          <template v-slot:card-inner="item">
            <template v-if="item.name === ITEM_TYPE_STORE">
              <div class="list__item__card__label">
                {{ localization.general.MainPage.PremiumRangesFolder }}
              </div>
            </template>
            <template v-else>
              <div class="list__item__card__label">
                {{ item.name }}
              </div>
              <div
                v-if="item.hasOwnProperty('diapason')"
                class="list__item__card__diapason__info"
              >
                <div
                  v-if="item.type === ITEM_TYPE_GROUP && linkingMode"
                  @click="
                    $event.stopPropagation();
                    linkItem(item);
                  "
                >
                  <img
                    v-if="itemIsLinked(item)"
                    class="list__item__card__diapason__link-to-group"
                    src="/icons/close-wrapped.svg"
                  />
                  <img
                    class="list__item__card__diapason__link-to-group"
                    src="/icons/plus.svg"
                    v-else
                  />
                </div>
                <div
                  v-if="item.hasOwnProperty('price') && item.price !== null"
                  class="list__item__card__diapason__info__price"
                >
                  {{ item.price.value }}$
                </div>
                <div
                  v-if="
                    item.statistics.correct > 0 || item.statistics.incorrect > 0
                  "
                  class="list__item__card__diapason__info__answers"
                >
                  <div class="incorrect">
                    {{ item.statistics.incorrect }}
                  </div>
                  <div class="correct">
                    {{ item.statistics.correct }}
                  </div>
                </div>
              </div>
            </template>
          </template>
        </List>
        <div class="ranges-statistics hide-sm">
          <div class="ranges-statistics__values">
            <div class="ranges-statistics__values__item">
              <b>{{ localization.general.MainPage.RangesStatistics }}</b>
              {{ itemsStatistics.regular.diapasons }}
              (+{{ itemsStatistics.premium.diapasons }})
            </div>
            <div class="ranges-statistics__values__item">
              <b>{{ localization.general.MainPage.GroupsStatistics }}</b>
              {{ itemsStatistics.regular.groups }}
              (+{{ itemsStatistics.premium.groups }})
            </div>
          </div>
        </div>
      </div>
      <div
        :class="[
          'range-editor',
          {
            'hidden-sm': rangesFooterCurrentView !== rangesFooterViewEditor,
            'visible-sm': rangesFooterCurrentView === rangesFooterViewEditor
          }
        ]"
      >
        <StoreRangeViewer v-if="itemIsPremium" :item="currentItem" />
        <DefaultRangeEditor
          v-else
          ref="rangeEditor"
          :is-blocked="isBlocked"
          :item="currentItem"
          :rangesOfGroup="rangesOfGroup"
          @clearStatisticsOfGroup="onClearStatisticsOfGroup"
          @linkedItemsChange="onLinkedItemsChange"
          @linkingModeToggle="onLinkingModeToggle"
          @startTest="startTest()"
          @setActiveParticipantsToPlay="setActiveParticipantsToPlay"
        />
      </div>
    </div>

    <RangeViewChanger @changeView="changeRangesFooterView" />

    <CreateGroupDialog v-model="showCreateGroupDialog" :handler="createGroup" />
    <CreateRangeDialog v-model="showCreateRangeDialog" :handler="createRange" />
    <DeleteItemsDialog
      v-model="showDeleteDialog"
      :handler="deleteItems"
      :items="computedSelectedItems"
      @itemsDelete="onItemsDelete()"
    />
  </div>
</template>

<script>
import RangeViewsMixin from '../../mixins/RangeViewsMixin';

import { GET_STORE_ITEMS, GET_STORE_ITEMS_CHILDREN } from '@/api/stores';

import {
  CREATE_GROUP,
  CREATE_RANGE,
  DELETE_ITEMS,
  GET_ALL_ITEMS,
  GET_CHILDREN_OF_ITEM,
  ITEMS_COPY,
  MOVE_ITEM
} from '@/api/items';

import DefaultRangeEditor from '@/components/RangeEditor/DefaultRangeEditor';
import StoreRangeViewer from '@/components/StoreRangeViewer';
import { GET_ITEMS_STATISTICS_OF_AUTH_USER } from '@/api/statistics';
import DropdownMenu from '@/components/UI/DropdownMenu';
import { DEFAULT_PARENT_ID } from '@/constants/items';
import {
  ITEM_TYPE_GROUP,
  ITEM_TYPE_RANGE,
  ITEM_TYPE_STORE
} from '@/constants/itemTypes';

export default {
  name: 'Main',
  mixins: [RangeViewsMixin],
  components: {
    DropdownMenu,
    StoreRangeViewer,
    DefaultRangeEditor
  },
  data() {
    return {
      loaded: false,
      ITEM_TYPE_STORE,
      ITEM_TYPE_GROUP,
      ITEM_TYPE_RANGE,
      activeParicipantsToPlay: {},
      selectedItemsActiveParicipants: {},
      itemsStatistics: {
        regular: {
          diapasons: 0,
          groups: 0
        },
        premium: {
          diapasons: 0,
          groups: 0
        }
      }
    };
  },
  computed: {
    itemIsSelected() {
      if (!this.currentItem) return false;

      return this.currentItem.id !== ITEM_TYPE_STORE;
    },
    itemIsPremium() {
      return this.itemIsSelected
        ? this.currentItem.hasOwnProperty('price') || this.currentItem.store
        : null;
    }
  },
  methods: {
    changeRangesFooterView(view) {
      this.rangesFooterCurrentView = view;
    },
    setActiveParticipantsToPlay(value) {
      console.log(value, 'Value coming to setactive');
      this.activeParicipantsToPlay = {
        ...value
      };
      console.log(this.activeParicipantsToPlay, 'Value coming to setactive');
      console.log(
        'MAIN VIEW: this.activeParticipantToPlay',
        this.activeParicipantsToPlay
      );
    },
    async listRecursion(item, arResult = []) {
      console.log('START REC', item, arResult);
      const promises = item.children.map(async child => {
        if (child.value.type === ITEM_TYPE_RANGE) {
          arResult.push(child.value.id);
        } else {
          // let tempChild = child;
          child.children = await child.childrenProvider();
          console.log(child.children, 'MAIN VIEW: child children');

          await this.listRecursion(child, arResult);
        }
      });
      await Promise.all(promises);
      return arResult;
    },
    async getItems(item) {
      if (item) {
        if (item.store) {
          let children = item.id
            ? await GET_STORE_ITEMS_CHILDREN(item.id)
            : await GET_STORE_ITEMS();

          return children.map(item => {
            item.store = true;
            item.dragAndDropIn = false;
            item.dragAndDropOut = false;
            return item;
          });
        }

        return await GET_CHILDREN_OF_ITEM(item.id);
      } else {
        const items = await GET_ALL_ITEMS();
        return [...items.groups, ...items.diapasonsWithoutGroup];
      }
    },
    onItemParentChange(data) {
      this.itemParentChangeHandler(data, MOVE_ITEM);
    },
    onClearStatisticsOfGroup() {
      let items = this.$refs.rangesList.getItemsOfCurrent();

      items
        .filter(item => item.type === ITEM_TYPE_RANGE)
        .forEach(item => {
          item.diapason.statistics.incorrectAnswers = 0;
          item.diapason.statistics.correctAnswers = 0;
        });
    },
    async startTest() {
      let diapasonsForTest = [];

      let activePlayersForTest = { ...this.activeParicipantsToPlay };
      for (let item in activePlayersForTest) {
        diapasonsForTest.push(item);
      }
      console.log(activePlayersForTest, 'activePlayersForTest');
      console.log(diapasonsForTest, 'DIAPASONS FOR TESTZ');
      // if (activePlayersForTest == null || activePlayersForTest.length < 1) {
      //   console.warn('MAIN VIEW: NO ACTIVE TAB', activePlayersForTest);
      //   //TODO: Добавить warning если не выбран игрок у одного из полей
      //   return;
      // }

      let rangesList = this.$refs.rangesList;
      console.log(
        rangesList.$refs.rangesList,
        'MAIN VIEW: RANGES LIST IN START TEST'
      );

      let selectedItems = rangesList.selectedItems;
      let currentItem = rangesList.currentItem;
      let selectedItemsRecursion = [];
      console.log(selectedItems, 'MAIN VIEW: SELECTED ITEMS');
      console.log(currentItem, 'MAIN VIEW: CURRENT ITEMS');

      // let items = selectedItems.length > 0 ? selectedItems : [currentItem];

      // console.log(items, 'MAIN VIEW: ITEMS ');

      // if (items.length === 0) return;

      for (let item of selectedItems) {
        console.log(item, 'item in for');
        if (item.value.type === ITEM_TYPE_RANGE) {
          if (diapasonsForTest.indexOf(item.value.id)) {
            diapasonsForTest.push(item.value.id);
          }
        } else if (item.value.type === ITEM_TYPE_GROUP) {
          //TODO: Добавить рекурсию для поиска всех чилдренов
          console.log(item, 'MAIN VIEW: item in foreach list recursion');
          item.children = await item.childrenProvider();
          console.log(item, 'item after children');
          await this.listRecursion(item, selectedItemsRecursion);
          console.log(selectedItemsRecursion, 'MAIN VIEW: end list recursion');
        }
      }

      console.log(
        selectedItemsRecursion,
        'MAIN VIEW: Selected items recursion'
      );
      diapasonsForTest = [...diapasonsForTest, ...selectedItemsRecursion];
      console.log('MAIN VIEW: Diapason For Test', diapasonsForTest);
      localStorage.setItem(
        'diapasonsForTest',
        JSON.stringify(diapasonsForTest)
      );

      localStorage.setItem(
        'activePlayers',
        JSON.stringify(activePlayersForTest)
      );
      console.log(diapasonsForTest, 'MAIN VIEW: DIAPASONS FOR TEST');
      this.$router.push({
        name: 'Game'
      });
    },
    async copyItem() {
      await this.handleCopyItem(ITEMS_COPY);
      await this.updateItemsStatistics();
    },

    async createGroup(newGroup) {
      const items = this.$refs.rangesList.getItemsOfCurrent();
      let order = this.getLastOrder(items);

      newGroup.order = order;
      newGroup.parentId = this.currentItemParentId ?? DEFAULT_PARENT_ID;

      await CREATE_GROUP(newGroup).then(newGroupID => {
        this.$refs.rangesList.addData({
          id: newGroupID,
          name: newGroup.name,
          type: 'Group',
          order: order,
          parentId: this.currentItemParentId ?? DEFAULT_PARENT_ID,
          diapason: null
        });
      });
    },
    async createRange(newRange) {
      const items = this.$refs.rangesList.getItemsOfCurrent();
      const ranges = items.filter(item => item.type === ITEM_TYPE_RANGE);

      let order = this.getLastOrder(ranges);

      newRange.order = order;
      newRange.parentId = this.currentItemParentId;

      await CREATE_RANGE(newRange).then(newItemID => {
        try {
          this.$refs.rangesList.addData({
            type: ITEM_TYPE_RANGE,
            id: newItemID,
            order: order,
            parentId: this.currentItemParentId,
            purchaseInfo: null,
            name: newRange.name,
            diapason: {
              additionalProperties: newRange.additionalProperties,
              participants: newRange.participants,
              tableFormat: newRange.tableFormat,
              statistics: {
                correctAnswers: 0,
                incorrectAnswers: 0,
                hands: {}
              }
            }
          });
        } catch (error) {
          console.log(error, 'ERROR WITH CREATING');
        }

        this.itemsStatistics.regular.diapasons++;
      });
    },

    async deleteItems() {
      await this.handleDeletion(DELETE_ITEMS);
      await this.updateItemsStatistics();
    },
    async updateItemsStatistics() {
      this.itemsStatistics = await GET_ITEMS_STATISTICS_OF_AUTH_USER();
    }
  },
  async created() {
    this.$track.openRanges();
    await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');
    await this.updateItemsStatistics();
    this.loaded = true;
  }
};
</script>
