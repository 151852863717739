<template>
  <div
    :class="[
      'board',
      {
        'board--wrong': wrong
      }
    ]"
  >

    <div class="board__bg">
      <div class="board__bg__item board__bg__item--bordered-layer"/>
      <div class="board__bg__item board__bg__item--inner-border-top-layer"/>
      <div class="board__bg__item board__bg__item--outer-border-top-layer"/>
      <div class="board__bg__item board__bg__item--outer-border-bottom-layer"/>
      <div class="board__bg__item board__bg__item--cascade"/>

      <div
        :style="{
          'background': bgColor
        }"
        class="board__bg__item board__bg__item--green-layer"
      />
      <div class="board__bg__item board__bg__item--wrong board__bg__item--wrong-layer"/>

      <div class="board__bg__item board__bg__item--inner-shadow"/>

    </div>

    <Position
      v-for="player in players"
      :key="player.name"
      :bet="player.rate"
      :cards="player.cards"
      :color="getColor(player.name)"
      :isDealer="player.d"
      :isMe="player.position === 0"
      :playerName="player.name"
      :positionNumber="player.position"
      :pot="player.bank"
      :tableSize="player.size"
    >
      <template v-if="player.isMe" v-slot:timer>
        <slot name="timer"/>
      </template>
    </Position>
    <div class="pot">
      Pot: {{ potNumber }}
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import Position from "@/components/Game/Position";
import {getTablePositions} from "@/utils/positions";

const unknownHand = () => [{}, {}];
const noHand = () => [];

const isCard = (name, epIndex, index, hero) => {
  if (epIndex == 0) return unknownHand();
  if (name == hero || name == "SB" || name == "BB") return unknownHand();
  return epIndex <= index ? noHand() : unknownHand();
};

const getPositionNumber = (positionName, heroPosition, tableSize, participants, availableTablePositions, positionIndex) => {
  if (positionIndex >= tableSize) return false;
  if (positionName.toLowerCase() == heroPosition.toLowerCase()) return 0;

  if (tableSize == 2) {
    if (participants[positionName.toLowerCase()]) {
      return 3;
    } else {
      return false;
    }
  }
  if (tableSize == 3) {
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  if (tableSize == 6) {
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  if (tableSize == 8) {
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  return false;
};
const formatterRFI = (hero, hX, participants, size, hand) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex((el) => el == hero);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let ep = size == 8 ? "ep1" : "ep";
  let epIndex = newOrder.findIndex((el) => el == ep);
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  const isCardRFI = (el, epIndex, i, hero) => {
    if (size == 2)
      return unknownHand();
    if (size == 3 && heroIndex === 0)
      return unknownHand();
    if (size == 3 && heroIndex === 1 && i != 2)
      return unknownHand();
    return isCard(el, epIndex, i, hero);
  };

  let result = newOrder.map((el, i) => {
    return {
      name: el.toUpperCase(),
      cards: hero == el ? hand : isCardRFI(el, epIndex, i, hero),
      position: getPositionNumber(el, hero, size, participants, sizes, i),
      rate: el == "sb" ? 0.5 : el == "bb" ? 1 : 0,
      d: size == 2 ? el == "sb" : el == "bu",

      isView: i <= size,
      rfi: true,
      size: size,
      bank:
        i == 0
          ? hX
          : participants[el.toLowerCase()]
          ? participants[el.toLowerCase()].stack
          : 0,
    };
  });

  return result;
};
const formatterVS3B = ({hp, r, op, size, participants, n, hand}) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex((el) => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];

  return newOrder.map((el, i) => ({
    name: el.toUpperCase(),
    cards: hp == el ? hand : (el == op ? unknownHand() : noHand()),
    position: getPositionNumber(el, hp, size, participants, sizes, i),
    rate: el == op ? +n : el == hp ? +r : el == "sb" ? 0.5 : el == "bb" ? 1 : 0,
    d: size == 2 ? el == "sb" : el == "bu",
    size: size,
    bank: participants[el.toLowerCase()].stack,
  }));
};

const formatterSQZ = ({hp, r, op, cp, size, participants, n, hand}) => {
  console.log(cp);
  let order = getTablePositions(size);
  let heroIndex = order.findIndex((el) => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  window.console.log(participants);
  return newOrder.map((el, i) => ({
    name: el.toUpperCase(),
    cards: hp == el ? hand : (el == op || el == cp ? unknownHand() : noHand()),
    position: getPositionNumber(el, hp, size, participants, sizes, i),
    rate: el == op ? +n : el == cp ? +r : el == hp ? +r : el == "sb" ? 0.5 : el == "bb" ? 1 : 0,
    d: size == 2 ? el == "sb" : el == "bu",
    size: size,
    bank: participants[el.toLowerCase()].stack,
  }));
};

const formatterVSOR = ({hp, op, size, participants, n, hand}) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex((el) => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  let ep = size == 8 ? "ep1" : "ep";
  let epi = newOrder.findIndex((el) => el == ep);
  let hpR = 0;
  let defaultV = 0;

  const isCardVSOR = (epi, eli) => (epi > eli ? unknownHand() : noHand());

  if (hp == "bb") {
    hpR = 1;
  }
  if (hp == "sb") {
    hpR = 0.5;
  }

  return newOrder.map((el, i) => ({
    name: el.toUpperCase(),
    cards: hp == el ? hand : (el == op ? unknownHand() : size < 6 ? unknownHand() : isCardVSOR(epi, i)),
    position: getPositionNumber(el, hp, size, participants, sizes, i),
    rate:
      el == op ? +n : el == hp ? hpR : el == "sb" ? 0.5 : el == "bb" ? 1 : 0,
    d: el == "bu",
    size: size,
    bank: participants[el.toLowerCase()].stack,
    default: defaultV,
  }));
};
const formatterVS1RC = ({hp, op, cp, size, participants, n, hand}) => {
  console.log(cp);
  // r = 1
  // size = 8

  // get array of current positions with certain order
  let order = getTablePositions(size);
  // hero position index in order
  let heroIndex = order.findIndex((el) => el == hp);
  // move Hero to beginning and change general order
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  // strange shit - opponents size?
  if (size == 3) sizes = [2, 4];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  // ep = ep1 if 8 else ep
  // if 8 then ep is not single and starts 'ep1' (few ep's)
  let ep = size == 8 ? "ep1" : "ep";
  // ep index
  let epIndex = newOrder.findIndex((el) => el == ep);
  // hero position RATE
  let hpR = 0;
  // DEFAULT VALUE??????????
  let defaultV = 0;
  // different HP bet rate for different positions
  if (hp == "bb") {
    hpR = 1;
  }
  if (hp == "sb") {
    hpR = 0.5;
  }

  // compare epIndex and current array element index. if epIndex bigger return [1,2]
  const isCardVS1RC = (el, i) => {
    let opponentHand = null;
    if (el == op || el == cp) {
      opponentHand = unknownHand();
    }
    if (opponentHand == null) {
      if (size < 6)
        opponentHand = unknownHand();
      else
        opponentHand = (epIndex > i ? unknownHand() : noHand());
    }
    return opponentHand;
  };
  const getRate = (el) => {
    let rate = 0;
    if (el == op) {
      rate = +n;
    } else if (el == hp) {
      rate = +hpR;
    } else if (el == cp) {
      rate = +n;
    } else if (el == "sb") {
      rate = 0.5;
    } else if (el == "bb") {
      rate = 1;
    } else {
      rate = 0;
    }
    return rate;
  };

  let result = newOrder.map((el, i) => {
    return {
      name: el.toUpperCase(),
      cards: hp == el ? hand : isCardVS1RC(el, i),
      position: getPositionNumber(el, hp, size, participants, sizes, i),
      rate: getRate(el),
      d: el == "bu",
      size: size,
      bank: participants[el.toLowerCase()].stack,
      default: defaultV,
    }
  });

  return result;
};

const formatterVS4BET = ({hp, op, r, participants, size, n, hand}) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex((el) => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  return newOrder.map((el, i) => ({
    name: el.toUpperCase(),
    items: el == op ? unknownHand() : noHand(),
    cards: hp == el ? hand : null,
    position: getPositionNumber(el, hp, size, participants, sizes, i),
    rate: el == op ? +n : el == hp ? +r : el == "sb" ? 0.5 : el == "bb" ? 1 : 0,
    d: el == "bu",
    size: size,
    isView: i <= size,
    bank: participants[el].stack,
  }));
};
export default {
  name: "Board",
  props: {
    bgColor: String,
    timebank: Number,
    hand: Object,
    participants: Object,
    handCards: Array,
    prevGame: Object,
    range: Object,
    isWrong: Boolean
  },
  components: {
    Position
  },
  data() {
    return {
      wrong: false,
    };
  },
  methods: {
    getColor(key) {
      if (this.participants && this.participants[key.toLowerCase()]) {
        return this.participants[key.toLowerCase()].color;
      }
      return "";
    },
  },
  watch: {
    prevGame() {
      // запускается каждый раз, когда меняется игра  -
      // НЕ ПОКАЗЫВАТЬ ОШИБКУ СРАЗУ, ДАБЫ СОЗДАТЬ НЕКИЙ ЭФФЕКТ МЕРЦАНИЯ СТОЛА :D

      this.wrong = false;

      setTimeout(() => {
        this.wrong = this.isWrong;
      }, 100);
    }
  },
  computed: {
    players() {
      return this.gameData
        .filter(item => typeof item.position !== 'boolean')
        .map(item => {
          item.isMe = item.position === 0;
          return item;
        })
    },
    potNumber() {
      return this.gameData.reduce((acc, val) => (val.rfi ? acc + val.rate : acc + val.rate), this.gameData[0].default ? this.gameData[0].default : 0)
    },
    getTrueAnswer() {
      if (this.prevGame) {
        const {call, fold, raise} = this.prevGame.item.weight;
        let string = "";
        if (call) string += "Call " + call + "; ";
        if (fold) string += "Fold " + fold + "; ";
        if (raise) string += "Raise " + raise + "; ";
        if (!string) string += "Fold 100; ";
        return string.slice(0, string.length - 2);
      }
      return "";
    },

    gameData() {
      const {
        additionalProperties: {type, r, n, size, hp, op, cp},
        participants,
      } = this.range;

      switch (type) {
        case "RFI": {
          // const { hero, hX, oX } = data;
          return formatterRFI(
            hp,
            participants[hp.toLowerCase()].stack,
            participants,
            size,
            this.handCards
          );
        }
        case "VS3B": {
          return formatterVS3B({
            r,
            n,
            size,
            hp,
            op,
            participants,
            hand: this.handCards,
          });
        }
        case "VSOR": {
          return formatterVSOR({
            r,
            n,
            size,
            hp,
            op,
            participants,
            hand: this.handCards,
          });
        }
        case "VS1RC": {
          return formatterVS1RC({
            r,
            n,
            size,
            hp,
            op,
            cp,
            participants,
            hand: this.handCards,
          });
        }
        case "SQZ": {
          return formatterSQZ({
            r,
            n,
            size,
            hp,
            op,
            cp,
            participants,
            hand: this.handCards,
          });
        }
        case "VS4BET": {
          return formatterVS4BET({
            r,
            n,
            size,
            hp,
            op,
            participants,
            hand: this.handCards,
          });
        }
        default: {
          return null;
        }
      }
    },
  }
};
</script>
