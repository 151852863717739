import deepClone from '@/utils/dataManipulations';
import { Expander, ExpanderItem } from '@/components/UI/Expander';
// import _ from 'lodash';

export default {
  data: () => ({
    watchForUpdates: false,
    watchForPartsUpdates: false
  }),
  components: {
    Expander,
    ExpanderItem
  },
  props: {
    isReadOnly: Boolean,
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    updateIsAvailable() {
      if (!this.watchForUpdates) return;
      console.log('UPDAVAILB updateIsAvailable');
      this.$emit('updated');
    }
    // partsFirstInitial() {
    //   for (let key in this.dataItem.participants) {
    //     this.parts[key] = _.cloneDeep(
    //       _.omit(this.dataItem.participants[key], ['hands'])
    //     );
    //   }
    // }
  },
  watch: {
    // parts: {
    //   handler(val) {
    //     if (!this.watchForPartsUpdates) {
    //       this.watchForPartsUpdates = true;
    //       return;
    //     }
    //     console.log('hello', val); //Здеь идет обработка tempParts
    //     this.$emit('getTempParts', val);
    //     this.updateIsAvailable();
    //   },
    //   deep: true,
    //   immediate: false
    // },
    item: {
      async handler(value) {
        console.log(value, 'value');
        if (!value) return; // DO NOT UPDATE, IF VALUE IS NULL

        // DISABLE WATCHER, IF PROP HAS CHANGED, because update must be available only if inner values(data) were changed

        this.watchForUpdates = false;

        this.dataItem = deepClone(value); // Everything in dataItem, will be watched
      },
      immediate: true
    },

    dataItem: {
      async handler() {
        // Check if update is available (it's disabled if "group" prop changes)
        this.updateIsAvailable();

        // Enable watcher
        this.watchForUpdates = true;
      },
      deep: true,
      immediate: true
    }
  }
};
