<template>
  <div
    :class="[
      'string-parser',
      {
        'string-parser--disabled': disabled
      }
    ]"
  >
    <input
      v-model="stringForParse"
      :class="{
          'string-parser__input--invalid': this.result.isError
        }"
      :disabled="disabled"
      :placeholder="localization.general.TextFieldParser.Input"
      class="string-parser__input"
      @input="onInput"
      @keyup.enter="getColodes()"
    >

    <svg
      class="string-parser__icon"
      data-src="icons/arrow-right.svg"
      @click="getColodes()"
    />
  </div>
</template>

<script>

import {getColodesFromString} from "@/utils/colode";

export default {
  name: "RangeStringParser",
  data() {
    return {
      stringForParse: '',
      result: {},
      buttonStyle: {
        width: "34px",
        height: "34px"
      }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getColodes() {
      if (this.disabled) return

      this.result = getColodesFromString(this.stringForParse);
      this.stringForParse = "";
      this.$emit('rangeStringParse', this.result);
    },
    onInput() {
      this.result.isError = false
    }
  }
}
</script>
<style lang="scss" src="./RangeStringParser.scss"/>