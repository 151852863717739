<template>
  <Dialog
    v-model="computedValue"
    class="create-range-dialog modal-dialog--size-to-content modal-dialog--width-medium"
  >
    <DialogHeader>
      {{ localization.general.MainPage.NewDiapason }}
    </DialogHeader>
    <DialogContent>
      <div class="form-block">
        <label class="form-label-text">
          {{ localization.general.MainPage.DiapasonName }}
        </label>
        <input
          ref="itemName"
          v-model="newRangeData.name"
          class="form-input"
          type="text"
          @keyup.enter="addItem"
        />
      </div>
    </DialogContent>
    <DialogFooter>
      <Btn
        :disabled="!newRangeData.name"
        :loading="requestPending"
        @click="addItem()"
      >
        {{ localization.general.Simple.Save }}
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';
import { DEFAULT_PARENT_ID } from '@/constants/items';

export default {
  name: 'CreateRangeDialog',
  components: { Btn, Dialog, DialogContent, DialogHeader, DialogFooter },
  props: {
    value: Boolean,
    handler: {
      type: Function
    }
  },
  data() {
    return {
      requestPending: false,
      newRangeData: {
        parentId: DEFAULT_PARENT_ID,
        name: '',
        additionalProperties: {
          type: 'RFI',
          size: 2,
          hp: 'sb',
          op: 'mp',
          cp: null,
          r: 0,
          n: 0
        },
        tableFormat: 'RFI sb5 10',
        participants: {
          ep: {
            stack: 100,
            color: null,
            hands: {},
            stats: '',
            notes: ''
          },
          mp: {
            stack: 100,
            color: null,
            hands: {},
            stats: '',
            notes: ''
          },
          co: {
            stack: 100,
            color: null,
            hands: {},
            stats: '',
            notes: ''
          },
          bu: {
            stack: 100,
            color: null,
            hands: {},
            stats: '',
            notes: ''
          },
          sb: {
            stack: 100,
            color: null,
            hands: {},
            stats: '',
            notes: ''
          },
          bb: {
            stack: 100,
            color: null,
            hands: {},
            stats: '',
            notes: ''
          }
        }
      }
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.closeModal();
      }
    }
  },
  methods: {
    async addItem() {
      this.requestPending = true;

      await this.handler(this.newRangeData)
        .then(() => {
          this.newRangeData.name = '';
          this.closeModal();
        })
        .catch(error => {
          if (error.Code === 'items_limit_reached') {
            this.$toast.error('Items Limit Reached');
          }
        })
        .then(() => {
          this.requestPending = false;
        });
    },
    closeModal() {
      this.$emit('input', false);
    }
  },
  watch: {
    value(val) {
      if (val) this.$refs.itemName.focus();
    }
  }
};
</script>

<style lang="scss" src="./CreateRangeDialog.scss"></style>
