var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"range-table-editor"},[_c('table',{staticClass:"range-table-editor__matrix"},[_c('tbody',_vm._l((_vm.rowIterator),function(row,x){return _c('tr',{key:x,staticClass:"range-table-editor__row"},_vm._l((row),function(hand,y){return _c('td',{key:y,class:[
            'range-table-editor__cell',
            `range-table-editor__cell__${hand.name}`,
            {
              'range-table-editor__cell--selected-for-test': hand.useForTest,
              'range-table-editor__cell--has-weight': _vm.isWeightValid(
                hand.weight
              ),
              'range-table-editor__cell--highlighted':
                _vm.highlightHands && _vm.highlightHands.includes(hand.name)
            }
          ],on:{"mousedown":function($event){return _vm.startSelection(hand)},"mouseover":function($event){return _vm.selectHand(hand)}}},[_c('div',{staticClass:"range-table-editor__background"}),(_vm.isWeightValid(hand.weight))?_c('div',{staticClass:"range-table-editor__weight"},_vm._l((_vm.weights),function(weightName){return _c('span',{key:weightName,staticClass:"range-table-editor__weight-value",style:({
                width: hand.weight[weightName] * 100 + '%',
                'background-color': _vm.actionColors[weightName]
              }),attrs:{"weight-type":weightName}})}),0):_vm._e(),_c('div',{staticClass:"absolute-position range-table-editor__foreground"}),_c('div',{staticClass:"absolute-position range-table-editor__info"},[_c('div',{staticClass:"range-table-editor__hand-name"},[_vm._v(" "+_vm._s(hand.name)+" ")]),(
                hand.statistics &&
                  _vm.showStatistics &&
                  (hand.statistics.correct > 0 ||
                    hand.statistics.incorrect > 0)
              )?_c('p',{class:[
                'range-table-editor__statistics',
                {
                  'range-table-editor__statistics--with-mistakes':
                    hand.statistics.incorrect > 0
                }
              ]},[_vm._v(" "+_vm._s(_vm.formatStatisticsValue(hand.statistics.incorrect))+" / "+_vm._s(_vm.formatStatisticsValue( hand.statistics.correct + hand.statistics.incorrect ))+" ")]):_vm._e()])])}),0)}),0)]),_c('div',{staticClass:"range-table-editor__state"},[_c('div',{staticClass:"range-table-editor__state__item"},[_c('b',[_vm._v("Fold: ")]),_c('div',{staticClass:"range-table-editor__state__item__value"},[_vm._v(" "+_vm._s((parseFloat(_vm.rangeState.fold) * 100).toFixed(2))+"% ("+_vm._s(parseInt(_vm.rangeState.foldCount))+") ")])]),_c('div',{staticClass:"range-table-editor__state__item"},[_c('b',[_vm._v("Call: ")]),_c('div',{staticClass:"range-table-editor__state__item__value"},[_vm._v(" "+_vm._s((parseFloat(_vm.rangeState.call) * 100).toFixed(2))+"% ("+_vm._s(parseInt(_vm.rangeState.callCount))+") ")])]),_c('div',{staticClass:"range-table-editor__state__item"},[_c('b',[_vm._v("Raise: ")]),_c('div',{staticClass:"range-table-editor__state__item__value"},[_vm._v(" "+_vm._s((parseFloat(_vm.rangeState.raise) * 100).toFixed(2))+"% ("+_vm._s(parseInt(_vm.rangeState.raiseCount))+") ")])]),_c('div',{staticClass:"range-table-editor__state__item"},[_c('b',[_vm._v("Allin: ")]),_c('div',{staticClass:"range-table-editor__state__item__value"},[_vm._v(" "+_vm._s((parseFloat(_vm.rangeState.allin) * 100).toFixed(2))+"% ("+_vm._s(parseInt(_vm.rangeState.allinCount))+") ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }