export default {
  data() {
    return {}
  },

  props: {
    rangesOfGroup: Array,
    item: Object,
    isBlocked: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {

    isNullItem() {
      return this.item === null;
    },
    isRange() {
      return this.item?.type === "Diapason"
    },
    isGroup() {
      return this.item?.type === "Group"
    },
    itemName() {
      return this.item ? this.item.name : '';
    },
    computedRange() {
      return this.isRange ? this.item : null;
    },
    computedRangeInfo() {

      if (this.isRange)
        return this.item.diapason;

      if (this.isGroup)
        return this.groupHandsRange

      return null
    },

  },
  methods: {}
}