var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'game__prev',
    {
      'game__prev--win': _vm.game.result,
      'game__prev--lose': !_vm.game.result,
    }
  ]},[_c('div',{staticClass:"game__prev__body"},[_c('div',{staticClass:"game__prev__hand"},[_c('div',{staticClass:"game__prev__cards"},_vm._l((_vm.game.hand.cards),function({ m, card },i){return _c('Card',{key:i,staticClass:"game__prev__card",attrs:{"name":card,"suit":m}})}),1)]),(_vm.showAnswer)?_c('div',[_c('div',{staticClass:"game__prev__result game__prev__highlight-text"},[_vm._v(" "+_vm._s(_vm.game.result ? _vm.localization.general.Board.Win : _vm.localization.general.Board.Loose)+" ")]),_c('div',{staticClass:"game__prev__correct-answer"},[_vm._v(" "+_vm._s(_vm.localization.general.Board.prefixCorrectResult)+": "+_vm._s(_vm.getTrueAnswer)+" ")]),_c('div',{staticClass:"game__prev__player-answer"},[_vm._v(" "+_vm._s(_vm.localization.general.Board.prefixResult)+": "),_c('b',{staticClass:"game__prev__highlight-text"},[_vm._v(" "+_vm._s(_vm.getAnswer)+" ")])])]):_c('div',{staticClass:"game__prev__player-answer"},[_vm._v(" "+_vm._s(_vm.localization.general.Board.prefixResult)+": "),_c('span',[_vm._v(" "+_vm._s(_vm.getAnswer)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }