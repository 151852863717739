<template>
  <div
    :class="[
      'range-editor-wrapper',
      {
        'range-editor-wrapper--is-blocked': isBlocked
      }
    ]"
  >
    <slot name="BeforeRangeOptions" />
    <ItemRangeOptionsEditor
      v-show="isRange"
      ref="itemRangeOptionsEditor"
      :item="rangeForItemOptionsEditor"
      :isReadOnly="isReadOnly || itemHasPurchaseInfo"
      :requestPending="updateItemRequestPending"
      :btnDisabled="startTestDisabled && !updateAvailable"
      :btnLoading="updateItemRequestPending"
      :btnColor="action.btnColor"
      :btnLabel="action.label"
      @btnAction="runAction"
      @checkWeightToggle="onCheckWeightToggle"
      @updateItemOptions="onItemOptionsUpdate"
      @updated="onValueUpdate"
      @activePart="setActiveParticipant"
      @getTempParts="getTempParts"
      @heroPos="setHeroPos"
    />

    <ItemGroupOptionsEditor
      v-show="isGroup"
      ref="itemGroupOptionsEditor"
      :item="groupForItemOptionsEditor"
      :isReadOnly="isReadOnly || itemHasPurchaseInfo"
      :isPremiumGroup="itemHasPurchaseInfo"
      :linkedItemsProvider="loadLinkedItems"
      :requestPending="updateItemRequestPending"
      :btnDisabled="startTestDisabled && !updateAvailable"
      :btnLoading="updateItemRequestPending"
      :btnColor="action.btnColor"
      :btnLabel="action.label"
      @btnAction="runAction"
      @linkedItemsChange="onLinkedItemsChange"
      @linkingModeToggle="onLinkingModeToggle"
      @updateItemOptions="onItemOptionsUpdate"
      @updated="onValueUpdate"
    />

    <div class="range-table-editor__actions">
      <div class="range-table-editor__actions__inputs">
        <RadioGroup
          v-model="selectionMode"
          class="range-table-editor__actions__radio-btns"
        >
          <Radio
            v-for="option in options"
            :key="option.label"
            :label="option.label"
            :value="option.value"
            class="range-table-editor__actions__radio-btns__item"
          />
        </RadioGroup>

        <div class="range-table-editor__actions__checkboxes">
          <Checkbox
            v-model="showStatistics"
            :label="localization.general.MainPage.ShowStatistics"
          />
        </div>
      </div>
      <div class="range-table-editor__actions__buttons">
        <DropdownMenu
          :header-label="localization.general.MainPage.Table"
          :items="menuItemsForTable"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
        <DropdownMenu
          :header-label="localization.general.MainPage.Clear"
          :items="clearMenuItems"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
      </div>
    </div>

    <template v-if="itemHasPurchaseInfo">
      <RangeWarning />
    </template>
    <template v-else>
      <WeightSlider
        ref="weightsSlider"
        :weight="weightsSlider"
        @weightSliderChange="onWeightSliderChange"
        @click.native="resetSliderRange()"
      />
      <WeightsList
        ref="weightsList"
        :weights="weights"
        @weightSelected="weightSelected"
      />
    </template>

    <slot name="AfterRangeOptions" />
    <slot name="BeforeTable" />

    <RangeTableEditor
      ref="rangeTableEditor"
      :actionColors="actionColors"
      :hands="computedRangeHands"
      :is-read-only="isGroup || isReadOnly || itemHasPurchaseInfo"
      :selectionMode="selectionMode"
      :showStatistics="showStatistics"
      :statistics="computedRangeStatistics"
      :weight="computedSelectedWeights"
      @handsSelect="onHandsSelect"
      @scrollChanges="scrollChanges"
      :activeParticipant="activeParticipant"
    />

    <slot name="AfterTable" />

    <RangeSlider
      ref="rangeSlider"
      :is-read-only="!isRange || itemHasPurchaseInfo || isReadOnly"
      :maxValue="169"
      :minValue="0"
      :step="0"
      :value="0"
      @rangeSliderChange="onRangeSliderChange"
    />
    <RangeStringParser
      :disabled="disableRangeStringParser || itemHasPurchaseInfo"
      @rangeStringParse="onRangeStringParse"
    />
  </div>
</template>

<script>
import {
  CLEAR_GROUP_STATISTICS,
  CLEAR_STATISTICS,
  GET_ITEMS_INFO,
  UPDATE_ITEM_GROUP,
  UPDATE_ITEM_RANGE,
  UPDATE_PREMIUM_ITEM_GROUP
} from '@/api/items';

// import { isWeightValid } from '@/utils/game';

import RangeEditorMixin from '../RangeEditorMixin';
import _ from 'lodash';
import { ITEM_TYPE_GROUP } from '@/constants/itemTypes';

export default {
  name: 'DefaultRangeEditor',

  mixins: [RangeEditorMixin],
  data() {
    return {
      updateAvailable: false,
      watchForUpdates: false,
      activeParticipant: null,
      heroPosition: '',
      tempParts: {
        type: Object
      }
    };
  },
  props: {
    purchaseInfo: Object
  },
  computed: {
    action() {
      if (this.updateAvailable) {
        return {
          label: this.localization.general.MainPage.SaveDiapasonChanges,
          handler: this.applyChanges,
          btnColor: 'red'
        };
      } else {
        return {
          label: this.localization.general.MainPage.StartTest,
          handler: this.startTest,
          btnColor: 'blue'
        };
      }
    },
    groupHasNoRanges() {
      return this.isGroup && this.rangesOfGroup.length === 0;
    },
    startTestDisabled() {
      return this.groupHasNoRanges || this.linkingMode;
    }
  },

  methods: {
    getTempParts(val) {
      // this.updateAvailable = true;

      this.tempParts = val;
      console.log(this.tempParts, 'TEMP PARTS IN DEFAULT RANGE EDITOT');
    },
    //Устанавливаем в главном компоненте активного игрока из PositionTabs

    setActiveParticipant(val) {
      if (this.activeParticipant === val) return;
      this.activeParticipant = val;
    },
    //TODO:
    scrollChanges(hands) {
      console.log(hands, 'HANDZ coming');

      this.tempDiapason[this.activeParticipant].hands = hands;
    },
    setHeroPos(val) {
      this.heroPosition = val;
      let tempHeroObj = {};
      tempHeroObj[this.item.id] = val;
      console.log(typeof val, val, 'HERO POSITION FROM DEFAULT');
      this.$emit('setActiveParticipantsToPlay', tempHeroObj);
    },
    onValueUpdate() {
      console.log('UPDAVAILB onValueUpdate');
      this.updateAvailable = true;
    },
    async loadLinkedItems(itemIDs) {
      console.log(
        'Default Range Editor: loadLinkedItems',
        itemIDs,
        typeof itemIDs
      );
      return await GET_ITEMS_INFO(itemIDs);
    },
    async runAction() {
      console.log('ds');
      this.action
        .handler()
        .then(() => {
          this.updateAvailable = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async startTest() {
      let activePlayer = this.activeParticipant;
      let hasHands = false;
      if (this.item.type === 'Diapason') {
        for (let prop in this.tempDiapason[activePlayer]?.hands) {
          console.log(prop, 'Default Range Editor: PROP IN START TEST');
          let handUsedForTest = this.tempDiapason[activePlayer]?.hands[prop]
            .useForTest;

          if (handUsedForTest) {
            hasHands = true;
          }
        }

        if (!hasHands) {
          this.$toast.error(
            'Select the cells to test the range and press Save button'
          );
          return;
        }

        this.$emit('startTest', activePlayer);
      } else {
        this.$emit('startTest');
      }
    },

    async applyChangesToGroup() {
      const itemGroupOptionsEditor = this.$refs.itemGroupOptionsEditor;
      const appliedChangesToGroup = itemGroupOptionsEditor.submit();

      const linksGroup = appliedChangesToGroup.data.linkedItems
        ? {
            items: appliedChangesToGroup.data.linkedItems
          }
        : null;
      let isPremium = this.itemHasPurchaseInfo;
      return new Promise(async (resolve, reject) => {
        if (!appliedChangesToGroup.validated) {
          reject({
            errorCode: 'NotValidated'
          });
          return;
        }

        if (isPremium) {
          console.log('I am premium');
          await UPDATE_PREMIUM_ITEM_GROUP({
            name: appliedChangesToGroup.data.name,
            linksGroup: linksGroup,
            id: this.item.id,
            parentId: this.item.parentId
          })
            .then(() => {
              this.item.name = appliedChangesToGroup.data.name;
              this.item.linksGroup = linksGroup;

              itemGroupOptionsEditor.turnOffLinkingMode();

              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          await UPDATE_ITEM_GROUP({
            name: appliedChangesToGroup.data.name,
            linksGroup: linksGroup,
            id: this.item.id,
            parentId: this.item.parentId
          })
            .then(() => {
              this.item.name = appliedChangesToGroup.data.name;
              this.item.linksGroup = linksGroup;

              itemGroupOptionsEditor.turnOffLinkingMode();

              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    },
    async applyChangesToRange() {
      //проверка прошли ли изменения в рендж и айтем
      console.log('rabotttt', this);
      const appliedChangesToCurrentItem = this.$refs.rangeTableEditor.applyChanges();
      const updatedRange = this.$refs.itemRangeOptionsEditor.submit();
      return new Promise(async (resolve, reject) => {
        if (!appliedChangesToCurrentItem.validated || !updatedRange.validated) {
          reject({
            errorCode: 'NotValidated'
          });
          return;
        }
        // console.log(this.$refs, 'refs this');
        // console.log('UPDATED RANGE', updatedRange);
        // console.log('appliedChangesToCurrentItem', appliedChangesToCurrentItem);
        let tempPartsToMerge = this.tempParts;
        let tempHandsToMerge = this.tempDiapason;

        console.log(
          tempPartsToMerge,
          tempHandsToMerge,
          'Default Range Editor: temp diapasons to merge'
        );

        // TODO: Нужно перенести в utils, возможно еще понадобится
        const deepMergeHands = (parts, hands) => {
          let finalMerged = {};
          for (let key in parts) {
            finalMerged[key] = _.merge(parts[key], hands[key]);
          }
          return finalMerged;
        };
        console.log(
          deepMergeHands(tempPartsToMerge, tempHandsToMerge),
          'Default Range Editor: function merge'
        );
        let mergedHands = deepMergeHands(tempPartsToMerge, tempHandsToMerge);
        console.log('Default Range Editor: merged hands', mergedHands);
        const payload = updatedRange.data;
        // payload.participants = this.item.diapason.participants; OLD PARTICIPANTS QUERY
        payload.participants = mergedHands;
        payload.parentId = this.item.parentId;
        payload.id = this.item.id;
        // payload.hands = appliedChangesToCurrentItem.data.hands; OLD PUT QUERRY
        console.log(payload, 'Default Range Editor: payload');

        await UPDATE_ITEM_RANGE(payload)
          .then(() => {
            this.item.name = updatedRange.data.name;
            this.item.diapason.additionalProperties =
              updatedRange.data.additionalProperties;
            // this.item.diapason.hands = appliedChangesToCurrentItem.data.hands;
            this.item.diapason.participants = payload.participants;

            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async clearStatistics() {
      this.$refs.rangeTableEditor.clearStatistics();

      if (this.isRange) {
        this.item.diapason.statistics.incorrectAnswers = 0;
        this.item.diapason.statistics.correctAnswers = 0;
      } else {
        this.$emit('clearStatisticsOfGroup');
      }

      let request;
      if (this.item.type === ITEM_TYPE_GROUP) {
        request = CLEAR_GROUP_STATISTICS;
      } else {
        request = CLEAR_STATISTICS;
      }

      return await request(this.item.id);
    }
  },
  watch: {
    item() {
      this.watchForUpdates = false;
      this.updateAvailable = false;
    }
  }
};
</script>

<style lang="scss" src="./DefaultRangeEditor.scss" />
