<template>
  <section
    :style="{
      'background-color': table.backgroundColor
    }"
    class="game"
  >
    <template v-if="!gameStatisticsDialog">
      <Dialog
        v-if="isTouchpad"
        v-model="colorPicker"
        class="modal-dialog--size-to-content"
      >
        <DialogHeader>
          {{ colorPickerTypeTitle }}
        </DialogHeader>
        <DialogContent>
          <ColorPicker
            :colors="backgroundColors"
            class="color-picker-popup__row"
            item-height="50px"
            item-width="50px"
            @input="setColor"
          />
        </DialogContent>
      </Dialog>
      <div
        v-if="!isTouchpad"
        :class="{
          'color-picker-popup--active': colorPicker
        }"
        :style="{
          top: colorPickerPosition.y,
          left: colorPickerPosition.x
        }"
        class="color-picker-popup"
      >
        <div class="color-picker-popup__overlay" @click="closeColorPicker()" />
        <div class="color-picker-popup__container">
          <div class="color-picker-popup__title">
            {{ colorPickerTypeTitle }}
          </div>
          <ColorPicker
            :colors="backgroundColors"
            class="color-picker-popup__row"
            item-height="50px"
            item-width="50px"
            @input="setColor"
          />
        </div>
      </div>

      <div class="game__header">
        <div class="game__header__info">
          <div class="game__timebank">
            <svg height="0" width="0">
              <defs>
                <linearGradient
                  id="cl1"
                  gradientUnits="objectBoundingBox"
                  x1="0"
                  x2="1"
                  y1="0"
                  y2="1"
                >
                  <stop stop-color="#e32a89" />
                  <stop offset="100%" stop-color="#498a98" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              class="game__timebank__svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g class="game__timebank__circle">
                <circle
                  class="game__timebank__path-elapsed"
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="url(#cl1)"
                />
                <path
                  ref="timebankRemaining"
                  :class="[
                    'game__timebank__path-remaining',
                    'game__timebank__path-remaining--' + timebankType
                  ]"
                  d="
                    M 50, 50
                    m -45, 0
                    a 45,45 0 0,0 90,0
                    a 45,45 0 0,0 -90,0
                  "
                  stroke-dasharray="283"
                />
              </g>
            </svg>
            <span ref="gameContentTimebankLabel" class="game__timebank__label">
              {{ timeLeft }}
            </span>
          </div>
          <div class="game__hands-remained" v-if="false">
            <img src="/img/game/hands-remained.svg" />
            {{ handsLeft }}
          </div>
        </div>
        <router-link
          class="game__logo"
          :to="{
            name: 'Main'
          }"
        >
          <img src="/img/logo.svg" />
        </router-link>
        <div class="game__header__btns-row">
          <Btn
            v-for="(btn, index) in gameHeaderBtns"
            :key="index"
            :class="[
              'game__header__btn--' + btn.classModificator,
              'game__header__btn'
            ]"
            color="white"
            height="38px"
            outlined
            @click="btn.handler"
          >
            <span class="game__header__btn__show-on-desktop">{{
              btn.label
            }}</span>
            <svg
              :data-src="btn.icon"
              class="game__header__btn__show-on-mobile game__header__btn__show-on-mobile__icon"
            />
          </Btn>
        </div>
      </div>
    </template>
    <template v-if="isLoading">
      <div class="game--loading">
        <img src="/img/loading.gif" alt="loading" />
      </div>
    </template>
    <template v-if="!isLoading">
      <div
        ref="gameContent"
        class="game__content"
        @contextmenu="callColorPickerOnContextMenu($event, 'Main Background')"
      >
        <Board
          ref="board"
          :bgColor="computedBoardColor"
          :hand="hand"
          :handCards="handCards"
          :isWrong="isWrongAnswer"
          :participants="participants"
          :prevGame="prevGame"
          :range="currentRange.diapason"
          :timebank="timebank"
          @contextmenu.native="callColorPickerOnContextMenu($event, 'Board')"
        >
          <template v-slot:timer>
            <div class="game__timer">
              <div class="game__timer__row">
                <div
                  v-for="second in linearTimerIndicatorLength"
                  :key="second"
                  :class="[
                    'game__timer__item',
                    {
                      'game__timer__item--filled':
                        second <= computedLinearSeconds
                    }
                  ]"
                />
              </div>
            </div>
          </template>
        </Board>
      </div>
      <ButtonsForAnswer
        v-if="hand"
        :allWeight="currentRangeWeights"
        :isHaveAllin="isHaveAllin"
        :isHaveCheck="isHaveCheck"
        :randomNumber="randomNumber"
        :situation="situation"
        :weight="hand.weight"
        @finishAnswer="finishGame"
      >
        <template v-slot:prepend>
          <div
            v-if="prevGame"
            :class="[
              'game__info',
              {
                'game__info--active': gameInfoIsActive
              }
            ]"
          >
            <div class="game__info__toggler" @click="toggleGameInfo()">
              <img src="/icons/triangle-right.svg" />
            </div>
            <div class="game__info__content">
              <PreviousGameInfo ref="prevGame" :game="prevGame" />
              <svg
                class="game__info__close"
                data-src="/icons/close.svg"
                @click="toggleGameInfo()"
              />
            </div>
          </div>
        </template>
      </ButtonsForAnswer>

      <RangePreviewDialog
        v-model="isRangePreviewOpened"
        :highlightHands="previewHand"
        :range="previewRange"
      />
      <GameHelpDialog v-model="isHelpOpened" @changeGameMode="changeGameMode" />
    </template>

    <GameStatisticsDialog
      v-model="gameStatisticsDialog"
      :statData="statistics"
      @finishGame="completeGamesAndQuit"
    />
  </section>
</template>

<script>
import Board from '@/components/Game/Board';
import GameHelpDialog from '@/components/GameHelpDialog';
import RangePreviewDialog from '@/components/RangePreviewDialog';

import { mapGetters } from 'vuex';
import { GET_CARDS_FROM_NAME } from '@/utils/colode';
import {
  // GET_ALL_WEIGHT,
  GET_ALL_WEIGHT_FROM_ARRAY,
  // GET_CARDS_FROM_OBJECT,
  GET_CARDS_FROM_ARRAY,
  GET_CORRECT_ANSWER_RANDOMIZER,
  GET_RESULT
} from '@/utils/game';

import {
  GET_ITEMS_TREE,
  GET_ITEMS_HANDS,
  GET_FULL_ITEM_INFO
} from '@/api/items';

import { PATCH_GLOBAL_PREFERENCES } from '@/api/globalPreferences';

import { Dialog, DialogContent, DialogHeader } from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';
import GameStatisticsDialog from '@/components/GameStatisticsDialog';
import ColorPicker from '@/components/UI/ColorPicker/ColorPicker';
import ButtonsForAnswer from '@/components/Game/ButtonsForAnswer';
import PreviousGameInfo from '@/components/Game/PreviousGameInfo';
import { SHADE_COLOR } from '@/utils/colors';

// max sets for a hand
const MAX_GAMES_PER_HAND = 5;

export default {
  name: 'Game',
  components: {
    PreviousGameInfo,
    ButtonsForAnswer,
    ColorPicker,
    Btn,

    DialogHeader,
    DialogContent,
    Dialog,

    Board,
    GameHelpDialog,
    GameStatisticsDialog,
    RangePreviewDialog
  },
  data() {
    return {
      gameStatisticsDialog: false,
      loaded: false,
      isLoading: false,
      localActivePlayers: {},
      playingIDS: [],

      gameInfoIsActive: false,
      timestamps: {
        info: null,
        warning: null,
        alert: null
      },
      timebankType: null,

      handsLeft: null,
      answerTimer: null,
      timeLeft: 0,
      timePassed: 0,
      remainingPathColor: null,

      ready: false,
      selectedBackgroundColor: '#273142',
      backgroundColors: [
        ['#333', '#000', '#293042', '#5d6270'],
        ['#dbc34c', '#ce9137', '#db7a4c', '#c34242'],
        ['#4288c3', '#42bdc3', '#42c38d', '#097227'],
        ['#c34278']
      ],
      colorPicker: false,
      colorPickerType: '',
      colorPickerPosition: { x: 0, y: 0 },
      handsTreeToPlay: [],
      handsTreeRemaining: 0,
      handsTreeSecondArray: [],

      randomNumber25: 0,
      fullDiapasonInfo: [],

      isRangePreviewOpened: false,
      isHelpOpened: false,
      previewRange: null,
      previewHand: null,

      currentRange: null,
      currentRangeWeights: [],
      hand: null,
      participants: null,
      startTime: 0,
      endTime: 0,
      randomNumber: null,
      currentPlayer: null,

      games: [],
      statistics: {
        totalTime: 0,
        totalHands: 0,
        correctAnswers: 0,
        incorrectAnswers: 0,
        quality: 0,
        averageAnswerTime: 0
      }
    };
  },

  computed: {
    ...mapGetters({
      getGameMode: 'play/getGameMode',
      table: 'user/table',
      globalPreferences: 'user/globalPreferences'
    }),
    linearTimerIndicatorLength() {
      return screen.width > 575 ? 10 : 6;
    },
    computedLinearSeconds() {
      const oneIndicatorValue = this.timebank / this.linearTimerIndicatorLength;
      return Math.ceil(this.timeLeft / oneIndicatorValue);
    },
    timebank() {
      return this.globalPreferences.game.timebank;
    },
    gameHeaderBtns() {
      return [
        {
          classModificator: 'help',
          handler: this.showHelp,
          label: this.localization.general.Game.Help,
          icon: '/icons/help.svg'
        },
        /*{
          classModificator: 'current',
          handler: this.showCurrentRange,
          label: this.currentRangeName
        },*/
        {
          classModificator: 'finish',
          handler: this.finishAndShowGameStatistics,
          label: this.localization.general.Game.Finish,
          icon: '/icons/finish.svg'
        }
      ];
    },
    colorPickerTypeTitle() {
      return this.colorPickerType === 'Board'
        ? this.localization.general.Board.TableColorSelector
        : this.localization.general.Board.GameBackgroundColorSelector;
    },
    isWrongAnswer() {
      return this.prevGame != null ? !this.prevGame.result : false;
    },
    situation() {
      let isVsRFIr1hBB = this.isCheckAvailable(this.currentRange);

      if (isVsRFIr1hBB) return 'VsRFIr1hBB';

      return null;
    },

    isHaveCheck() {
      return this.isCheckAvailable(this.currentRange);
    },
    isHaveAllin() {
      let currentRangeWeights = GET_ALL_WEIGHT_FROM_ARRAY(
        this.handsTreeToPlay //TODO:
      );

      return currentRangeWeights.some(weight => weight.allin);
    },
    currentRangeName() {
      return this.currentRange.name ?? '';
    },
    isTouchpad() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    computedBoardColor() {
      const firstColor = this.table.tableColor;
      const secondColor = SHADE_COLOR(firstColor, -35);
      const thirdColor = SHADE_COLOR(firstColor, -25);

      return `radial-gradient(157.33% 157.33% at 50% 36.96%, rgba(0, 0, 0, 0) 0%, ${secondColor} 100%), radial-gradient(63.04% 63.04% at 50% 36.96%, ${firstColor} 0%, ${thirdColor} 100%)`;
    },
    handCards() {
      console.log(this.hand, 'THIS KEYCODE');
      if (this.hand) return GET_CARDS_FROM_NAME(this.hand.name); //TODO: здесь поменять на

      return null;
    },
    prevGame() {
      if (this.games.length === 0) return null;

      return this.games[this.games.length - 1];
    }
  },
  methods: {
    fillArrayHands(array) {
      let arr = array;
      const length = 25;
      const startLength = arr.length;
      for (let i = startLength; i < length; i++) {
        console.log(arr[Math.random(0, startLength)], 'MATH RANDOM INT');
        arr.push(arr[this.getRandomInt(0, startLength)]);
      }

      return arr;
    },
    findIndex(arr, handName) {
      return arr.findIndex(obj => obj.hand.handKey === handName);
    },
    toggleGameInfo() {
      this.gameInfoIsActive = !this.gameInfoIsActive;
    },
    setCircleDasharray() {
      const rawTimeFraction = this.timeLeft / this.timebank;
      const circleDasharray = `${(
        (rawTimeFraction - (1 / this.timebank) * (1 - rawTimeFraction)) *
        283
      ).toFixed(0)} 283`;

      console.log(this.$refs, 'this refs');
      this.$refs.timebankRemaining.setAttribute(
        'stroke-dasharray',
        circleDasharray
      );
    },
    async getFullDiapasonInfo(itemIds) {
      let finalDiapason = [];
      for (let itemID of itemIds) {
        let diapason = await GET_FULL_ITEM_INFO(itemID);
        console.log(diapason, 'diapason coming');
        finalDiapason.push(diapason[0]);
      }
      console.log(finalDiapason, 'FINAL DIAPASON');
      this.fullDiapasonInfo = finalDiapason;
    },
    startAnswerTimer() {
      this.timePassed = 0;
      this.timeLeft = this.timebank;

      this.answerTimer = setInterval(() => {
        // The amount of time passed increments by one
        this.timePassed++;
        this.timeLeft = this.timebank - this.timePassed;

        if (this.timeLeft <= this.timestamps.alert) {
          this.timebankType = 'alert';
        } else if (this.timeLeft <= this.timestamps.warning) {
          this.timebankType = 'warning';
        } else {
          this.timebankType = 'info';
        }

        if (this.timeLeft <= 0) {
          this.finishGame({
            raise: null,
            check: null,
            call: null,
            fold: null,
            allin: null
          });
          clearInterval(this.answerTimer);
          this.startAnswerTimer();
        }
        this.setCircleDasharray();
        // The time left label is updated
      }, 1000);
    },

    setHotKeyEvents(event) {
      const hotKeys = this.globalPreferences.hotKeys;
      const nextRangeHotKey = hotKeys.currentRangePreview;
      const prevRangeHotKey = hotKeys.prevRangePreview;

      if (!nextRangeHotKey && !prevRangeHotKey) return;
      if (event.repeat) return;

      let modifierKey = null;

      if (event.ctrlKey) modifierKey = 'ctrl';
      else if (event.altKey) modifierKey = 'alt';
      else if (event.shiftKey) modifierKey = 'shift';

      const nextRangeKeyCodesAreSame =
        nextRangeHotKey.keyCode === event.keyCode;
      const prevRangeKeyCodesAreSame =
        prevRangeHotKey.keyCode === event.keyCode;

      const checkIfModifierKeysAreEmpty = obj => {
        let empty = true;
        Object.keys(obj).forEach(key => {
          if (key !== 'keyCode' && nextRangeHotKey[key]) {
            empty = false;
          }
        });
        return empty;
      };
      if (modifierKey) {
        if (nextRangeHotKey[modifierKey] && nextRangeKeyCodesAreSame) {
          event.preventDefault();
          this.showCurrentRange();
        }
        if (prevRangeHotKey[modifierKey] && prevRangeKeyCodesAreSame) {
          event.preventDefault();
          this.showPrevRange();
        }
      } else {
        if (
          nextRangeKeyCodesAreSame &&
          checkIfModifierKeysAreEmpty(nextRangeHotKey)
        ) {
          event.preventDefault();
          this.showCurrentRange();
        }
        if (
          prevRangeKeyCodesAreSame &&
          checkIfModifierKeysAreEmpty(nextRangeHotKey)
        ) {
          event.preventDefault();
          this.showPrevRange();
        }
      }
    },
    //if check is available then fold and call isn't
    isCheckAvailable(currentRange) {
      const diapasonData = currentRange.diapason.additionalProperties;
      return (
        diapasonData.hp === 'bb' &&
        diapasonData.n == '1' &&
        diapasonData.type === 'VSOR'
      );
    },
    closeColorPicker() {
      this.colorPicker = false;
    },
    setColor(color) {
      this.colorPicker = false;

      let property =
        this.colorPickerType === 'Board' ? 'tableColor' : 'backgroundColor';
      this.table[property] = color;

      this.$store.commit('user/SET_TABLE_COLORS', this.table);

      PATCH_GLOBAL_PREFERENCES({
        table: {
          value: this.table
        }
      });
    },
    callColorPickerOnContextMenu(e, type) {
      e.stopPropagation();
      e.preventDefault();

      this.colorPicker = true;
      this.colorPickerType = type;

      const x = e.clientX;
      const y = e.clientY;

      const colorPicker = document.querySelector('.color-picker-popup');
      const width = colorPicker.clientWidth;
      const height = colorPicker.clientHeight;

      const detectIfSpaceOnRightEnded = window.innerWidth - (x + width);
      const detectIfSpaceOnBottomEnded = window.innerHeight - (y + height);

      const ModifiedX =
        detectIfSpaceOnRightEnded < 0 ? x + detectIfSpaceOnRightEnded : x;
      const ModifiedY =
        detectIfSpaceOnBottomEnded < 0 ? y + detectIfSpaceOnBottomEnded : y;

      // Set the position for menu
      this.colorPickerPosition.y = ModifiedY + `px`;
      this.colorPickerPosition.x = ModifiedX + `px`;
    },
    finishAndShowGameStatistics() {
      localStorage.removeItem('diapasonsForTest');
      clearInterval(this.answerTimer);

      if (this.statistics.totalHands) {
        this.statistics.quality = parseInt(
          (this.statistics.correctAnswers * 100) / this.statistics.totalHands
        );
        this.gameStatisticsDialog = true;
      } else {
        this.$router.push({
          name: 'Main'
        });
      }
    },

    async completeGamesAndQuit() {
      await this.$store
        .dispatch('play/quitAndSaveStatistics', {
          games: this.games
        })
        .then(() => {
          this.$track.finishGame();
          this.$router.push({
            name: 'Main'
          });
        });
    },
    finishGame(heroAnswer) {
      // process received result from buttons component
      this.stopTimer();

      // RESULT CALCULATION
      // getting result by utility script
      const result = GET_RESULT(
        this.hand,
        heroAnswer,
        this.handCards,
        this.getGameMode,
        this.situation,
        this.randomNumber
      );

      const game = {
        answer: result.answer,
        result: result.result,
        hand: {
          name: result.item.name,
          use: result.item.use,
          weight: result.item.weight,
          cards: result.cards
        },
        id: this.currentRange.id,
        name: this.currentRange.name,
        range: this.currentRange,
        isHaveCheck: this.isHaveCheck,
        isHaveFold: !this.isHaveCheck,
        isHaveAllin: this.isHaveAllin,
        situation: this.situation,
        correctAnswerRandomizer: GET_CORRECT_ANSWER_RANDOMIZER()
      };

      if (game.result) {
        // if result == true (win)
        console.log('GAME RESUUULT', game);
        if (this.prevGame == null || this.prevGame.result !== 0) {
          this.statistics.correctAnswers++;
          this.statistics.totalHands++;
        }
        this.hand = null;
        this.games.push(game);

        clearInterval(this.answerTimer);
        this.startAnswerTimer();
        this.nextGame();
      } else {
        // if result == false (loss)
        if (this.prevGame == null || this.prevGame.result !== 0) {
          // counts only if didn't answer wrong on previous show up
          this.statistics.incorrectAnswers++;
          this.statistics.totalHands++;
        }

        if (this.globalPreferences.game.openRangePreview)
          this.showCurrentRange();

        this.games.push(game);
        this.startTimer();
      }
    },

    async nextGame() {
      this.startTimer();
      console.log('NNNNEEEXXTT');
      this.randomNumber = this.getRandomInt(1, 100);
      let maxRandomNumber = this.handsTreeToPlay.length - 1;
      console.log(maxRandomNumber, 'MAX RANDOM NuMBER');
      this.randomNumber25 = this.getRandomInt(0, maxRandomNumber);

      try {
        let handsLeft = 0;
        this.handsTreeRemaining = this.handsTreeToPlay.length;
        // let handsTreeLeft = 0;
        if (this.handsTreeRemaining < 11) {
          let payload = await GET_ITEMS_HANDS(this.playingIDS);
          let sliced = [...this.handsTreeToPlay, ...payload];
          this.handsTreeToPlay = this.secondItemsTreeHands;
          console.log(sliced, 'SLICED');
          console.log(payload, 'PAYLOAD');
        }

        let activePositions = this.$store.state.play.activePlayers;
        console.log(this.$store.state.play.game, 'this.store');
        let gameData = this.fullDiapasonInfo.filter(item => {
          //TODO: поменять на укороченный диапазон(не полный как сейчас)
          // let itemId = item.id;
          // let activePlayer = activePositions[itemId];
          //TODO:сюда добавить выбранную позицию по типу item.diapason.participants[activeHands[itemId]].hands

          let hands = GET_CARDS_FROM_ARRAY(this.handsTreeToPlay).filter(r => {
            const handResults = this.games
              .filter(el => el.hand.name == r.name)
              .filter(el => el.id === item.id)
              .reduce((acc, val) => (val.result == 1 ? acc + 1 : 0), 0);
            console.log(r, 'ABCD');
            handsLeft += MAX_GAMES_PER_HAND - handResults;
            // handsTreeLeft = hands;
            console.log(handResults, 'THIS HANDS RESULTS');

            if (handResults >= MAX_GAMES_PER_HAND) {
              let index = this.findIndex(this.handsTreeToPlay, r.name);
              console.log('special: index', index);
              this.handsTreeToPlay = this.handsTreeToPlay.filter(
                obj => obj.hand.handKey !== r.name
              );

              console.log(this.handsTreeToPlay, 'special: hands tree to play');
              return false;
            }
            return true;
          });
          console.log(hands, 'HANDS INSIDE FIRST ITERATION');
          return hands.length;
        });
        console.log(handsLeft, 'GAME VIEW: HANDS LEFT');
        this.handsLeft = handsLeft;
        // if no more diapasons to play

        if (gameData.length === 0 || handsLeft <= 0) {
          console.warn('GAME VIEW: no hands to play first excepton', gameData);
          this.finishAndShowGameStatistics();
          return;
        }
        console.log(gameData, 'GAME VIEW: gameData before range');
        // current diapason to play
        let range = gameData[[Math.floor(Math.random() * gameData.length)]]; //выбор рандомного range
        let currentRangePlayer = activePositions[range.id];
        console.log(currentRangePlayer, 'GAME VIEW: currentRangePlayer');
        console.log('GAME VIEW: range', range);
        console.log(
          'GAME VIEW: range diapason bb hands',
          range.diapason?.participants
        );

        this.currentRange = range; //TODO: сменить рендж на игровой, который в руке
        this.currentPlayer = currentRangePlayer;

        // if (range.diapason && range.diapason.participants) {
        //   this.participants = range.diapason.participants;
        // }
        console.log('GAME VIEW');
        //  array of all diapasons cells with filled weight - set to component
        this.currentRangeWeights = GET_ALL_WEIGHT_FROM_ARRAY(
          this.handsTreeToPlay
        );
        // this.currentRangeWeights = GET_ALL_WEIGHT(
        //   range.diapason.participants[currentRangePlayer].hands
        // );
        console.log(
          this.currentRangeWeights,
          'GAME VIEW: current range weights'
        );
        //TODO: переделать на другой диапазон

        // the 2 assumed hands (double cards with weights) for the set

        let hands = GET_CARDS_FROM_ARRAY(this.handsTreeToPlay).filter(r => {
          //TODO: Переделать на diapason.participants[activeParticipant].hands
          console.log(this.games, 'gamesR games');
          const gamesR = this.games
            .filter(el => el.hand == r.name)
            .filter(el => el.id === range.id)
            .reduce((acc, val) => {
              console.log('dsfsdfsd');
              return val.result == 1 ? acc + 1 : 0;
            }, 0);
          console.log(gamesR, 'gamesR');
          if (gamesR >= MAX_GAMES_PER_HAND) {
            return false;
          }
          console.log(gamesR, 'gamesR 2');
          return true;
        });

        // if no cards to play
        if (hands.length === 0) {
          console.error('GAME VIEW: no hands to play', hands);
          this.finishAndShowGameStatistics();
          return;
        }
        console.log(hands, 'GAME VIEW: hands');
        // the current set hand (2 cards)
        this.hand = hands[Math.floor(Math.random() * hands.length)];

        // this.hand = this.handsTreeToPlay[0].hand;
        console.log(this.hand, 'this.hands');
      } catch (err) {
        this.$router.push({
          name: 'Main'
        });
      }
    },
    showCurrentRange() {
      if (this.currentRange) {
        this.previewRange = this.currentRange;
        this.previewHand = [this.hand.name]; //TODO: hand.handKey
        console.log(this.previewHand, 'PREVIEW HAND');
        this.isRangePreviewOpened = !this.isRangePreviewOpened;
      }
    },
    showPrevRange() {
      if (this.prevGame?.range?.diapason) {
        this.previewRange = this.prevGame.range;
        this.previewHand = [this.prevGame.hand.name];
        this.isRangePreviewOpened = !this.isRangePreviewOpened;
      }
    },

    showHelp() {
      this.isHelpOpened = true;
    },
    changeGameMode(mode) {
      this.$store.commit('play/SET_ACTIVE_MODE', mode);
    },
    startTimer() {
      this.startTime = performance.now();
    },
    stopTimer() {
      this.endTime = performance.now();
      let timeDiff = (this.endTime - this.startTime) / 1000;

      // get seconds
      let seconds = Math.ceil(timeDiff);
      this.statistics.totalTime += seconds;
      if (this.statistics.totalHands > 1)
        this.statistics.averageAnswerTime = Math.ceil(
          this.statistics.totalTime / this.statistics.totalHands
        );
      else {
        this.statistics.averageAnswerTime = Math.ceil(
          this.statistics.totalTime
        );
      }
    },

    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    setSizesOfBoard() {
      let container = this.$refs.gameContent;
      let board = this.$refs.board;
      let gameTable = this.$refs.gameTable;

      const findSize = (width, height) => {
        let TABLE_SIZES = [
          {
            size: 'xl',
            width: 1150,
            height: 560,
            offsetX: 520,
            offsetY: 250
          },
          {
            size: 'l',
            width: 700,
            height: 340,
            offsetX: 250,
            offsetY: 230
          },
          {
            size: 'm',
            width: 560,
            height: 560,
            offsetX: 130,
            offsetY: 280
          },
          {
            size: 's',
            width: 380,
            height: 475,
            offsetX: 90,
            offsetY: 200
          },
          {
            size: 'xs',
            width: 250,
            height: 380,
            offsetX: 0,
            offsetY: 0
          }
        ];

        TABLE_SIZES = TABLE_SIZES.map(size => {
          size.fullWidth = size.width + size.offsetX;
          size.fullHeight = size.height + size.offsetY;
          size.class = 'game--' + size.size;
          return size;
        });
        const LARGEST_TABLE_SIZE = TABLE_SIZES[0];

        console.log('Width & Height of container: ', width, height);

        let foundTableSize;

        let containerWidthIsLargest = LARGEST_TABLE_SIZE.fullWidth < width;
        let containerHeightIsLargest = LARGEST_TABLE_SIZE.fullHeight < height;

        if (containerHeightIsLargest && containerWidthIsLargest) {
          console.log('get largest table');

          return LARGEST_TABLE_SIZE;
        }

        /* if container width is largest, but height is not - select appropriate to screen */
        if (containerWidthIsLargest) {
          foundTableSize = TABLE_SIZES.find(size => size.fullHeight < height);
          console.log('get table size with largest width', foundTableSize);
        }
        /* if container height is largest, but width is not - select appropriate to screen */
        if (containerHeightIsLargest) {
          foundTableSize = TABLE_SIZES.find(size => size.fullWidth < width);
          console.log('get table size with largest height', foundTableSize);
        }

        if (!foundTableSize) {
          foundTableSize = TABLE_SIZES.find(
            size => width > size.fullWidth && height > size.fullHeight
          );
          console.log('found table size', foundTableSize, width, height);
        }

        if (!foundTableSize) {
          foundTableSize = TABLE_SIZES[TABLE_SIZES.length - 1];
          console.log(
            'Table size not found - getting smallest',
            foundTableSize
          );
        }

        return foundTableSize;
      };

      let containerWidth = container.clientWidth;
      let containerHeight = container.clientHeight;

      let foundTableSize = findSize(containerWidth, containerHeight);

      if (foundTableSize) {
        board.$el.style.width = foundTableSize.width + 'px';
        board.$el.style.height = foundTableSize.height + 'px';
        gameTable.className = 'game__table game__table--' + foundTableSize.size;
      } else {
        gameTable.className = 'game__table';
        board.$el.style.removeProperty('width');
        board.$el.style.removeProperty('height');
      }
    }
  },
  created() {
    const localActivePlayers = JSON.parse(
      localStorage.getItem('activePlayers')
    );
    this.localActivePlayers = localActivePlayers;
    const diapasonsForTest =
      JSON.parse(localStorage.getItem('diapasonsForTest')) ?? [];
    console.log(diapasonsForTest, 'pids DTEST');
    for (let item of diapasonsForTest) {
      console.log(item, this.playingIDS, 'pids');
      this.playingIDS = [...this.playingIDS, item];
    }
    this.$store.commit('play/START_GAME', diapasonsForTest);
    this.$store.commit('play/SET_ACTIVE_PLAYERS', localActivePlayers);
    console.log(diapasonsForTest, 'GAME VIEW: DIAPASONS FOR TEST');
    console.log('GAME VIEW: localActivePlayers', localActivePlayers);
    this.$track.startGame();
  },
  async mounted() {
    /*window.onbeforeunload = function() {
      return confirm("Вы уверены? Вся статистика очистится после выхода");
    };*/
    this.isLoading = true;
    // const itemIDsPlay =
    //   JSON.parse(localStorage.getItem('diapasonsForTest')) ?? [];

    let itemsTree = await GET_ITEMS_TREE(this.playingIDS);
    let itemsTreeHands = await GET_ITEMS_HANDS(this.playingIDS);
    let secondItemsTreeHands = await GET_ITEMS_HANDS(this.playingIDS);
    console.log(itemsTreeHands, 'zapolnenie: itemsTreeHandsAfterFetch');
    if (itemsTreeHands.length < 1) this.finishAndShowGameStatistics();
    //TODO:
    //If items < 25 - fill array with same hands
    // if (itemsTreeHands.length < 25) {
    //   itemsTreeHands = this.fillArrayHands(itemsTreeHands);
    //   secondItemsTreeHands = this.fillArrayHands(secondItemsTreeHands)
    // }

    await this.getFullDiapasonInfo(this.playingIDS);
    console.log(itemsTreeHands, 'zapolnenie: itemsTreeHandsAfterWhileLoop');
    console.log(secondItemsTreeHands, 'zapolnenie: itemsSecondary');

    //Set 2 arrays to play(second is temp array to load items )
    this.handsTreeToPlay = itemsTreeHands;
    this.handsTreeSecondArray = secondItemsTreeHands;

    this.handsTreeRemaining = itemsTreeHands.length;

    console.log(itemsTree, 'GAME VIEW: ITEMS TREE');
    console.log(itemsTreeHands, 'GAME VIEW: ITEM TREE HANDS');
    // await this.$store.commit('play/START_GAME', itemsTree);
    await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');

    this.loaded = true;
    this.isLoading = false;
    this.timestamps.warning = this.timebank / 2;
    this.timestamps.alert = this.timebank / 4;

    this.randomNumber = this.getRandomInt(1, 100);
    this.nextGame();

    window.addEventListener('keydown', this.setHotKeyEvents);

    if (window.innerWidth > 767) {
      document.addEventListener('click', e => {
        if (!this.hand) return false;

        let colorPickerDiv = document.querySelector('.color-picker');
        if (colorPickerDiv) {
          if (
            !e.target.classList.contains('color-picker') &&
            !colorPickerDiv.contains(e.target)
          ) {
            this.colorPicker = false;
          }
        }
      });
    }

    this.startAnswerTimer();

    this.ready = true;
  },
  beforeDestroy() {
    clearInterval(this.answerTimer);
    this.answerTimer = null;

    window.onbeforeunload = null;
    window.removeEventListener('keydown', this.setHotKeyEvents);
  }
};
</script>
<style lang="scss" src="../assets/scss/game.scss" />
