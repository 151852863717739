<template>
  <div>
    <div v-show="isBlocked" class="hide-all" />
    <div class="range-editor__title">
      <h4>{{ itemName }}</h4>
    </div>

    <slot name="BeforeRangeOptions" />
    //FIXME: test
    <Expander>
      <ExpanderItem v-show="isRange">
        <template v-slot:header>
          {{ localization.general.MainPage.RangeOptions }}
        </template>
        <template v-slot:content>
          <ItemRangeOptionsEditor
            v-if="isRange"
            ref="itemRangeOptionsEditor"
            :diapason="computedRange"
            :is-read-only="true"
          />
        </template>
      </ExpanderItem>
      <ExpanderItem v-show="isGroup">
        <template v-slot:header>
          {{ localization.general.MainPage.RangesGroupOptions }}
        </template>
        <template v-slot:content>
          <ItemGroupOptionsEditor
            v-if="isGroup"
            ref="itemGroupOptionsEditor"
            :group="item"
            :is-read-only="true"
          />
        </template>
      </ExpanderItem>
    </Expander>

    <slot name="BeforeTable" />

    <RangeTableEditor
      ref="rangeTableEditor"
      :is-read-only="true"
      :range="computedRangeInfo"
    />

    <slot name="AfterTable" />
  </div>
</template>

<script>
import { Expander, ExpanderItem } from '@/components/UI/Expander';
import ItemRangeOptionsEditor from '@/components/ItemOptionsEditor/ItemRangeOptionsEditor';
import RangeTableEditor from '@/components/RangeTableEditor';
import ItemGroupOptionsEditor from '@/components/ItemOptionsEditor/ItemGroupOptionsEditor';
import itemsMixin from '@/mixins/itemsMixin';

export default {
  name: 'RangeViewer',
  mixins: [itemsMixin],
  components: {
    RangeTableEditor,
    ItemRangeOptionsEditor,
    ItemGroupOptionsEditor,
    ExpanderItem,
    Expander
  }
};
</script>

<style lang="scss" src="./RangeViewer.scss" />
