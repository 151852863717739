import http from '../utils/http';

import { DEFAULT_PARENT_ID } from '@/constants/items';
import { ITEM_TYPES } from '@/constants/itemTypes';

export const GET_CHILDREN_OF_ITEM = async (id = DEFAULT_PARENT_ID) => {
  const { data } = await http.get(`Items/${id}/Children`);
  return data;
};
// get tree full item
export const GET_ITEM = async (parentId = DEFAULT_PARENT_ID) => {
  const { data } = await http.get('Items', {
    params: {
      parentItemId: parentId
    }
  });
  const groups = data.filter(item => ITEM_TYPES[1] === item.type);
  const diapasons = data.filter(item => ITEM_TYPES[0] === item.type);

  return {
    groups,
    diapasons
  };
};

export const GET_ITEMS_STATISTICS = async userId => {
  let requestParameters = {
    params: {}
  };
  if (userId) requestParameters.params.userId = userId;
  const { data } = await http.get('Items/Statistics', requestParameters);
  return data;
};
export const UPDATE_ITEMS_STATISTICS = async items => {
  await http.put('Items/Statistics', {
    items
  });
};

export const GET_ITEMS_HANDS = async itemIDs => {
  let request = itemIDs.join(',');
  const { data } = await http.get(`Items/${request}/Hands`);
  return data;
};

export const GET_ITEMS_TREE = async itemIDs => {
  console.log('worked item tree', itemIDs);

  let request = itemIDs.join(',');
  const { data } = await http.get('Items/' + request + '/Tree');
  return data;
};

export const GET_ITEMS_INFO = async itemIDs => {
  console.log(itemIDs, 'ITEMIDS');
  const { data } = await http.get('Items/' + itemIDs + '/Info');
  return data;
};

export const GET_FULL_ITEM_INFO = async itemID => {
  const { data } = await http.get('Items/' + itemID);
  return data;
};

export const GET_ALL_ITEMS = async () => {
  const { groups, diapasons: diapasonsWithoutGroup } = await GET_ITEM();

  return {
    groups,
    diapasonsWithoutGroup
  };
};

export const GET_ALL_ITEMS_OLD = async () => {
  const { groups, diapasons: diapasonsWithoutGroup } = await GET_ITEM();

  const groupsItems = await Promise.all(
    groups.map(group => GET_ITEM(group.id))
  );
  const groupsDiapasons = groupsItems
    .map(item => item.diapasons)
    .reduce((acc, val) => [...acc, ...val], []);

  return {
    groups,
    diapasonsWithoutGroup,
    groupsDiapasons
  };
};

export const UPDATE_ITEM_RANGE = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .put('Items/Diapason', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const UPDATE_ITEM_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .put('Items/Group', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const UPDATE_PREMIUM_ITEM_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .put('Items/Group/Links', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const CLEAR_GROUP_STATISTICS = async itemId => {
  const { data } = await http.put(
    'Items/Group/Statistics/Clear?itemId=' + itemId
  );
  return data;
};
export const CLEAR_STATISTICS = async itemId => {
  const { data } = await http.put(
    'Items/Diapason/Statistics/Clear?itemId=' + itemId
  );
  return data;
};

export const CREATE_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .post('Items/Group', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const CREATE_RANGE = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .post('Items/Diapason', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const DELETE_ITEMS = async itemsString =>
  await http.delete(`Items/${itemsString}`);
export const MOVE_ITEM = async DTO => await http.post('/Items/Move', DTO);

export const ITEMS_COPY = async (items, parentId, recursively) => {
  const { data } = await http.post('Items/Copy', {
    items: items,
    parentId: parentId,
    recursively: recursively
  });

  return data;
};
export const SEARCH_ITEMS = async params => {
  const { data } = await http.get('/Items/Search', {
    params: params
  });
  return data;
};
