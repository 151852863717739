<template>
  <div
    :class="[
      'list__item',
      {
        'list__item--no-checkbox': !multipleSelect,
        'list__item--selected': itemIsSelected,
        'list__item--opened': item.isOpened,
        'list__item--dropping': item.showBottomHr,
        'list__item--dragovered': item.showHr,
        'list__item--has-checked-children': item.hasChildSelected,
      }
    ]"
  >
    <slot v-bind="item.value" name="card-outer"/>
    <div
      class="list__item__row"
      @drag="onDrag($event)"
      @dragend="onDragEnd(item)"
      @dragleave="onCardLeave(item)"
      @dragover="onCardDragOver($event, item)"
      @dragstart="onDragStart(item)"
      @drop="onDrop(item)"
    >
      <Checkbox
        v-if="multipleSelect"
        class="list__item__checkbox"
        bordered
        v-model="isSelected"
        @input="onItemCheck(item)"
      />

      <slot v-bind="item.value" name="card-body">
        <div
          :class="[
            'list__item__card',
            {
              'list__item__card--group': itemIsGroup,
              'list__item__card--dragging': dragFromItem ? dragFromItem.value.id === item.value.id : false,
            }
          ]"
          :style="{
            'padding-left': (depth * PADDING_PX_PER_LEVEL) + 'px'
          }"
          @click="onItemClick(item)"
        >
          <slot v-bind="item.value" name="card-body-content"/>
          <div class="list__item__card__content">

            <template v-if="itemIsGroup">
              <svg
                class="list__item__card__group-icon"
                data-src="/icons/chevron-right.svg"
              />

            </template>

            <slot v-bind="item.value" name="card-inner"/>

            <div
              v-if="draggable"
              :class="[
                'list__item__card__draggable',
                {
                  'list__item__card__draggable--non': !item.dragAndDropOut
                }
              ]"
              @mouseleave="disableDragOnIconMouseLeave(item)"
              @mouseover="enableDragOnIconHover(item)"
            >
              <img src="/icons/draggable.svg"/>
            </div>
          </div>
          <progress-linear
            class="list__item__card__loading"
            v-if="item.value.type === 'Group'"
            v-show="item.loading"
          />
        </div>

      </slot>

    </div>

    <div
      v-if="item.isOpened && item.childrenProvider !== null"
      class="list__item__card__children"
    >
      <div
        v-if="item.isOpened && item.children.length === 0"
        :class="[
          'list__item__card__children__empty',
          {
            'list__item__card__children__empty--dragovered': item.showHr
          }
        ]"
        :data-depth="depth + 1"
        :style="{
          'padding-left': ((depth + 2) * PADDING_PX_PER_LEVEL) + 'px'
        }"
        @drag="onDrag($event)"
        @dragend="onDragEnd(item)"
        @dragleave="onCardLeave(item)"
        @dragover="onCardDragOver($event, item)"
        @dragstart="onDragStart(item)"
        @drop="onDrop(item)"
      >
        {{ localization.general.Common.Empty }}
      </div>
      <template v-else>
        <ListItem
          v-for="child in computedItems"
          :key="child.value.id"
          :allowDrop="allowDrop"
          :currentItem="currentItem"
          :data-depth="depth + 1"
          :depth="depth + 1"
          :disableDragOnIconMouseLeave="disableDragOnIconMouseLeave"
          :dragFromItem="dragFromItem"
          :draggable="draggable"

          :enableDragOnIconHover="enableDragOnIconHover"
          :item="child"
          :multipleSelect="multipleSelect"

          :onCardDragOver="onCardDragOver"
          :onCardLeave="onCardLeave"
          :onDrag="onDrag"
          :onDragEnd="onDragEnd"
          :onDragStart="onDragStart"
          :onDrop="onDrop"
          :onDropToBottomOfParent="onDropToBottomOfParent"
          :onItemCheck="onItemCheck"
          :onItemClick="onItemClick"


          :onParentBottomDragLeave="onParentBottomDragLeave"

          :onParentBottomDragOver="onParentBottomDragOver"

          :selectedItems="selectedItems"
          :sortFunction="sortFunction"
        >
          <template v-slot:card-outer="slotItem">
            <slot v-bind="slotItem" name="card-outer"/>
          </template>
          <template v-slot:card-inner="slotItem">
            <slot v-bind="slotItem" name="card-inner"/>
          </template>
          <template v-slot:card-body-content="slotItem">
            <slot v-bind="slotItem" name="card-body-content"/>
          </template>
        </ListItem>
      </template>

      <div
        class="list__item__card__children__bottom"
        @dragleave="onParentBottomDragLeave(item)"
        @dragover="onParentBottomDragOver($event, item)"
        @drop="onDropToBottomOfParent(item)"
      >
        <hr
          v-show="item.showBottomHr"
          class="list__item__card__hr"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/UI/Checkbox";
import ProgressLinear from "@/components/UI/ProgressLinear/ProgressLinear";

export default {
  name: "ListItem",
  components: {ProgressLinear, Checkbox},
  data() {
    return {
      selected: false,
      current: false,
      PADDING_PX_PER_LEVEL: 16
    }
  },
  props: {
    depth: {
      type: Number,
      default: 0
    },
    draggable: {
      type: Boolean,
      default: true
    },
    multipleSelect: {
      type: Boolean,
      default: true
    },
    onParentBottomDragOver: Function,
    onParentBottomDragLeave: Function,
    onDropToBottomOfParent: Function,
    sortFunction: Function,

    onItemClick: Function,
    onItemCheck: Function,

    onDragStart: Function,
    onDragEnd: Function,
    onDrop: Function,

    onCardDragOver: Function,
    onCardLeave: Function,

    onDrag: Function,
    allowDrop: Function,

    enableDragOnIconHover: Function,
    disableDragOnIconMouseLeave: Function,

    selectedItems: Array,
    currentItem: Object,
    dragFromItem: Object,
    item: Object,
  },
  computed: {
    computedItems() {
      return this.sortFunction(this.item.children);
    },
    itemIsGroup() {
      return this.item.value.type === 'Group'
    },
    itemIsSelected() {
      return this.currentItem?.value?.id === this.item?.value?.id;
    },
    isSelected: {
      get() {
        return this.selectedItems.some(selectedItem => selectedItem.value.id === this.item.value.id);
      },
      set(val) {
        return val
      }
    }
  }
}
</script>