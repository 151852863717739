<template>
  <div class="view-changer show-sm">
    <div class="view-changer__row">
      <div
        :class="[
          'view-changer__item',
          {
            'view-changer__item--active': currentViewIsList
          }
        ]"
        @click="setHash('list')"
      >
        <svg class="view-changer__item__icon" data-src="/icons/list.svg" />

        {{ localization.general.RangeViewerBtns.List }}
      </div>
      <div
        :class="[
          'view-changer__item',
          {
            'view-changer__item--active': currentViewIsEditor
          }
        ]"
        @click="setHash('editor')"
      >
        <svg class="view-changer__item__icon" data-src="/icons/table.svg" />
        {{ localization.general.RangeViewerBtns.Table }}
      </div>
    </div>
  </div>
</template>

<script>
import { RANGES_VIEW_EDITOR, RANGES_VIEW_LIST } from './';

export default {
  name: 'RangeViewChanger',
  data() {
    return {
      currentView: null,
      RANGES_VIEW_LIST,
      RANGES_VIEW_EDITOR
    };
  },
  computed: {
    currentViewIsList() {
      return this.currentView === RANGES_VIEW_LIST || this.currentView == null;
    },
    currentViewIsEditor() {
      return this.currentView === RANGES_VIEW_EDITOR;
    }
  },
  methods: {
    setHash(hash) {
      window.location.hash = hash;
    },
    setCurrentView(view) {
      this.currentView = view;
      this.$emit('changeView', view);
    },

    detectCurrentHash() {
      const component =
        window.location.hash === '#editor'
          ? RANGES_VIEW_EDITOR
          : RANGES_VIEW_LIST;
      const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
      if (component)
        if (width < 840) {
          component === RANGES_VIEW_LIST
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'auto');
        } else {
          document.body.style.overflow = 'auto';
        }
      this.setCurrentView(component);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectCurrentHash);
    document.body.style.overflow = 'auto';
  },
  mounted() {
    this.detectCurrentHash();
    this.$nextTick(() => {
      window.addEventListener('resize', this.detectCurrentHash);
    });
    window.onpopstate = () => {
      this.detectCurrentHash();
    };
  }
};
</script>

<style lang="scss" src="./RangeViewChanger.scss" />
