<template>
  <div class="game__actions">
    <div class="game__actions__wrapper">
      <slot name="prepend"/>
      <PercentageSelectorsForAdvancedMode
        v-if="getGameMode === 'Advanced'"
        :btns="percentageBtns"
        :current="current"
        @changeBtn="onPercentageChange"
      />

      <WeightForRandomizedMode
        v-if="getGameMode === 'Randomized'"
        :value="randomNumber"
        :weights="percentageBtns"
      />

      <div class="game__actions__btns">
        <Btn
          v-for="btn in btnsForAnswer"
          :key="btn.label"
          class="game__actions__btn"
          @click="btn.handler"
        >
          <div class="game__actions__btn__label">
            {{ btn.label }}
          </div>
          <div v-show="btn.value" class="game__actions__btn__value">
            {{ btn.value }}
          </div>
        </Btn>
      </div>
    </div>

  </div>
</template>
<script>

import {GET_STATE_RADIO_BUTTONS} from "@/utils/answerButtonsInGame";
import {mapGetters} from 'vuex';
import Btn from "@/components/UI/Btn";

import WeightForRandomizedMode from "@/components/Game/WeightForRandomizedMode";
import PercentageSelectorsForAdvancedMode from "@/components/Game/PercentageSelectorsForAdvancedMode";

export default {
  name: 'ButtonsForAnswer',
  props: ['allWeight', 'weight', 'isHaveAllin', 'isHaveCheck', 'situation', 'randomNumber'],
  components: {
    PercentageSelectorsForAdvancedMode,
    WeightForRandomizedMode,
    Btn
  },
  data() {
    return {
      raise: null,
      call: null,
      check: null,
      fold: null,
      allin: null,
      current: null,
      checked: []
    };
  },
  methods: {
    setValues() {
      const {raise, call, fold, allin, check} = this;
      const obj = {raise, call, fold, allin, check};

      let checkValue = Object.entries(obj).filter(([, value]) => value);
      if (checkValue.length === 3) {
        let last = Object.entries(obj).find(([, value]) => !value);
        this[last[0]] = 100 - this.sum;
      }
    },
    onPercentageChange(val) {
      this.current = val;
    },
    next() {
      let answers = {
        raise: this.raise,
        check: this.check,
        call: this.call,
        fold: this.fold,
        allin: this.allin,
      };
      window.console.log(answers);
      // send answer
      this.$emit('finishAnswer', answers);
      this.setDefault();
    },
    setDefault() {
      this.raise = null;
      this.check = null;
      this.call = null;
      this.fold = null;
      this.allin = null;
      this.current = null;
    },
    onSet(type) {
      if (type === "allin" && !this.isHaveAllin) return;
      if (type === "fold" && this.isHaveCheck) return;

      if (!this.current) {
        if (this.sum - this[type] === 0) {
          this[type] = 100;
        } else {
          this[type] = 100 - this.sum;
        }
      } else {
        this[type] = this.current;
        this.current = null;
      }
    },
    setHotKeyEvents(event) {
      const hotkeys = this.globalPreferences.hotKeys;

      if (event.repeat) return

      let modifierKey = null;
      if (event.ctrlKey)
        modifierKey = "ctrl";
      else if (event.altKey)
        modifierKey = "alt";
      else if (event.shiftKey)
        modifierKey = "shift";

      Object.keys(hotkeys).forEach(key => {
        if (hotkeys[key]) {
          if (hotkeys[key].keyCode === event.keyCode) {
            if (modifierKey) {
              if (hotkeys[key][modifierKey]) {
                event.preventDefault();
                this.onSet(key);
              }
            } else {
              let checkIfAllEmpty = true;
              Object.keys(hotkeys[key]).forEach(innerKey => {
                if (innerKey !== "keyCode") {

                  if (hotkeys[key][innerKey]) {
                    checkIfAllEmpty = false;
                  }
                }

              });

              if (checkIfAllEmpty) {
                event.preventDefault();
                this.onSet(key);
              }
            }
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      getGameMode: 'play/getGameMode',
      globalPreferences: 'user/globalPreferences'
    }),
    foldText() {
      return `FOLD ${this.fold ?? ""}`;
    },
    callText() {
      return `CALL ${this.call ?? ""}`;
    },
    raiseText() {
      return `RAISE ${this.raise ?? ""}`;
    },
    allinText() {
      return `ALLIN ${this.allin ?? ""}`;
    },
    checkText() {
      return `CHECK ${this.check ?? ""}`;
    },
    btnsForAnswer() {
      let conditionalBtns = this.isHaveCheck ?
        [
          {
            value: this.check,
            label: "Check",
            handler: () => this.onSet('check'),
          }
        ] :
        [
          {
            value: this.fold,
            label: "Fold",
            handler: () => this.onSet('fold'),
          },
          {
            value: this.call,
            label: "Call",
            handler: () => this.onSet('call'),
          }
        ];

      let defaultBtns = [
        {
          value: this.raise,
          label: "Raise",
          handler: () => this.onSet('raise'),
        },
        {
          value: this.allin,
          label: "Allin",
          handler: () => this.onSet('allin'),
        }
      ];

      return defaultBtns.concat(conditionalBtns);

    },
    percentageBtns() {
      if (!this.allWeight || !this.weight) return [];
      // this.allWeight - all cells with weight
      // this.weight - current cell obj w weightse444444444444444444444444444444444444444434444444444444444444444444345
      return GET_STATE_RADIO_BUTTONS(this.allWeight, this.weight, this.situation);
    },

    sum() {
      return this.raise + this.call + this.fold + this.allin + this.check;
    },

  },
  async created() {
    document.addEventListener('keydown', this.setHotKeyEvents);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.setHotKeyEvents);
  },

  watch: {
    sum() {
      // if 100 gathered then finish
      if (this.sum >= 100) this.next();
    },
    raise() {
      this.setValues();
    },
    call() {
      this.setValues();
    },
    check() {
      this.setValues();
    },
    fold() {
      this.setValues();
    },
    allin() {
      this.setValues();
    },
    percentageBtns() {
      this.setDefault();
    },
  },
};
</script>
