var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'langs',
  {
    'langs--dark': _vm.dark,
    'langs--uppercase': _vm.uppercase,
  }
]},_vm._l((_vm.langs),function(lang){return _c('div',{key:lang.value,class:[
        'langs__item',
        {
          'langs__item--active': _vm.activeLang === lang.value
        }
      ],on:{"click":function($event){return _vm.changeLang(lang.value)}}},[_vm._v(" "+_vm._s(lang.label)+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }