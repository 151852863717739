<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="footer__socials">
          <a
            class="footer__socials__item"
            href="https://join.skype.com/invite/au60nkWM87sA"
          >
            <img src="/icons/socials/skype.svg" />
            PokerIQ
          </a>
          <a class="footer__socials__item" href="https://t.me/DinaPIQ">
            <img src="/icons/socials/telegram.svg" />
            PokerIQ
          </a>
        </div>
        <div class="footer__copyright">© PokerIQ, 2021</div>
        <div class="footer__ukraine">
          Made in Ukraine <img src="/icons/socials/map.svg" alt="UKRAINE" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    setCurrentPage(index) {
      this.$store.dispatch('user/SET_CURRENT_MAIN_PAGE', index);
    }
  }
};
</script>
