<template>
  <div class="dashboard">
    <Header/>

    <router-view/>

    <Footer
      :class="{
        'hide-sm': $route.path === '/main' ||  $route.path === '/admin/premium-ranges'
      }"
    />
  </div>
</template>


<script>

import RangeViewsMixin from "../mixins/RangeViewsMixin";
import Footer from "@/components/Layout/Footer";
import Header from "@/components/Layout/Header";

export default {
  name: 'Dashboard',
  components: {Header, Footer},
  mixins: [RangeViewsMixin],

  data() {
    return {

      currentItem: null,
      selectedItems: [],
      itemsStatistics: {
        regular: {
          diapasons: 0,
          groups: 0
        },
        premium: {
          diapasons: 0,
          groups: 0
        }
      },

    };
  },
  created() {
    if(!this.$auth.isLogged()){
      this.$router.push({
        name: 'Sign In'
      })
    }
  }
};
</script>

<style lang="scss" src="../assets/scss/dashboard.scss"/>
