var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'dropdown-menu',
    {
      'dropdown-menu--active': _vm.showDropdownBody,
      'dropdown-menu--bg-blue': !_vm.borderColor && _vm.backgroundColor === 'blue',
      'dropdown-menu--bg-dark': !_vm.borderColor && _vm.backgroundColor === 'dark',
      'dropdown-menu--outlined-gray': !_vm.backgroundColor && _vm.borderColor === 'gray'
    }
  ]},[_c('div',{staticClass:"dropdown-menu__header",style:(`--height: ${_vm.height}px`),on:{"click":function($event){return _vm.toggleDropdownBody()}}},[(_vm.headerIcon)?_c('svg',{staticClass:"dropdown-menu__header__icon",attrs:{"data-src":_vm.headerIcon}}):_vm._e(),_c('div',{staticClass:"dropdown-menu__header__label"},[_vm._v(" "+_vm._s(_vm.headerLabel)+" ")]),_c('div',{staticClass:"dropdown-menu__header__arrow"},[_c('svg',{attrs:{"data-src":"/icons/arrow-down.svg"}})])]),_c('div',{staticClass:"dropdown-menu__body"},_vm._l((_vm.dataItems),function(item,index){return _c('div',{key:index,class:[
        'dropdown-menu__item',
        {
          'dropdown-menu__item--disabled': item.disabled,
          'dropdown-menu__item--loading': item.loading,
          'dropdown-menu__item--async': item.async,
        }
      ],on:{"click":function($event){return _vm.runHandler(item)}}},[(item.async)?_c('ProgressCircular',{attrs:{"color":_vm.computeProgressCircularColor}}):_vm._e(),_c('div',{staticClass:"dropdown-menu__item-wrapper"},[(item.icon)?_c('svg',{staticClass:"dropdown-menu__item__icon",attrs:{"data-src":item.icon}}):_vm._e(),_c('div',{staticClass:"dropdown-menu__item__label"},[_vm._v(" "+_vm._s(item.label)+" ")])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }