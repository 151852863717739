<template>
  <div :class="[
    'langs',
    {
      'langs--dark': dark,
      'langs--uppercase': uppercase,
    }
  ]">
    <div
      v-for="lang in langs"
      :key="lang.value"
      :class="
        [
          'langs__item',
          {
            'langs__item--active': activeLang === lang.value
          }
        ]
      "
      @click="changeLang(lang.value)"
    >
      {{ lang.label }}
    </div>
  </div>
</template>

<script>
import {LANG_ENG, LANG_RU} from "@/constants/languages";

export default {
  name: "LangSwitcher",
  props: {
    uppercase: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      langs: [
        {
          label: 'en',
          value: LANG_ENG
        },
        {
          label: 'ru',
          value: LANG_RU
        }
      ]
    }
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem('activeLang', lang);
      this.$store.commit('ui/SET_LOCALIZATION', lang);
      this.$emit("changeLang", lang);
    },
  }
}
</script>

<style lang="scss" src="./LangSwitcher.scss"/>