<style>
.courses .premium-item-localization__tabs__item {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */
    color: #12b7db;
    text-transform: uppercase;
}
.courses .tabs__nav__item--active {
    color: #404c61;
}
.courses .tabs__item {
    padding: 0;
}
</style>
<style lang="scss" scoped src="../assets/scss/courses.scss"></style>

<template>
    <div class="courses">
        <div class="header">
            <Header />
        </div>

        <section class="main">
            <section class="main--courses">
                <h1 class="title">
                    {{ localization.courses.mainTitle }}
                </h1>
                <p class="title--sub">
                    {{ localization.courses.mainDescription }}
                </p>
                <p class="title--sub--description">
                    {{ localization.courses.mainSubDescription }}
                </p>
                <div class="items" v-if="loadingOur">
                    <div v-for="item in nullCardsOur" :key="item">
                        <VueSkeletonLoader class="notData"></VueSkeletonLoader>
                    </div>
                </div>
                <div class="items" :style="{ marginBottom: 10 + 'px' }" v-else>
                    <div v-for="item in items" :key="item.title">
                        <Card :item="item" />
                    </div>
                    <div v-for="item in nullCardsOur" :key="item">
                        <div class="no__data"></div>
                    </div>
                </div>
            </section>
            <section class="partners--courses">
                <h1 class="title">
                    {{ localization.courses.partnersTitle }}
                </h1>
                <TabsNav v-model="activeTab" style="margin-top:25px">
                    <TabsNavBtn
                        v-for="cf in localization.courses.tabs"
                        :key="cf.value"
                        :class="[
                            'premium-item-localization__tabs__item',
                            cf == activeTab && 'active',
                        ]"
                    >
                        {{ cf.label }}
                    </TabsNavBtn>
                </TabsNav>
                <Tabs v-model="activeTab">
                    <Tab
                        v-for="filter in localization.courses.tabs"
                        :key="filter.value"
                        style="transition: 0.2s; min-height:300px;"
                    >
                        <div
                            class="items"
                            v-if="loadingPartners"
                            :style="{ marginBottom: 10 + 'px' }"
                        >
                            <div v-for="item in nullCardsPartners" :key="item">
                                <VueSkeletonLoader
                                    class="no__data"
                                ></VueSkeletonLoader>
                            </div>
                        </div>
                        <div
                            class="items"
                            :style="{ marginBottom: 10 + 'px' }"
                            v-else
                        >
                            <div
                                v-for="item in partnerCourses"
                                :key="item.title"
                            >
                                <Card :item="item" :actionDisabled="true" />
                            </div>
                            <div v-for="item in nullCardsPartners" :key="item">
                                <div class="no__data"></div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </section>
        </section>
        <div class="footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'
import Card from '@/components/UI/CoursesCard'
import Tabs from '@/components/UI/Tabs/Tabs'
import Tab from '@/components/UI/Tabs/Tab'
import TabsNav from '@/components/UI/Tabs/TabsNav'
import TabsNavBtn from '@/components/UI/Tabs/TabsNavBtn'
import { GET_PARTNERS_COURSES, GET_OUR_COURSES } from '@/api/courses'
import VueSkeletonLoader from 'skeleton-loader-vue'
export default {
    name: 'Promo',
    components: {
        Header,
        Footer,
        Card,
        Tabs,
        Tab,
        TabsNav,
        TabsNavBtn,
        VueSkeletonLoader,
    },
    data() {
        return {
            windowWidth: 0,

            activeTab: 1,
            loadingOur: true,
            loadingPartners: true,
            items: [],
            partnerCourses: [],
        }
    },
    watch: {
        activeTab(val) {
            const type = this.localization.courses.tabs[val].value
            this.loadingPartners = true

            GET_PARTNERS_COURSES(type)
                .then(res => {
                    this.partnerCourses = res.data
                })
                .finally(() => {
                    this.loadingPartners = false
                })
        },
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
    },
    computed: {
        nullCardsOur() {
            let cardInLine = 4
            switch (true) {
                case this.windowWidth <= 420:
                    cardInLine = 0
                    break
                case this.windowWidth <= 768:
                    cardInLine = 0
                    break
                case this.windowWidth <= 1220:
                    cardInLine = 3
                    break
            }
            if (this.loadingOur)
                return new Array(cardInLine).fill(0).map((_, i) => i)
            if (cardInLine == 0 || this.items.length % cardInLine == 0)
                return []

            return new Array(cardInLine - (this.items.length % cardInLine))
                .fill(0)
                .map((_, i) => i)
        },
        nullCardsPartners() {
            let cardInLine = 4
            switch (true) {
                case this.windowWidth <= 420:
                    cardInLine = 0
                    break
                case this.windowWidth <= 768:
                    cardInLine = 0
                    break
                case this.windowWidth <= 1220:
                    cardInLine = 3
                    break
            }
            if (this.loadingPartners)
                return new Array(cardInLine).fill(0).map((_, i) => i)
            if (cardInLine == 0 || this.partnerCourses.length % cardInLine == 0)
                return []

            return new Array(
                cardInLine - (this.partnerCourses.length % cardInLine)
            )
                .fill(0)
                .map((_, i) => i)
        },
    },

    async mounted() {
        this.windowWidth = window.innerWidth
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
        GET_OUR_COURSES()
            .then(res => {
                this.items = res.data
            })
            .finally(() => {
                this.loadingOur = false
            })

        GET_PARTNERS_COURSES(this.localization.courses.tabs[0].value)
            .then(res => {
                this.partnerCourses = res.data
            })
            .finally(() => {
                this.loadingPartners = false
            })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>
