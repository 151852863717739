<template>
  <label class="radio-container">
    {{ label }}
    <input
      :checked="isChecked"
      :value="value"
      type="radio"
      @input="updateInput"
    />
    <span class="radio-container__input"/>
  </label>

</template>

<script>
export default {
  name: "Radio",
  data() {
    return {
      isChecked: false
    }
  },
  props: {
    value: null,
    label: String,
  },
  methods: {
    updateInput() {
      this.$parent.emitChanges(this.value);
    }
  },
}
</script>

