<template>
  <div>
    <Expander persistent>
      <ExpanderItem
        class="range-editor__options"
        @getIsActiveState="expanderIsActive"
      >
        <template v-slot:header>
          {{ item.name }}
        </template>
        <template v-slot:header-buttons>
          <Btn
            class="range-editor__start-test-btn"
            :disabled="btnDisabled"
            :loading="btnLoading"
            height="38px"
            :color="btnColor"
            onmouseover="event.stopPropagation();"
            @click.prevent.stop="handleBtnAction()"
          >
            {{ btnLabel }}
          </Btn>
        </template>
        <template v-slot:content>
          <div
            :class="[
              'item-options-editor',
              {
                'item-options-editor--readonly': isReadOnly
              }
            ]"
          >
            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.name }}
              </div>
              <div class="item-options-editor__value">
                <input
                  v-model="dataItem.name"
                  class="form-input item-options-editor__input item-options-editor__input--w-100"
                />
              </div>
            </div>
            <div class="item-options-editor__row">
              <Toggler
                v-model="dataItem.additionalProperties.type"
                :items="testTypes"
                class="
              item-options-editor__value
              item-options-editor__value--btns
              item-options-editor__value--w-100
              item-options-editor__value--type-btns
            "
                stretched
                @input="showCheck()"
              />
            </div>

            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.sizeBlock }}
              </div>
              <div
                class="item-options-editor__value item-options-editor__value--btns"
              >
                <Toggler
                  v-model="dataItem.additionalProperties.size"
                  :items="tableSizesBasedOnItemType"
                  in-one-row
                  @input="updateParts"
                />
              </div>
            </div>
            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.hp }}
              </div>
              <div
                class="item-options-editor__value item-options-editor__value--btns-wrap"
              >
                <Toggler
                  v-model="dataItem.additionalProperties.hp"
                  :items="possibleHeroPositions"
                  @update="showCheck()"
                />
              </div>
            </div>
            <div
              v-if="['VS3B', 'SQZ', 'VS4BET'].includes(itemType)"
              class="item-options-editor__row"
            >
              <div class="item-options-editor__label">
                {{ heroBetSizeLabelBasedOnItemType }}
              </div>
              <div class="item-options-editor__value">
                <input
                  v-model.number="dataItem.additionalProperties.r"
                  class="item-options-editor__input form-input"
                  type="number"
                />
              </div>
            </div>
            <template v-if="showOpHpSpInput">
              <div class="item-options-editor__conditional-block">
                <div
                  :class="[
                    'item-options-editor__cp-op',
                    'item-options-editor__conditional-block',
                    {
                      'item-options-editor__conditional-block--disabled': !dataItem
                        .additionalProperties.hp
                    }
                  ]"
                >
                  <div
                    v-show="selectedTypesRequireCallerPosition"
                    :class="[
                      'item-options-editor__row',
                      'item-options-editor__conditional-block',
                      {
                        'item-options-editor__row--order-last':
                          itemType === 'VS1RC',
                        'item-options-editor__conditional-block--disabled':
                          itemType === 'VS1RC' &&
                          !dataItem.additionalProperties.op
                      }
                    ]"
                  >
                    <div class="item-options-editor__label">
                      {{ localization.general.Diapason.cp }}
                    </div>
                    <div
                      class="item-options-editor__value item-options-editor__value--btns-wrap"
                    >
                      <Toggler
                        v-model="dataItem.additionalProperties.cp"
                        :items="possibleCallerPositions"
                      />
                    </div>
                  </div>
                  <div
                    :class="[
                      'item-options-editor__row',
                      'item-options-editor__conditional-block',
                      {
                        'item-options-editor__row--order-last':
                          itemType === 'SQZ',
                        'item-options-editor__conditional-block--disabled':
                          itemType === 'SQZ' &&
                          !dataItem.additionalProperties.cp &&
                          selectedTypesRequireCallerPosition
                      }
                    ]"
                  >
                    <div class="item-options-editor__label">
                      {{ localization.general.Diapason.op }}
                    </div>
                    <div
                      class="item-options-editor__value item-options-editor__value--btns"
                    >
                      <Toggler
                        v-model="dataItem.additionalProperties.op"
                        :items="possibleOpponentPositions"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="showOpponentBetSize"
                  class="item-options-editor__row"
                >
                  <div class="item-options-editor__label">
                    {{ opBetSizeLabelBasedOnItemType }}
                  </div>
                  <div class="item-options-editor__value">
                    <input
                      v-model.number="dataItem.additionalProperties.n"
                      class="item-options-editor__input form-input"
                      type="number"
                      @input="showCheck()"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </ExpanderItem>

      <!--TABS-->
      <PositionTabs
        :parts="parts"
        :activeTableParticipant="activeTableParticipant"
        @setActiveTableParticipant="setActiveTableParticipant"
        :dataItem="dataItem"
        :tableParticipants="tableParticipants"
        :parentLinkedIsActive="tabsIsOpen"
        @updated="console.log('updated')"
        @onChangePositionTab="onChagePositionUpdated"
        v-model="parts"
        @updateParts="reactiveUpdateParts"
        @setHeroPosition="setHeroPosition"
      />
    </Expander>
  </div>
</template>

<script>
/* eslint-disable */

import getTableParticipants from '@/utils/participants';
import { getCustomNameOfPosition, getTablePositions } from '@/utils/positions';
import { mapGetters } from 'vuex';
import Toggler from '@/components/UI/Toggler';
import ColorPicker from '@/components/UI/ColorPicker';
import ItemOptionsEditorMixin from '@/components/ItemOptionsEditor/ItemOptionsEditorMixin';
import rangeHasCheck from '@/utils/itemRange';
import Scroller from '@/components/UI/Scroller/Scroller';
import Btn from '@/components/UI/Btn';
import PositionTabs from '@/components/PositionTabs/PositionTabs';
import Expander from '../../UI/Expander/Expander.vue';
import _ from 'lodash';

export default {
  name: 'ItemRangeOptionsEditor',
  props: {
    item: Object,
    btnDisabled: Boolean,
    btnLoading: Boolean,
    btnColor: String,
    btnLabel: String
  },
  data() {
    return {
      activeTableParticipant: null,
      localHeroPosition: '',
      parentLinkedState: false,
      tabsIsOpen: false,
      tableSizes: [2, 3, 6, 8],
      testTypes: [
        {
          label: 'Unopened',
          value: 'RFI'
        },
        {
          label: 'VS RFI/Limp',
          value: 'VSOR'
        },
        {
          label: 'VS 3bet',
          value: 'VS3B'
        },
        {
          label: 'VS 4bet raise',
          value: 'VS4BET'
        },
        {
          label: 'VS 1raise+caller',
          value: 'VS1RC'
        },
        {
          label: 'VS SQZ',
          value: 'SQZ'
        }
      ],
      parts: {},
      hasCheck: false,

      dataItem: {
        name: null,
        additionalProperties: {
          type: null,
          op: null,
          cp: null,
          r: null,
          n: null,
          size: null,
          hp: null
        },
        participants: []
      }
    };
  },
  components: {
    Scroller,
    ColorPicker,
    Toggler,
    Btn,
    PositionTabs,
    Expander
  },
  mixins: [ItemOptionsEditorMixin],
  computed: {
    ...mapGetters({
      globalPreferences: 'user/globalPreferences'
    }),

    showOpHpSpInput() {
      return this.itemType && this.itemType !== 'RFI';
    },

    showOpponentBetSize() {
      return (
        this.itemType === 'VSOR' ||
        (this.itemType === 'VS1RC' && this.dataItem.additionalProperties.cp) ||
        this.itemType === 'VS3B' ||
        (this.itemType === 'SQZ' && this.dataItem.additionalProperties.cp) ||
        this.itemType === 'VS4BET'
      );
    },

    selectedTypesRequireCallerPosition() {
      return ['SQZ', 'VS1RC'].includes(this.itemType);
    },

    heroBetSizeLabelBasedOnItemType() {
      if (this.itemType === 'VS4BET') {
        return this.localization.general.Diapason.vs4BetHeroBetSize;
      }

      return this.localization.general.Diapason.vs3BetHeroBetSize;
    },
    opBetSizeLabelBasedOnItemType() {
      if (this.itemType === 'VS4BET') {
        return this.localization.general.Diapason.vs4BetOpBetSize;
      }
      if (
        this.itemType === 'VS3B' ||
        (this.itemType === 'SQZ' && this.dataItem.additionalProperties.cp)
      ) {
        return this.localization.general.Diapason.vs3BetOpBetSize;
      }

      return this.localization.general.Diapason.openRaiseOpBetSize;
    },
    tableSizesBasedOnItemType() {
      if (this.itemType === 'VS1RC' || this.itemType === 'SQZ') {
        return this.tableSizes.filter(size => size !== 2 && size !== 3);
      }

      return this.tableSizes;
    },
    tableParticipants() {
      if (!this.itemSize) return [];
      return getTablePositions(this.itemSize);
    },
    possibleOpponentPositions() {
      if (!this.itemSize) return [];

      let tablePositions = getTablePositions(this.itemSize);
      const heroPositionIndex = tablePositions.findIndex(
        position => position === this.dataItem.additionalProperties.hp
      );

      if (heroPositionIndex === -1) return [];

      if (this.itemType === 'VS3B') {
        tablePositions = tablePositions.filter((_, i) => i > heroPositionIndex);
      } else if (this.itemType === 'SQZ') {
        let all = this.tableParticipants;
        let cpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.cp
        );
        tablePositions = all.slice(cpInAll + 1);
      } else if (this.itemType === 'VSOR' || this.itemType === 'VS4BET') {
        tablePositions = tablePositions.filter((_, i) => i < heroPositionIndex);
      } else if (this.itemType === 'VS1RC') {
        let all = this.tableParticipants;
        let hpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.hp
        );
        tablePositions = all.slice(0, hpInAll - 1);
      }

      return this.makeModifiedItemsOfPosition(tablePositions);
    },

    possibleHeroPositions() {
      if (!this.itemSize) return [];

      const filterPositions = positionForExclude => {
        return getTablePositions(this.itemSize).filter(position => {
          let positionForExcludeIsArray = Array.isArray(positionForExclude);

          if (positionForExcludeIsArray) {
            return !positionForExclude.includes(position);
          }

          return position !== positionForExclude;
        });
      };

      let excludePositions = null;

      if (
        (this.itemType === 'RFI' && this.itemSize === 8) ||
        (this.itemType === 'RFI' && this.itemSize === 6) ||
        (this.itemType === 'RFI' && this.itemSize === 3) ||
        (this.itemType === 'RFI' && this.itemSize === 2) ||
        (this.itemType === 'VS3B' && this.itemSize === 8) ||
        (this.itemType === 'VS3B' && this.itemSize === 6) ||
        (this.itemType === 'VS3B' && this.itemSize === 3) ||
        (this.itemType === 'VS3B' && this.itemSize === 2) ||
        (this.itemType === 'SQZ' && this.itemSize === 3) ||
        (this.itemType === 'SQZ' && this.itemSize === 2)
      ) {
        excludePositions = 'bb';
      } else if (
        (this.itemType === 'SQZ' && this.itemSize === 8) ||
        (this.itemType === 'SQZ' && this.itemSize === 6)
      ) {
        excludePositions = ['bb', 'sb'];
      } else if (this.itemType === 'VSOR' && this.itemSize === 8) {
        excludePositions = 'ep1';
      } else if (this.itemType === 'VSOR' && this.itemSize === 6) {
        excludePositions = 'ep';
      } else if (this.itemType === 'VSOR' && this.itemSize === 3) {
        excludePositions = 'bu';
      } else if (this.itemType === 'VSOR' && this.itemSize === 2) {
        excludePositions = 'sb';
      } else if (this.itemType === 'VS1RC' && this.itemSize === 8) {
        excludePositions = ['ep1', 'ep2'];
      } else if (this.itemType === 'VS1RC' && this.itemSize === 6) {
        excludePositions = ['mp', 'ep'];
      } else if (this.itemType === 'VS1RC' && this.itemSize === 3) {
        excludePositions = 'bu';
      } else if (this.itemType === 'VS1RC' && this.itemSize === 2) {
        excludePositions = 'sb';
      }

      let filteredPositions = [];
      if (this.itemType === 'VS4BET') {
        filteredPositions = getTablePositions(this.itemSize).slice(1);
      } else {
        filteredPositions = filterPositions(excludePositions);
      }
      console.log(
        this.makeModifiedItemsOfPosition(filteredPositions),
        ' filtered Positions'
      );
      return this.makeModifiedItemsOfPosition(filteredPositions);
    },
    possibleCallerPositions() {
      if (!this.selectedTypesRequireCallerPosition) return [];

      let tablePositions = [];
      let all = this.tableParticipants;

      if (this.itemType === 'SQZ') {
        let hpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.hp
        );
        tablePositions = all.slice(hpInAll + 1, -1);
      } else if (this.itemType === 'VS1RC') {
        let opInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.op
        );
        let hpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.hp
        );

        tablePositions = all.slice(opInAll + 1, hpInAll);
      }
      return this.makeModifiedItemsOfPosition(tablePositions);
    },

    itemType() {
      return this.dataItem.additionalProperties.type;
    },
    itemSize() {
      return this.dataItem.additionalProperties.size;
    }
  },

  methods: {
    onChagePositionUpdated() {
      console.log('UPDAVAILB onChangePositionUpdate');
      this.$emit('updated');
    },
    reactiveUpdateParts(payload) {
      if (!this.activeTableParticipant) {
        console.log(
          'ITEM RANGE EDITOR: no active participant',
          this.activeTableParticipant
        );
        return;
      }
      this.parts[this.activeTableParticipant] = payload;
    },
    expanderIsActive(state) {
      this.tabsIsOpen = state;
      console.log('workons', this.tabsIsOpen);
    },
    getCustomNameOfPosition,
    handleBtnAction() {
      this.$emit('btnAction');
    },
    setActiveTableParticipant(val) {
      this.activeTableParticipant = val;
      this.$emit('activePart', val);
    },
    //TODO:
    setHeroPosition(val) {
      this.localHeroPosition = val;
      this.$emit('heroPos', val);
      console.log('hero pos', val);
    },
    makeModifiedItemsOfPosition(items) {
      if (items.length === 0) return [];

      return items.map(position => {
        return {
          label: getCustomNameOfPosition(position),
          value: position
        };
      });
    },
    setHasCheckValue() {
      this.hasCheck = this.checkIfCurrentDataMustToggleCheck();
    },
    checkIfCurrentDataMustToggleCheck() {
      return rangeHasCheck(
        this.itemType,
        this.dataItem.additionalProperties.hp,
        this.dataItem.additionalProperties.n
      );
    },
    updateParts(val) {
      let initialParts = getTableParticipants(
        this.dataItem.additionalProperties.size
      );
      console.log(this.parts, 'Ithis parts');
      this.parts = {};
      for (let key in initialParts) {
        console.log([key], 'THIS PARTS AFTER UPDATE');

        this.$set(
          this.parts,
          key,
          _.cloneDeep(_.omit(this.dataItem.participants[key], ['hands']))
        );
      }
    },
    sdf() {
      this.parts = getTableParticipants(
        this.dataItem.additionalProperties.size
      );
      // this.parts = this.parts.deepClone(this.dataItem.diapason.participants);

      console.log(this.parts, 'this parts from UPDATE PARTS FUNCTION');
    },
    submit() {
      console.log('go to submit');
      const positionExists = (items, value) => {
        return !!items.find(item => item.value === value);
      };
      if (!this.dataItem.name) {
        return this.$toast.error(this.localization.general.Simple.EnterName);
      }

      if (
        !this.dataItem.additionalProperties.hp ||
        !positionExists(
          this.possibleHeroPositions,
          this.dataItem.additionalProperties.hp
        )
      ) {
        return this.$toast.error(this.localization.general.Simple.EnterHP);
      }

      if (['VSOR', 'VS1RC', 'VS3B', 'VS4BET', 'SQZ'].includes(this.itemType)) {
        if (
          !this.dataItem.additionalProperties.op ||
          !positionExists(
            this.possibleOpponentPositions,
            this.dataItem.additionalProperties.op
          )
        ) {
          return this.$toast.error(this.localization.general.Simple.EnterOP);
        }
        if (!this.dataItem.additionalProperties.n) {
          return this.$toast.error(this.localization.general.Simple.EnterOOS);
        }
      }

      if (['SQZ', 'VS1RC'].includes(this.itemType)) {
        if (
          !positionExists(
            this.possibleCallerPositions,
            this.dataItem.additionalProperties.cp
          )
        ) {
          return this.$toast.error(this.localization.general.Simple.EnterCP);
        }
      }

      if (['VS3B', 'VS4BET', 'SQZ'].includes(this.itemType)) {
        if (!this.dataItem.additionalProperties.r) {
          return this.$toast.error(this.localization.general.Simple.EnterOHRS);
        }
      }

      return {
        validated: true,
        data: this.dataItem
      };
    },
    showCheck() {
      /*
        EMIT WILL FIRE IF:
          1. CONDITION FOR CHECK IS TRUE && PREVIOUS SETTED PROPERTIES FOR CHECK ARE FALSE
          1. CONDITION FOR CHECK IS FALSE && PREVIOUS SETTED PROPERTIES FOR CHECK ARE TRUE
       */
      let setCheck = this.checkIfCurrentDataMustToggleCheck();
      if (this.hasCheck || setCheck) {
        this.$emit('checkWeightToggle', {
          setCheck: setCheck,
          type: this.itemType,
          hp: this.dataItem.additionalProperties.hp,
          op: this.dataItem.additionalProperties.op,
          cp: this.dataItem.additionalProperties.cp,
          r: this.dataItem.additionalProperties.r,
          n: this.dataItem.additionalProperties.n,
          name: this.dataItem.name
        });
      }

      this.setHasCheckValue();
    },
    //TODO:
    setFirstTableParticipantAsActive() {
      if (this.tableParticipants.length === 0) return;
      console.log(this.localHeroPosition, 'LOCAL HERO');
      this.activeTableParticipant = this.item.additionalProperties.hp;
      this.$emit('activePart', this.item.additionalProperties.hp);
      console.log(this.activeTableParticipant, 'active table first');
    }
  },

  watch: {
    //TODO:
    // 'dataItem.additionalProperties.size': {
    //   handler() {
    //     console.log(this.localHeroPosition, 'LOCAL HERO 2');
    //     this.setFirstTableParticipantAsActive();
    //   },
    //   immediate: true
    // },
    parts: {
      handler(val) {
        // if (!this.watchForPartsUpdates) {
        //   this.watchForPartsUpdates = true;
        //   return;
        // }
        this.setFirstTableParticipantAsActive();
        this.watchForPartsUpdates = true;
        console.log('hello', val); //Здеь идет обработка tempParts
        this.$emit('getTempParts', val);
        // this.updateIsAvailable();
      },
      deep: true,
      immediate: false
    },
    item: {
      handler() {
        this.updateParts();
        this.setHasCheckValue();
      },
      immediate: true
    },
    //watcher для дефолтного игрока при хвходе
    activeTableParticipant: {
      handler(val) {
        this.$emit('activePart', val);
      }
    }
  }
};
</script>
<style lang="scss" src="./ItemRangeOptionsEditor.scss" />
