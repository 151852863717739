<template>
  <div>
    <img
      v-if="!loaded"
      class="loading-gif"
      src="/img/loading.gif"
    />
    <div v-else class="user">
      <h2 class="admin-title">
        <router-link :to="{
          name: 'Admin Users'
        }">
          {{ localization.general.Profile.Users }}
        </router-link>
        / {{ user.email }}
      </h2>
      <GroupBox accent>
        <template v-slot:header>
          User Info
        </template>
        <template v-slot:content>
          <div
            class="user__go-back"
            @click="$router.back()"
          >
            <img src="/icons/chevron-right.svg"/>
            {{ localization.general.Profile.GoBack }}
          </div>

          <div class="user__referral-block">
            <p class="form-label-text">
              {{ localization.general.Profile.ReferralLink }}
            </p>
            <div class="user__referral-block__row">
              <input
                v-model="isCheck"
                type="checkbox"
              />

              <input
                v-model="percent"
                :disabled="!isCheck"
                class="form-input"

                type="number"
              />
              <Btn @click="onChangeReferral">
                {{ localization.general.Profile.ReferrerButton }}
              </Btn>
            </div>
          </div>

          <table class="c-simple-table">
            <tbody>
            <tr>
              <td>
                {{ localization.general.Profile.EmailField }}
              </td>
              <td>
                {{ user.email }}
              </td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.RegistrationTimeField }}
              </td>
              <td>
                {{ new Date(user.registrationDateTime).toLocaleString() }}
              </td>
            </tr>
            </tbody>
          </table>

          <div class="user__statistics">
            <table
              v-if="userStatistics"
              class="c-simple-table"
            >
              <thead>
              <tr>
                <th>
                  Item statistics
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <template>
                <tr>
                  <td class="u-bold">
                    Item statistics
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Regular Ranges</td>
                  <td>{{ userStatistics.regular.diapasons }}</td>
                </tr>
                <tr>
                  <td>Regular Groups</td>
                  <td>{{ userStatistics.regular.groups }}</td>
                </tr>
                <tr>
                  <td>Premium Ranges</td>
                  <td>{{ userStatistics.premium.diapasons }}</td>
                </tr>
                <tr>
                  <td>Premium Groups</td>
                  <td>{{ userStatistics.premium.groups }}</td>
                </tr>

              </template>
              </tbody>
            </table>

            <table
              v-if="userLimits"
              class="c-simple-table"
            >
              <thead>
              <tr>
                <th>
                  User Limits
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <template>
                <tr>
                  <td>
                    Items
                  </td>
                  <td>
                    {{ userLimits.items }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>

        </template>
      </GroupBox>

      <GroupBox accent>
        <template v-slot:header>
          {{ localization.general.Profile.Licenses }}
        </template>
        <template v-slot:content>
          <table class="c-simple-table">
            <thead>
            <tr>
              <th>
                {{ localization.general.Profile.ColumnId }}
              </th>
              <th>
                {{ localization.general.Profile.ColumnTag }}
              </th>
              <th>
                {{ localization.general.Profile.ColumnPrice }}
              </th>
              <th>
                {{ localization.general.Profile.ColumnEndTime }}
              </th>
              <th>
                {{ localization.general.Profile.ColumnCreationTime }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="license in computedLicenses"
              :key="license.id"
              :style="[
                {
                  'background-color': activeLicense === license.id ? '#eeffff' : '',
                }
              ]"
              @click="activeLicense = activeLicense === license.id ? '' : license.id"
            >
              <td>{{ license.id }}</td>
              <td>{{ license.tag }}</td>
              <td>{{ license.price }}</td>
              <td>{{ new Date(license.endDateTime).toLocaleString() }}</td>
              <td>{{ new Date(license.creationDateTime).toLocaleString() }}</td>
            </tr>
            </tbody>
          </table>

          <div class="u-d-flex u-mt-4">
            <Btn
              :disabled="!activeLicense"
              outlined
              @click="deleteSelectedLicense"
            >
              {{ localization.general.Simple.Remove }}
            </Btn>
            <Btn
              @click="openAddLicenseDialog()"
            >
              {{ localization.general.Simple.Add }}
            </Btn>
          </div>
        </template>
      </GroupBox>
      <GroupBox accent>
        <template v-slot:header>
          {{ localization.general.Profile.Subscriptions }}
        </template>
        <template v-slot:content>
          <table
            v-if="userSubscriptions.length > 0"
            class="c-simple-table"
          >
            <thead>
            <tr>
              <th>
                {{ localization.general.Profile.SubscriptionCreationDateTime }}
              </th>
              <th>
                {{ localization.general.Profile.SubscriptionPrice }}
              </th>
              <th>
                {{ localization.general.Profile.SubscriptionLastPaymenthateTime }}
              </th>
              <th>
                Charge
              </th>
              <th>
                {{ localization.general.Profile.SubscriptionChargeState }}
              </th>
            </tr>
            </thead>
            <tbody>

            <tr
              v-for="subscription in userSubscriptions"
              :key="subscription.id"
            >
              <td>
                {{ subscription.creationDateTime }}
              </td>
              <td>
                {{ subscription.header }}
              </td>
              <td>
                {{ subscription.lastPaymentDateTime }}
              </td>
              <td>
                {{ localization.general.Profile['SubscriptionChargeState' + subscription.result] }}
              </td>
              <td>
                {{ subscription.state }}
              </td>
            </tr>

            </tbody>
          </table>
          <div v-else class="no-active-subscription">
            {{ localization.general.Profile.NoActiveSubscriptions }}
          </div>
        </template>
      </GroupBox>
      <GroupBox accent>
        <template v-slot:header>
          Bills
        </template>
        <template v-slot:content>
          <table class="c-simple-table">
            <thead>
            <tr>
              <th>
                Created at
              </th>
              <th>
                Cart Id
              </th>
              <th>
                Price
              </th>
              <th>
                Discount
              </th>
              <th>
                Source
              </th>
              <th>
                State
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-if="loaded">
              <tr
                v-for="bill in billsByDate"
                :key="bill.id"
              >
                <td>
                  {{ new Date(bill.creationDateTime).toLocaleString() }}
                </td>
                <td>
                  {{ bill.cartId }}
                </td>
                <td>
                  {{ bill.totalPrice.discount ? bill.totalPrice.discount.price : bill.totalPrice.value }}
                </td>
                <td>
                  {{ bill.totalPrice.discount ? bill.totalPrice.discount.value : 0 }}
                </td>
                <td>
                  {{ bill.source }}
                </td>
                <td>
                  {{ bill.state }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </template>
      </GroupBox>
      <GroupBox accent>
        <template v-slot:header>
          {{ localization.general.Profile.Referrals }}
        </template>
        <template v-slot:content>
          <ReferralsTable :user="user"/>
        </template>
      </GroupBox>
      <GroupBox accent>
        <template v-slot:header>
          Ranges
        </template>
        <template v-slot:content>
          <div class="user-ranges">
            <div class="user-ranges__list">
              <UserRangesList
                :user-id="user.id"
                @currentItemChange="onCurrentItemChange"
              />
            </div>
            <div
              v-if="currentItem"
              class="user-ranges__main"
            >
              <RangeViewer
                :is-read-only="true"
                :item="currentItem"
              />
            </div>
          </div>
        </template>
      </GroupBox>

      <AddLicenseDialog
        v-model="addLicenseDialog"
        :user-id="user.id"
        @licenseCreate="onLicenseCreate"
      />
    </div>

  </div>
</template>

<script>
import ReferralsTable from '@/components/ReferralsTable';
import moment from 'moment';


import {GET_LANG_STORE} from '@/api/localizations';
import {SEARCH_SUBSCRIPTIONS} from '@/api/subscriptions';
import {GET_ITEMS_STATISTICS} from '@/api/statistics';
import {SEARCH_BILLS} from '@/api/bills';
import {DELETE_LICENSE, GET_LICENSES, SET_LICENSE} from '@/api/licenses';
import {GET_USER_LIMITS} from '@/api/userLimits';
import {GET_USER} from '@/api/users';

import AddLicenseDialog from "../../../../components/AddLicenseDialog";
import GroupBox from "../../../../components/GroupBox/GroupBox";
import UserRangesList from "../../../../components/UserRangesList";
import RangeViewer from "../../../../components/RangeEditor/RangeViewer";
import {SET_REFERRALS} from "@/api/referrals";
import Btn from "@/components/UI/Btn";

export default {
  name: 'AdminUsersSingle',
  components: {
    Btn,
    RangeViewer,
    UserRangesList,
    GroupBox,
    AddLicenseDialog,
    ReferralsTable
  },
  data() {
    return {
      currentItem: null,

      addLicenseDialog: false,

      loaded: false,
      userSubscriptions: [],
      activeTab: 1,

      user: {},
      userLicenses: [],
      userBills: [],
      userLimits: null,
      userStatistics: null,

      activeLicense: '',
      disableReferralCheckbox: false,
      percent: 12,
      isCheck: false,

    };
  },
  computed: {
    computedLicenses() {
      return this.userLicenses.filter(license => {
        return (
          moment(license.endDateTime).valueOf() >
          moment('01-01-2019', 'dd-mm-yyyy').valueOf()
        );
      });
    },
    billsByDate() {
      const arrOr = this.userBills;
      const newarr = arrOr.sort((a, b) => {
        return moment(b.creationDateTime).diff(a.creationDateTime);
      });
      return newarr;
    },

    isReferralSystemEnabled: {
      get() {
        return this.user.referrer.isReferralSystemEnabled;
      },
      async set(value) {
        this.disableReferralCheckbox = true;

        await SET_REFERRALS({
          userId: this.user.id,
          isEnabled: value.isReferralSystemEnabled,
          profit: value.profit
        })

        this.disableReferralCheckbox = false;
        this.user.referrer.isReferralSystemEnabled = value;
      },
    },
  },
  methods: {
    onCurrentItemChange(value) {
      this.currentItem = value;
    },
    async onLicenseCreate(value) {
      await SET_LICENSE(value.newLicense);
      await this.loadLicenses();
    },
    openAddLicenseDialog() {
      this.addLicenseDialog = true
    },
    isNotActive(date) {
      return (
        moment(date).valueOf() < moment('01-01-2019', 'dd-mm-yyyy').valueOf()
      );
    },
    async onChangeReferral() {
      await SET_REFERRALS({
        userId: this.user.id,
        isEnabled: this.isCheck,
        profit: this.percent ? this.percent / 100 : 0,
      }, (isSuccess) => {
        if (isSuccess) {
          this.$toast.success("User Referral Successfully was setted");
        }
      });
    },
    async loadSubscriptions() {
      const userSubscriptions = await SEARCH_SUBSCRIPTIONS({
        userId: this.$route.params.id,
        itemsForPage: 50
      })
      const pLangStore = await GET_LANG_STORE(this.activeLang, 'store');

      this.userSubscriptions = this.translationsSubscriptionsToPriceBlocks(pLangStore, userSubscriptions.data);
    },
    async loadLicenses() {
      this.userLicenses = await GET_LICENSES({
        userId: this.$route.params.id
      });
    },
    async deleteSelectedLicense() {
      await DELETE_LICENSE(this.activeLicense);

      const activeLicenseIndex = this.userLicenses.findIndex((license) => license.id === this.activeLicense);
      this.userLicenses.splice(activeLicenseIndex, 1);
      this.$toast.success(this.localization.general.Profile.LicenseSuccessfullyDeleted);
    },

  },
  async mounted() {
    this.user = await GET_USER(this.$route.params.id);
    this.userLimits = await GET_USER_LIMITS(this.user.id);
    this.userStatistics = await GET_ITEMS_STATISTICS(this.user.id);

    const bills = await SEARCH_BILLS({
      userId: this.user.id
    });
    this.userBills = bills.data;

    this.isCheck = this.user.referrer.isReferralSystemEnabled;
    this.percent = this.user.referrer.profit * 100;

    await this.loadSubscriptions();
    await this.loadLicenses();

    this.loaded = true;

  },
  watch: {
    activeLang() {
      this.loadSubscriptions();
    }
  }
};
</script>

<style lang="scss" scoped>
.user {
  &__referral-block {
    margin: 20px 0;

    &__row {
      display: flex;
      align-items: center;

      input[type=checkbox] {
        width: 20px;
        height: 20px;
      }

      .form-input {
        margin: 0 15px;
        height: 40px;
      }

      .c-button {
        height: 40px;
      }
    }
  }

  &__go-back {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;

    img {
      margin-right: 6px;
      width: 12px;
      transform: rotate(180deg);
    }
  }

  &__statistics {
    display: flex;
    align-items: flex-start;
    margin: 0 -10px;
    margin-top: 30px;

    .c-simple-table {
      width: 50%;
      margin: 0 10px;
    }
  }
}

.no-active-subscription {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.user-ranges {
  display: flex;

  &__list {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    width: 40%;
    padding-right: 15px;
  }

  &__main {
    padding-left: 15px;

    width: 60%;
  }
}
</style>
