<template>
  <div
    :class="[
      'expander',
      {
        'expander--overlaid': overlayContent
      }
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Expander',
  data() {
    return {
      items: [],
      activeExpander: -1,
      key: 1
    };
  },
  props: {
    value: {
      type: Number,
      default: -1
    },
    persistent: {
      type: Boolean,
      default: false
    },
    overlayContent: Boolean
  },
  methods: {
    expandItem(i, isLinked = false) {
      this.activeExpander = this.activeExpander === i ? null : i;
      console.warn(this.activeExpander);
      // loop over all the tabs
      this.items.forEach((item, index) => {
        if (isLinked) {
          item.isActive = !item.isActive;
        } else {
          item.isActive = index === this.activeExpander;
        }
      });
    },
    loadOnMount() {
      this.items = this.$children;
      this.items.forEach((item, index) => {
        item.key = index;
      });

      this.expandItem(this.value);
    }
  },
  created() {
    if (this.overlayContent && !this.persistent) {
      window.addEventListener('click', e => {
        if (!this.$el.contains(e.target)) {
          this.expandItem(null);
        }
      });
    }
  },
  mounted() {
    this.loadOnMount();
  },
  watch: {
    value() {
      this.activeExpander = this.value;
      this.expandItem();
    }
  }
};
</script>

<style lang="scss" src="./Expander.scss" />
