<style lang="scss" scoped>
$medium: 1220px;
$tablet: 768px;
$tabletMini: 700px;
$phone: 500px;
.courses {
    background: #ecf0f6;
    display: grid;
    min-height: 100vh;
    grid-template-areas:
        'header'
        'main'
        'footer';
}

.header {
    grid-area: header;
}
.footer {
    grid-area: footer;
}
.main {
    width: 1216px;
    margin: 0 auto;
    margin-top: 90px;
    grid-area: main;
}
.breadcrumbs {
    margin-left: 32px;
}
.breadcrumbs .button__text {
    font-family: 'proxima';
    display: inline-block;
    position: relative;
    top: -2px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    padding-left: 16px;
    /* identical to box height */

    /* dark */

    color: #404c61;
}
.substrate {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 150px 255px;
    margin-top: 30px;
    background: #ffffff;
    box-shadow: 0px 30px 40px rgba(94, 118, 159, 0.03);
    padding: 35px 50px 30px 40px;
    border-radius: 20px 20px 0px 0px;
}
.substrate__title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;

    /* dark */

    color: #404c61;
}
.substrate__sub_title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
    /* dark */

    color: #404c61;
}
.substrate__description {
    margin-top: 20px;
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;

    /* grey */

    color: #808fb0;
}
.substrate_statistic_title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 78px;
    /* identical to box height */

    /* blue */

    color: #12b7db;
}
.substrate_statistic_sub_title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    /* dark */

    color: #404c61;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.substrate .button,
.modal .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 15px 25px;
    gap: 14px;
    border-radius: 100px;
}
.substrate .button__primary .button__text,
.modal .button {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    text-transform: uppercase;

    /* white */

    color: #ffffff;
}
.button {
    cursor: pointer;
}
.divider--horizontal {
    border-left: 1px solid black;
    height: 18px;
}
.button__price {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    text-transform: uppercase;

    /* white */

    color: #ffffff;
}
.button__primary {
    background: #12b7db;
    &:hover {
        background: #42d0ef;
    }
}
.button__secondary {
    border: 2px solid #dde5f3;
    color: #404c61;
    padding: 11px 20px 11px 25px !important;
    &:hover {
        color: #42d0ef;
        border-color: #42d0ef;
    }
}
.button__secondary .button__text {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    text-transform: uppercase;

    /* dark */
}
.skill__title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}
.items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1px;
    row-gap: 1px;
}
.no__data {
    min-width: 303px;
    max-width: 303px;
    width: 303px;
    height: 220px !important;
    display: inline-block;

    background: #f2f5fa;

    border: none;
    padding: 0;
}
.skill {
    margin-bottom: 150px;
}
.modal-dialog__footer,
.modal-dialog__header {
    background-color: inherit;
}
input[type='checkbox']:checked {
    accent-color: #1892ad;
    border-radius: 4px;
}
.modal-dialog__header_title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    width: 90%;
}
.modal-dialog__header_sub_title {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    width: 90%;
    /* or 26px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* grey */

    color: #808fb0;
}
.modal__checkbox {
    margin-top: 15px;
    margin-right: 10px;
}
.modal__checkbox label {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */
    width: 70%;

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* black */

    color: #242424;
}

@media screen and (max-width: $medium) {
    .main {
        width: 100%;
        padding: 0 20px;
    }
    .substrate {
        grid-template-columns: 1fr 50px 255px;
    }
    .no__data {
        min-width: 220px;
        width: 32.6%;
        max-width: none;
    }
}
.button__primary_margin-top {
    margin-top: 28px;
}
@media screen and (max-width: $tablet) {
    .substrate {
        grid-template-columns: 1fr 20px 255px;
    }
    .no__data {
        min-width: 200px;
        width: 49.5%;
        max-width: none;
    }
}
@media screen and (max-width: $tabletMini) {
    .substrate {
        grid-template-columns: 1fr;
    }
    .no__data {
        min-width: 220px;
        width: 49.5%;
        max-width: none;
    }
    .for__tablet {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .substrate_statistic_sub_title {
        align-self: center;
    }
    .substrate_statistic_title {
        font-size: 54px;
        justify-self: center;
    }
    .button__primary_margin-top {
        margin-top: 10px;
    }
    .skill__title {
        font-size: 24px;
        margin-top: 15px;
        text-align: center;
    }
    .substrate__title {
        font-size: 32px;
    }
    .substrate__sub_title {
        font-size: 15px;
    }
    .substrate__description {
        font-size: 15px;
    }
}

@media screen and (max-width: $phone) {
}
</style>
<style lang="scss">
.courses .select__header {
    border: 1px solid #dde5f3 !important;
}
.courses .modal-dialog__content {
    width: 100%;
    max-width: 394px !important;
}
.courses-dialog .modal-dialog__close {
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>
<template>
    <div class="courses">
        <div class="header">
            <Header />
        </div>

        <section class="main">
            <div class="breadcrumbs">
                <div class="button" @click="previousPage">
                    <div
                        class="image"
                        style="margin-top:2px; display: inline-block;"
                    >
                        <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7 1L1 7L7 13"
                                stroke="#12B7DB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>

                    <div class="button__text">
                        {{ localization.courses.returnToPreviousPage }}
                    </div>
                </div>
            </div>
            <section class="substrate">
                <div class="loading" v-if="isLoading">
                    <VueSkeletonLoader
                        v-for="item in [1, 2, 3, 4, 5]"
                        :key="item"
                        style="margin-top:10px"
                        :width="Math.trunc(Math.random() * 100) + '%'"
                        height="14px"
                        type="rect"
                        animation="fade"
                    ></VueSkeletonLoader>
                </div>

                <div class="text-block" v-else>
                    <h1
                        class="substrate__title"
                        v-html="marked(item.localization[lang].name)"
                    ></h1>
                    <div
                        class="substrate__sub_title"
                        v-html="marked(item.localization[lang].description)"
                    ></div>
                    <div
                        class="substrate__description"
                        v-html="
                            marked(localization.courses.currentSubDescription)
                        "
                    ></div>
                </div>
                <div></div>
                <div class="loading" v-if="isLoading">
                    <VueSkeletonLoader
                        v-for="item in [1, 2, 3, 4, 5]"
                        :key="item"
                        style="margin-top:10px"
                        :width="Math.trunc(Math.random() * 100) + '%'"
                        height="14px"
                        type="rect"
                        animation="fade"
                    ></VueSkeletonLoader>
                </div>
                <div class="action-block" v-else>
                    <div class="for__tablet">
                        <h1 class="substrate_statistic_title">
                            {{ getProgress(item.progress) }}%
                        </h1>
                        <p class="substrate_statistic_sub_title">
                            ваш прогресс изучению
                        </p>
                    </div>

                    <div
                        v-if="item.price && item.price.value !== null"
                        class="button button__primary button__primary_margin-top "
                        @click="onBuy"
                    >
                        <div
                            class="button__text"
                            v-html="marked(localization.courses.buy)"
                        ></div>
                        <div class="divider--horizontal"></div>
                        <div class="button__price">
                            {{ item.price && item.price.value }}$
                        </div>
                    </div>
                    <div
                        v-if="
                            item.levels.filter(el => el.includeDemo).length > 0
                        "
                        class="button button__secondary"
                        style="margin-top:20px;"
                        @click="setDemo"
                    >
                        <div
                            class="button__text"
                            v-html="
                                marked(
                                    item.price && item.price.value !== null
                                        ? localization.courses.onDemo.replace(
                                              '{{lessons}}',
                                              item.levels.filter(
                                                  el => el.includeDemo
                                              ).length
                                          )
                                        : localization.courses.hasDemo
                                )
                            "
                        ></div>
                        <div></div>
                        <div style="margin-top:5px;">
                            <svg
                                width="21"
                                height="21"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.5 15.5L15.5 11.5M15.5 11.5L11.5 7.5M15.5 11.5H7.5M21.5 11.5C21.5 17.0228 17.0228 21.5 11.5 21.5C5.97715 21.5 1.5 17.0228 1.5 11.5C1.5 5.97715 5.97715 1.5 11.5 1.5C17.0228 1.5 21.5 5.97715 21.5 11.5Z"
                                    stroke="#12B7DB"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <h1 class="skill__title">
                    <span
                        v-if="!isLoading"
                        v-html="marked(localization.courses.yourSkills)"
                    >
                    </span>
                    <VueSkeletonLoader
                        v-else
                        style="margin-top:10px"
                        width="50%"
                        height="14px"
                        type="rect"
                        animation="fade"
                    ></VueSkeletonLoader>
                </h1>
                <!-- {{ $route.params.id }} -->
            </section>
            <section class="skill" style="margin-top:10px">
                <div
                    class="items"
                    style="margin-bottom: 10px"
                    v-show="isLoading"
                >
                    <div v-for="item in nullCards" :key="item">
                        <VueSkeletonLoader
                            class="no__data"
                            type="rect"
                            animation="fade"
                        ></VueSkeletonLoader>
                    </div>
                </div>
                <div
                    class="items"
                    style="margin-bottom: 10px"
                    v-if="!isLoading"
                >
                    <Card
                        :item="item"
                        v-for="item in item.levels"
                        :key="item.id"
                    ></Card>

                    <div
                        class="no__data"
                        v-for="item in nullCards"
                        :key="item"
                    ></div>
                </div>
            </section>

            <Dialog
                v-if="item && !isLoading"
                v-model="isDemo"
                tab="0"
                v-on:keyup.enter="onDemo"
                class="add-license-dialog courses-dialog modal-dialog--size-to-content modal-dialog--width-medium modal "
            >
                <DialogHeader>
                    <h1
                        class="modal-dialog__header_title"
                        v-html="marked(item.localization[lang].name)"
                    ></h1>
                    <p class="modal-dialog__header_sub_title">
                        Вам доступно 2 урока в демо. Чтобы уроки, необходимо
                        купить курс
                    </p>
                </DialogHeader>
                <DialogContent style="min-height:200px;">
                    <Select
                        v-model="skill"
                        :height="48"
                        :items="skills"
                        :width="'100%'"
                        background-color="white"
                        highlighted-text
                        rounded
                    />
                    <div class="modal__checkbox">
                        <input type="checkbox" v-model="checkbox" />
                        <label>
                            Раздавать только рукис допущенными ошибками
                        </label>
                    </div>

                    <button
                        class="button button__primary  "
                        style="margin-top: 21px; width: 100%"
                        type="submit"
                        @click="onDemo"
                    >
                        начать тренировку
                    </button>
                </DialogContent>
                <DialogFooter> </DialogFooter>
            </Dialog>
        </section>
        <div class="footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'
import Card from '@/components/UI/CoursesCard/skillCard'
import VueSkeletonLoader from 'skeleton-loader-vue'
import marked from 'marked'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from '@/components/UI/Dialog'
import Select from '@/components/UI/Select/Select'
import { GET_CURRENT_COURSE } from '@/api/courses'
export default {
    name: 'CurrentCourse',
    components: {
        Header,
        Footer,
        Card,
        VueSkeletonLoader,
        Dialog,
        DialogContent,
        DialogFooter,
        DialogHeader,
        Select,
    },
    data() {
        return {
            windowWidth: 0,
            isLoading: true,
            isDemo: false,

            skill: null,
            checkbox: false,
            item: {},
            skills: [],
        }
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
        setDemo() {
            this.isDemo = true
        },
        onDemo() {
            const item = this.skill
            if (!item) return
            alert(item)
        },
        onBuy() {},
        marked(text) {
            return marked(text)
        },
        onEnter() {
            if (!this.isDemo) return
            this.onDemo()
        },
        previousPage() {
            this.$router.push('/courses')
        },
        getProgress(progress) {
            return (progress * 100).toString().slice(0, 2)
        },
    },
    watch: {
        lang(val) {
            console.log('lang', val)
        },
    },
    computed: {
        lang() {
            return this.$store.state.ui.activeLang
        },
        localizedItemInfo() {
            if (this.item.localization.hasOwnProperty(this.lang))
                return this.item.localization[this.lang]

            return null
        },
        nullCards() {
            let cardInLine = 4

            switch (true) {
                case this.windowWidth <= 600:
                    cardInLine = 0
                    break
                case this.windowWidth <= 768:
                    cardInLine = 2
                    break
                case this.windowWidth <= 1220:
                    cardInLine = 3
                    break
            }
            if (this.isLoading)
                return new Array(cardInLine).fill(0).map((_, i) => i)

            if (cardInLine == 0 || this.item.levels.length % cardInLine == 0)
                return []

            return new Array(
                cardInLine - (this.item.levels.length % cardInLine)
            )
                .fill(0)
                .map((_, i) => i)
        },
    },

    async mounted() {
        const id = this.$route.params.id
        this.windowWidth = window.innerWidth
        if (!id) this.$router.push('/notFound')

        try {
            const data = await GET_CURRENT_COURSE(id)

            this.item = data
            this.skills = data.levels
                .filter(el => el.includeDemo)
                .map(({ id, localization }) => {
                    return { value: id, label: localization[this.lang].name }
                })

            if (this.skills.length > 0) this.skill = this.skills[0].value
        } catch (err) {
            console.error(err)
        } finally {
            this.isLoading = false
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
                window.addEventListener('keyup', this.onEnter)
            })
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('keyup', this.onEnter)
    },
}
</script>
