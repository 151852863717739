<template>
  <div class="container">
    <div v-if="isLoadPrice" class="pricing-palden pricing">
      <PriceBlock
        v-for="block in prices"
        :id="block.id"
        :key="block.id"
        :button="block.button"
        :currency="block.currency"
        :fields="block.fields"
        :header="block.header"
        :licenseKey="block.id"
        :month="block.period"
        :red="block.redText"
        :strike="block.strikeText"
        :textPrice="block.textPrice"
      />
    </div>
  </div>
</template>

<script>
import PriceBlock from '../../components/PriceBlock';
// import { GET_LANG_STORE } from '@/api/localizations';
import { GET_STORE_LICENSES } from '@/api/stores';

export default {
  name: 'Price',
  components: {
    PriceBlock
  },
  data() {
    return {
      isLoadPrice: false,
      priceBlocks: null
    };
  },
  computed: {
    lang() {
      return this.$store.state.ui.activeLang;
    },
    prices() {
      return this.priceBlocks;
    },
    advancedGameMode() {
      return this.localization.general.GlobalPreferences.Advanced;
    },
    priceContent() {
      return this.$store.state.landing.landingLangPrice ?? '';
    }
  },
  methods: {
    loadPriceBlocks() {
      //TODO: доделать price local
      // const pLangStore = GET_LANG_STORE(this.lang, 'store');
      const pLangStore = this.localization.store;
      console.log(this.localization.store, 'LOCALIZATION PRICES');
      const pStoreLicenses = GET_STORE_LICENSES();
      console.log(pStoreLicenses, 'p store licensec');
      Promise.all([pLangStore, pStoreLicenses]).then(arr => {
        console.log(arr[0], arr[1], 'arrrrayz');

        this.priceBlocks = this.translationsAndLicensesToPriceBlocks(
          arr[0],
          arr[1]
        );
        this.isLoadPrice = true;
      });
    }
  },
  created() {
    this.loadPriceBlocks();
  },
  watch: {
    lang() {
      this.loadPriceBlocks();
    }
  }
};
</script>

<style>
.pricing-palden {
  display: flex;
}

.text-fix {
  font-size: 16px;
}

.text-content {
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: center;
}

.pricing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 auto 3em;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* @media (max-width: 1200px) {
    .pricing-palden {
        flex-direction: column;
    }
} */
</style>
