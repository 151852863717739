<template>
  <div>
    <h1 class="faq--header">{{ currentItems[0]['category'] }}</h1>
    <div v-for="item in currentItems" :key="item.id">
      <Expander>
        <ExpanderItem>
          <template v-slot:header>
            {{ item.title }}
          </template>
          <template v-slot:content>
            <div v-html="item.value" class="faq__content"></div>
          </template>
        </ExpanderItem>
      </Expander>
    </div>
  </div>
</template>

<script>
import { Expander, ExpanderItem } from '@/components/UI/Expander';

export default {
  name: 'FaqExpanderList',
  components: { ExpanderItem, Expander },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentItems() {
      return this.$props.items;
    }
  }
};
</script>

<style lang="scss">
.faq {
  &--header {
    display: flex;
    justify-content: center;

    margin-bottom: 20px;
  }
  &__content {
    font-size: 18px;
    img {
      max-width: 100%;
      height: auto;
      border: 3px solid #000;
    }
  }
}
</style>
