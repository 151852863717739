<template>
  <div
    :class="[
      'game-statistics',
      {
        'game-statistics--active': value
      }
    ]"
  >
    <div class="game-statistics__logo">
      <img class="game-statistics__logo__img" src="/img/logo.svg"/>
    </div>
    <div class="game-statistics__block">

      <div class="game-statistics__row">
        <div class="game-statistics__item game-statistics__item--w-50">
          <h6 class="game-statistics__item__label">{{ localization.general.TestResult.TotalTime }}</h6>
          <h2 class="game-statistics__item__value">
            {{ totalMinutes }}
            {{ localization.general.TestResult.TotalMinutes }}
            {{ totalSeconds }}
            {{ localization.general.TestResult.TotalSeconds }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-50">
          <h6 class="game-statistics__item__label">{{ localization.general.TestResult.AverageAnswerTime }}</h6>
          <h2 class="game-statistics__item__value">
            {{ statData.averageAnswerTime }}
            {{ localization.general.TestResult.TotalSeconds }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-25">
          <h6 class="game-statistics__item__label">{{ localization.general.TestResult.TotalHands }}</h6>
          <h2 class="game-statistics__item__value">
            {{ statData.totalHands }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-25 game-statistics__item--correct">
          <h6 class="game-statistics__item__label">{{ localization.general.TestResult.CorrectAnswers }}</h6>
          <h2 class="game-statistics__item__value">
            {{ statData.correctAnswers }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-25 game-statistics__item--incorrect">
          <h6 class="game-statistics__item__label">{{ localization.general.TestResult.IncorrectAnswers }}</h6>
          <h2 class="game-statistics__item__value">
            {{ statData.incorrectAnswers }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-25">
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.Quality }}
          </h6>
          <h2 class="game-statistics__item__value">
            {{ statData.quality }}%
          </h2>
        </div>
      </div>
      <Btn
        class="game-statistics__save"
        @click="save()"
      >
        FINISH
      </Btn>
    </div>
    <Footer/>
  </div>

</template>

<script>
import Btn from "@/components/UI/Btn";

export default {
  name: 'GameStatisticsDialog',
  components: {
    Btn
  },
  props: {
    statData: Object,
    value: Boolean,
  },
  computed: {
    totalMinutes() {
      return Math.floor(this.statData.totalTime / 60);
    },
    totalSeconds() {
      return this.statData.totalTime - this.totalMinutes * 60;
    },
  },
  methods: {
    save() {
      this.$emit('finishGame');
    },
  },
  watch: {
    value(val) {
      if (!val) this.$emit('finishGame');
    }
  }
};
</script>

<style lang="scss" scoped src="./GameStatisticsDialog.scss"/>
