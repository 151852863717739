var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Expander',{attrs:{"persistent":""}},[_c('ExpanderItem',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.dataItem.name)+" ")])]},proxy:true},{key:"header-buttons",fn:function(){return [_c('Btn',{staticClass:"range-editor__start-test-btn",attrs:{"disabled":_vm.btnDisabled,"loading":_vm.btnLoading,"height":"38px","color":_vm.btnColor,"onmouseover":"event.stopPropagation();"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleBtnAction()},"getIsActiveState":_vm.expanderIsActive}},[_vm._v(" "+_vm._s(_vm.btnLabel)+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{class:[
            'item-options-editor',
            {
              'item-options-editor--readonly': _vm.isReadOnly
            }
          ]},[_c('div',{staticClass:"item-options-editor__row"},[_c('div',{staticClass:"item-options-editor__label"},[_vm._v(" "+_vm._s(_vm.localization.general.Diapason.name)+" ")]),_c('div',{staticClass:"item-options-editor__value"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataItem.name),expression:"dataItem.name"}],staticClass:"form-input item-options-editor__input item-options-editor__input--w-100",domProps:{"value":(_vm.dataItem.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataItem, "name", $event.target.value)}}})])]),_c('div',{staticClass:"item-options-editor__row"},[_c('div',{staticClass:"item-options-editor__label"}),_c('div',{staticClass:"item-options-editor__value"},[_c('button',{class:[
                  'item-options-editor__link-groups-btn',
                  {
                    'item-options-editor__link-groups-btn--active': _vm.linkingMode
                  },
                  'item-options-editor__link-groups-btn--turnOnPointerEvents'
                ],on:{"click":function($event){return _vm.toggleLinkingMode()}}},[_vm._v(" "+_vm._s(_vm.linkingModeBtnText)+" ")])])]),_c('div',{staticClass:"item-options-editor__row item-options-editor__row--link-groups"},[_c('div',{staticClass:"item-options-editor__label"},[_vm._v(" "+_vm._s(_vm.localization.general.ItemGroupOptions.LinkedItems)+" ")]),_c('div',{staticClass:"item-options-editor__value"},[(_vm.linkedItemsLoading)?_c('img',{staticClass:"loading-gif",attrs:{"src":"/img/loading.gif"}}):[(_vm.linkedItems.length === 0)?_c('i',[_vm._v(" "+_vm._s(_vm.localization.general.Common.Empty)+" ")]):_vm._l((_vm.linkedItems),function(linkedItem,index){return _c('div',{key:linkedItem.id,staticClass:"item-options-editor__row--link-groups__item"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.linkingMode),expression:"linkingMode"}],staticClass:"item-options-editor__row--link-groups__item__icon",attrs:{"src":"/icons/close-wrapped.svg"},on:{"click":function($event){return _vm.removeFromLinkedItems(index)}}}),_vm._v(" "+_vm._s(linkedItem.name)+" ")])})]],2)])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }