<template>
  <div class="pricing-item">
    <div class="pricing-deco">
      <svg
        id="Layer_1"
        class="pricing-deco-img"
        enable-background="new 0 0 300 100"
        height="100px"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 300 100"
        width="300px"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        y="0px"
      >
        <path
          class="deco-layer deco-layer--1"
          d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
          fill="#FFFFFF"
          opacity="0.6"
        />
        <path
          class="deco-layer deco-layer--2"
          d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
          fill="#FFFFFF"
          opacity="0.6"
        />
        <path
          class="deco-layer deco-layer--3"
          d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
          fill="#FFFFFF"
          opacity="0.7"
        />
        <path
          class="deco-layer deco-layer--4"
          d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
          fill="#FFFFFF"
        />
      </svg>
      <div class="pricing-price">{{ header }}</div>
    </div>
    <div class="description">
      <span class="regular text">
        <del>{{ strike }}</del>
      </span>
      <span class="desc text">
        <b>{{ textPrice }}</b>
      </span>

      <h3 class="pricing-title red-text text" style="min-height: 30px">
        {{ red }}
      </h3>
    </div>
    <ul class="pricing-feature-list">
      <li v-for="field in fields" :key="field" class="pricing-feature">
        <span class="text">{{ field }}</span>
        <span class="true_false">
          <img src="/icons/check.svg" style="margin-top: 10px" width="12">
        </span>
      </li>
    </ul>

    <template v-if="isLogged">
      <a class="pricing-action text" @click="onClickBuy">{{ button }}</a>
    </template>
    <template v-else>
      <a class="pricing-action text" href="/signin">{{ button }}</a>
    </template>

  </div>
</template>

<script>
import auth from '../auth';
import {CREATE_STORE_FASTSPRING_CART} from '@/api/stores';

//
export default {
  props: [
    'fields',
    'id',
    'currency',
    'month',
    'red',
    'textPrice',
    'strike',
    'button',
    'licenseKey',
    'header',
  ],
  computed: {
    isLogged() {
      return auth.isLogged();
    },
    userLevel() {
      return this.$store.state.user.userLevel;
    },
  },
  methods: {
    async onClickBuy() {
      this.$track.buyLicense();

      /// this.errors.push('ERROR');
      try {

        // const response = await CREATE_STORE_FASTSPRING_CART([
        //   'prefloper-user-base-monthly',
        //   'prefloper-user-base-quarterly',
        //   'prefloper-user-base-yearly'
        // ]);
        const response = await CREATE_STORE_FASTSPRING_CART([
          this.id
        ]);

        /// TODO not work
        await window.fastspring.builder.secure(
          response.encryptionResult.payload,
          response.encryptionResult.secureKey
        );
        await window.fastspring.builder.checkout();

      } catch (err) {
        alert(JSON.stringify(err))
        /// TODO not work
        this.errors.push('ERROR');
      }
    },
    getMetadata(id) {
      try {
        const {prices} = this.$store.state.user;
        const block = prices.find((p) => p.id == id);
        return block.metadata;
      } catch (err) {
        return 'Error';
      }
    }
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.pricing-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 330px;
  flex: 0 1 330px;
  margin: 1em -0.5em;
}

.pricing-item:nth-child(2) {
  z-index: 10;
  margin: 0;
  -webkit-box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
}

.pricing-header {
  position: relative;
  padding: 4em 0 9em;
  border-radius: 10px 10px 0 0;
  background-color: #1fb0d6;
}

.pricing-palden .red-text {
  font-size: 20px;
  margin: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ca1515;
}

.pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
}

.pricing-deco {
  position: relative;
  font-size: 16px;
  padding: 4em 0 9em;
  margin-top: 0;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(to top, #1f99d6 0%, #273142 100%);
}

.pricing-price {
  font-size: 40px;
  font-weight: bold;
  line-height: 0.75;
  margin: 0 0 0.25em 0;
  padding: 0;
  color: #fff;
}

.pricing-currency {
  font-size: 20px;
  vertical-align: top;
}

.pricing-period {
  font-size: 20px;
  font-style: italic;
  padding: 0;
}

.pricing-title {
  color: #fff;
}

.descripton {
  z-index: 1000;
  margin-top: -60px;
  color: #000;
}

.pricing-feature-list {
  margin: 0;
  padding: 20px 0;

  text-align: left;
  list-style: none;
}

.pricing-feature {
  clear: both;
  padding: 10px 25px;
}

.pricing-feature span.text {
  float: left;
  width: 80%;
}

.pricing-feature span.true_false {
  float: right;
  width: 20px;
}

.pricing-action {
  font-weight: bold;
  margin: auto 3em 2em 3em;
  display: inline-block;
  padding: 1em 2em;
  -webkit-transition: background-color $animation-speed-normal;
  -o-transition: background-color $animation-speed-normal;
  transition: background-color $animation-speed-normal;
  color: #fff;
  background: linear-gradient(to top, #1fb0d6 0%, #0b77ba 100%);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


.description {
  /* z-index: 1000; */
  z-index: 1;
  margin-top: -60px;
  color: #000;
}

.pricing-item:hover .deco-layer--1 {
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
}

.pricing-item:hover .deco-layer--2 {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}

.pricing-palden .deco-layer {
  -webkit-transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.pricing-palden .pricing-item {
  margin: 1em;
  cursor: default;
  color: #1fb0d6;
  border-radius: 20px 20px 10px 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
}

.pricing-palden .pricing-item {
  margin: 1em -0.5em;
}

.pricing-palden .pricing-item:nth-child(2) {
  z-index: 10;
  margin: 0;
  -webkit-box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
}

.pricing-palden .description span {
  display: block;
  width: 100%;
  min-height: 24px;
}

.pricing-palden .pricing-feature-list {
  margin: 0;
  padding: 20px 0;
  list-style: none;
}

.pricing-palden .pricing-feature {
  clear: both;
  padding: 10px 25px;
}

.pricing-palden .pricing-feature span.text {
  float: left;
  width: 80%;
}

.pricing-palden .pricing-feature span.true_false {
  float: right;
  width: 20px;
}

.pricing-palden .pricing-feature span {
  display: inline-block;
  color: #000;
}

.pricing-palden .pricing-feature span.true_false i.fa-check {
  color: #4cadc9;
}

.fa-check:before {
  content: '\f00c';
}

[class*='fa-']:before {
  font-family: 'FontAwesome';
  font-weight: 400;
}

.pricing-palden .pricing-feature span.true_false i {
  font-size: 20px;
}
</style>
