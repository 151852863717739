import deepClone from '@/utils/dataManipulations';

const sixParticipants = {
  ep: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  mp: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  co: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  bu: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  sb: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  bb: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  }
};
const threeParticipants = {
  bu: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  sb: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  bb: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  }
};
const twoParticipants = {
  sb: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  bb: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  }
};
const eightParticipants = {
  ep1: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  ep2: {
    stack: 100,
    color: null,
    stats: 'string',
    notes: 'string'
  },
  ep3: {
    stack: 100,
    color: null,

    stats: 'string',
    notes: 'string'
  },
  mp: {
    stack: 100,
    color: null,

    stats: 'string',
    notes: 'string'
  },
  co: {
    stack: 100,
    color: null,

    stats: 'string',
    notes: 'string'
  },
  bu: {
    stack: 100,
    color: null,

    stats: 'string',
    notes: 'string'
  },
  sb: {
    stack: 100,
    color: null,

    stats: 'string',
    notes: 'string'
  },
  bb: {
    stack: 100,
    color: null,

    stats: 'string',
    notes: 'string'
  }
};

// const participants = [twoParticipants, threeParticipants, sixParticipants, eightParticipants]

const getTableParticipants = size => {
  console.log(size, 'size in getTableParticipants');
  let participants = {};
  switch (size) {
    case 2:
      participants = twoParticipants;
      break;
    case 3:
      participants = threeParticipants;
      break;
    case 6:
      participants = sixParticipants;
      break;
    case 8:
      participants = eightParticipants;
      break;

    default:
      break;
  }
  return deepClone(participants);
};
export default getTableParticipants;
