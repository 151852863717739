/* eslint-disable */

import VueCookies from 'vue-cookies';
import Vue from 'vue';

import {GET_LICENSE_PRICES} from '@/api/licenses';
import {GET_GLOBAL_PREFERENCES} from '@/api/globalPreferences';
import {
  GET_LANG_FAQ,
  GET_LANG_GAME_HELP,
  GET_LANG_GENERAL,
  GET_LANG_LANDING,
  GET_LANG_STORE
} from '@/api/localizations';

const LANGUAGE_RU = 'ru';
const LANGUAGE_ENG = 'eng';

function VALIDATE_LANGUAGE(lang) {
  if (lang === LANGUAGE_RU || lang === LANGUAGE_ENG) {
    return lang;
  }

  return LANGUAGE_ENG;
}

const state = {
  globalPreferences: null,
  actionColors: {
    fold: '',
    call: '',
    allin: '',
    check: '',
    raise: ''
  },
  table: null,
  userInfo: null,
  userEmail: null,
  userLevel: 0,
  licenses: [],
  prices: null,
  currentMainPage: 1,
};

const getters = {
  language: state => {
    if (state.language)
      return VALIDATE_LANGUAGE(state.language);

    if (localStorage['language'])
      return VALIDATE_LANGUAGE(localStorage['language']);

    let lang = window.navigator.userLanguage || window.navigator.language;
    lang = lang.slice(0, 2);
    if (lang == 'ru' || lang == 'be' || lang == 'uk') {
      return LANGUAGE_RU;
    } else {
      return LANGUAGE_ENG;
    }
  },
  licenses: state => {
    if (state.licenses) {
      return state.licenses;
    } else {
      state.licenses = VueCookies.get('userLicenses');
      return state.licenses;
    }
  },
  userLevel: state => {
    if (state.userLevel) {
      return state.userLevel;
    } else {
      state.userLevel = VueCookies.get('userLevel');
      return state.userLevel;
    }
  },
  actionColors: state => {
    return {
      call: state.actionColors.call || "#77DBA5",
      raise: state.actionColors.raise || "#F59A9A",
      allin: state.actionColors.allin || "#CF80D6",
      check: state.actionColors.check || "#F59A9A",
      fold: state.actionColors.fold || "#92E2FC",
    };
  },
  table: state => {
    return {
      tableColor: state.table?.tableColor ?? "#293042",
      backgroundColor: state.table?.backgroundColor ?? "#5d6270"
    };
  },
  localization: state => {
    return state.localization;
  },
  globalPreferences: state => {
    return state.globalPreferences;
  }
};

const actions = {
  SET_CURRENT_MAIN_PAGE({commit}, number) {
    commit('SET_CURRENT_MAIN_PAGE', number);
  },
  async LOAD_GLOBAL_PREFERENCES({commit}) {

    const data = await GET_GLOBAL_PREFERENCES();

    commit('play/SET_ACTIVE_MODE', data.gameMode, {root: true});
    commit('SET_ACTION_COLORS', data.actionColors);
    commit('SET_TABLE_COLORS', data.table);
    commit('SET_GLOBAL_PREFERENCES', data);
  }
};

const mutations = {
  SET_USER_INFO(state, DTO) {
    state.userInfo = DTO;
    state.userEmail = DTO.email;
  },
  SET_GLOBAL_PREFERENCES(state, data) {
    state.globalPreferences = data;
  },
  SET_CURRENT_MAIN_PAGE(state, number) {
    state.currentMainPage = number;
  },
  SET_ACTION_COLORS(state, value) {
    state.actionColors = value;
  },
  SET_TABLE_COLORS(state, value) {
    state.table = value;
  },
  SET_LANGUAGE: (state, data) => {
    state.language = data;
  },
  SET_LOCALIZATION_GENERAL: (state, data) => {
    state.localization.general = data;
  },
  SET_LOCALIZATION_FAQ: (state, data) => {
    state.localization.faq = data;
  },
  SET_LOCALIZATION_GAME_HELP: (state, data) => {
    state.localization.gameHelp = data;
  },
  SET_LOCALIZATION_STORE: (state, data) => {
    state.localization.store = data;
  },
  SET_LOCALIZATION_LANDING: (state, data) => {
    state.localization.landing = data;
  },
  SET_LEVEL(state, level) {
    state.userLevel = level;
  },
  SET_USER_LICENSES(state, licenses) {
    state.licenses = licenses;
  },
  SET_PRICES: (state, data) => {
    state.prices = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};