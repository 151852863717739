import Vue from 'vue'
import Router from 'vue-router'

// Import ADMIN pages
import AdminLicenses from './views/Dashboard/Admin/AdminLicenses'
import AdminBills from './views/Dashboard/Admin/AdminBills'
import AdminSubscriptions from './views/Dashboard/Admin/AdminSubscriptions'

import AdminUsersIndex from './views/Dashboard/Admin/Users/AdminUsersIndex'
import AdminUsersSingle from './views/Dashboard/Admin/Users/AdminUsersSingle'

import AdminLocalizationsEditor from './views/Dashboard/Admin/LocalizationsEditor'
import AdminPremiumRangeEditorTab from './views/Dashboard/Admin/PremiumRangeEditorTab'
import AdminUsersStatistics from './views/Dashboard/Admin/UsersStatistics'
import AdminLicenseDescriptors from './views/Dashboard/Admin/AdminLicenseDescriptors'

import NotFound from './views/NotFound'

import SignUp from '@/views/Auth/SignUp'
import SignIn from '@/views/Auth/SignIn'
import RestorePassword from '@/views/Auth/RestorePassword'

import Profile from '@/views/Dashboard/Profile'
import Admin from '@/views/Dashboard/Admin'
import Faq from '@/views/Dashboard/Faq'
import Game from '@/views/Game'
import Promo from '@/views/Promo'
import Courses from '@/views/Courses'
import CurrentCourse from '@/views/CurrentCourse'
import Dashboard from '@/views/Dashboard'
import Main from '@/views/Dashboard/Main'
import Price from '@/views/Dashboard/Price'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Promo',
            component: Promo,
            meta: {
                title: 'Prefloper',
            },
        },
        {
            path: '/courses',
            name: 'Courses',
            component: Courses,
            meta: {
                title: 'Prefloper',
            },
        },
        {
            path: '/course/:id',
            name: 'CurrentCourse',
            component: CurrentCourse,
            meta: {
                title: 'Prefloper',
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            children: [
                {
                    path: '/main',
                    name: 'Main',
                    component: Main,
                    meta: {
                        title: 'Main',
                    },
                },
                {
                    path: '/price',
                    name: 'Price',
                    component: Price,
                    meta: {
                        title: ' Price',
                    },
                },

                {
                    path: '/admin',
                    component: Admin,
                    meta: {
                        title: 'Admin',
                    },
                    children: [
                        {
                            path: '',
                            redirect: '/admin/users',
                        },
                        {
                            path: 'users',
                            name: 'Admin Users',
                            component: AdminUsersIndex,
                            meta: {
                                title: 'Admin Users',
                            },
                        },
                        {
                            path: 'users/:id',
                            name: 'Admin Users Single',
                            component: AdminUsersSingle,
                            meta: {
                                title: 'Admin Users Single',
                            },
                        },
                        {
                            path: 'licenses',
                            name: 'Admin Licenses',
                            component: AdminLicenses,
                            meta: {
                                title: 'Admin Licenses',
                            },
                        },
                        {
                            path: 'bills',
                            name: 'Admin Bills',
                            component: AdminBills,
                            meta: {
                                title: 'Admin Bills',
                            },
                        },
                        {
                            path: 'subscriptions',
                            name: 'Admin Subscriptions',
                            component: AdminSubscriptions,
                            meta: {
                                title: 'Admin Subscriptions',
                            },
                        },
                        {
                            path: 'statistics',
                            name: 'Admin User Statistics',
                            component: AdminUsersStatistics,
                            meta: {
                                title: 'Admin User Statistics',
                            },
                        },
                        {
                            path: 'premium-ranges',
                            name: 'Admin Premium Ranges Editor',
                            component: AdminPremiumRangeEditorTab,
                            meta: {
                                title: 'Admin Premium Ranges Editor',
                            },
                        },
                        {
                            path: 'localizations',
                            name: 'Admin Localizations Editor',
                            component: AdminLocalizationsEditor,
                            meta: {
                                title: 'Admin Localizations Editor',
                            },
                        },
                        {
                            path: 'license-descriptors',
                            name: 'Admin License Descriptors',
                            component: AdminLicenseDescriptors,
                            meta: {
                                title: 'Admin License Descriptors',
                            },
                        },
                    ],
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    component: Profile,
                    meta: {
                        title: 'Profile',
                    },
                },
                {
                    path: '/faq',
                    name: 'FaQ',
                    component: Faq,
                    meta: {
                        title: 'FaQ',
                    },
                },
            ],
        },

        {
            path: '/signup',
            name: 'Sign Up',
            component: SignUp,
            meta: {
                title: 'Sign up',
            },
        },
        {
            path: '/signin',
            name: 'Sign In',
            component: SignIn,
            meta: {
                title: 'Sign In',
            },
        },
        {
            path: '/restore-password',
            name: 'Restore Password',
            component: RestorePassword,
            meta: {
                title: 'Poker IQ',
            },
        },

        {
            path: '/notfound',
            name: '404',
            component: NotFound,
            meta: {
                title: 'Page not found',
            },
        },
        {
            path: '/game',
            name: 'Game',
            component: Game,
            meta: {
                title: 'Game',
            },
        },
        {
            path: '*',
            redirect: '/notfound',
        },
    ],
})

const DEFAULT_TITLE = 'Poker IQ'
router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = `${to.meta.title} | ${DEFAULT_TITLE}`
    })
})

export default router
