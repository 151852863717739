<template>
  <div
    :class="[
      'range-editor-wrapper',
      {
        'range-editor-wrapper--is-blocked': isBlocked
      }
    ]"
  >
    <slot name="BeforeRangeOptions" />
    <Expander persistent>
      <ExpanderItem class="range-editor__options">
        <template v-slot:header>
          {{ itemName }}
        </template>
        <template v-slot:content>
          <div>
            <ItemRangeOptionsEditor
              v-if="isRange"
              ref="itemRangeOptionsEditor"
              :diapason="computedRange"
              :isReadOnly="isReadOnly"
              :requestPending="updateItemRequestPending"
              @checkWeightToggle="onCheckWeightToggle"
              @updateItemOptions="onItemOptionsUpdate"
            />
            <ItemGroupOptionsEditor
              v-if="isGroup"
              ref="itemGroupOptionsEditor"
              :group="item"
              :isReadOnly="isReadOnly"
              :itemsProvider="loadLinkedItems"
              :requestPending="updateItemRequestPending"
              @linkedItemsChange="onLinkedItemsChange"
              @linkingModeToggle="onLinkingModeToggle"
              @updateItemOptions="onItemOptionsUpdate"
            />
          </div>
        </template>
      </ExpanderItem>

      <ExpanderItem>
        <template v-slot:header>
          Localization
        </template>
        <template v-slot:content>
          <PremiumItemLocalizationsEditor
            v-if="!isNullItem"
            ref="PremiumItemLocalizationsEditor"
            :itemLocalization="item.localization"
          />
        </template>
      </ExpanderItem>
      <ExpanderItem>
        <template v-slot:header>
          Availability Layer
        </template>
        <template v-slot:content>
          <PremiumItemAvailabilityEditor
            v-if="!isNullItem"
            ref="PremiumItemAvailabilityEditor"
            :layer="item.availabilityLayer"
            :price="item.price"
            :price-modifier="item.priceModifier"
          />
        </template>
      </ExpanderItem>
      <ExpanderItem>
        <template v-slot:header>
          Store product descriptors
        </template>
        <template v-slot:content>
          <StoreProductDescriptorEditor
            v-if="!isNullItem"
            ref="StoreProductDescriptorEditor"
            :descriptors="item.storeProductDescriptors"
          />
        </template>
      </ExpanderItem>
    </Expander>

    <div class="range-table-editor__actions">
      <div class="range-table-editor__actions__inputs">
        <RadioGroup
          v-model="selectionMode"
          class="range-table-editor__actions__radio-btns"
        >
          <Radio
            v-for="option in options"
            :key="option.label"
            :label="option.label"
            :value="option.value"
            class="range-table-editor__actions__radio-btns__item"
          />
        </RadioGroup>

        <div class="range-table-editor__actions__checkboxes">
          <Checkbox
            v-model="showStatistics"
            :label="localization.general.MainPage.ShowStatistics"
          />
        </div>
      </div>
      <div class="range-table-editor__actions__buttons">
        <DropdownMenu
          :header-label="localization.general.MainPage.Table"
          :items="menuItemsForTable"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
        <DropdownMenu
          :header-label="localization.general.MainPage.Clear"
          :items="clearMenuItems"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
      </div>
    </div>

    <WeightSlider
      ref="weightsSlider"
      :weight="weightsSlider"
      @weightSliderChanged="onWeightSliderChange"
    />
    <WeightsList
      ref="weightsList"
      :weights="weights"
      @weightSelected="weightSelected"
    />

    <slot name="AfterRangeOptions" />
    <slot name="BeforeTable" />

    <RangeTableEditor
      ref="rangeTableEditor"
      :actionColors="actionColors"
      :hands="rangeHands"
      :is-read-only="isGroup || isReadOnly"
      :range="computedRangeInfo"
      :selectionMode="selectionMode"
      :weight="computedSelectedWeights"
      @handsSelect="onHandsSelect"
    />

    <slot name="AfterTable" />

    <Slider
      ref="rangeSlider"
      :is-read-only="!isRange"
      :maxValue="169"
      :minValue="0"
      :step="0"
      :value="0"
      @rangeSliderChange="onRangeSliderChange"
    />
    <RangeStringParser
      :disabled="disableRangeStringParser"
      @rangeStringParse="onRangeStringParse"
    />

    <div class="range-editor__actions">
      <button class="range-editor__actions__btn-save" @click="applyChanges">
        {{ localization.general.MainPage.SaveDiapasonChanges }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  GET_PREMIUM_ITEMS_INFO,
  UPDATE_PREMIUM_ITEM_DIAPASON,
  UPDATE_PREMIUM_ITEM_GROUP
} from '@/api/premiumItems';

import StoreProductDescriptorEditor from '@/components/StoreProductDescriptorEditor';
import PremiumItemAvailabilityEditor from '@/components/PremiumItemAvailabilityEditor';
import PremiumItemLocalizationsEditor from '@/components/PremiumItemLocalizationsEditor';

import RangeEditorMixin from '../RangeEditorMixin';
import ItemGroupOptionsEditor from '@/components/ItemOptionsEditor/ItemGroupOptionsEditor';

export default {
  name: 'PremiumRangeEditor',
  mixins: [RangeEditorMixin],
  components: {
    ItemGroupOptionsEditor,
    PremiumItemLocalizationsEditor,
    PremiumItemAvailabilityEditor,
    StoreProductDescriptorEditor
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
    async loadLinkedItems(itemIDs) {
      return await GET_PREMIUM_ITEMS_INFO(itemIDs);
    },
    async applyChangesToGroup() {
      const appliedChangesToGroup = this.$refs.itemGroupOptionsEditor.submit();
      const appliedChangesToLocalizations = this.$refs.PremiumItemLocalizationsEditor.saveLocalizations();
      const appliedChangesToAvailabilityEditor = this.$refs.PremiumItemAvailabilityEditor.getChanges();
      const appliedChangesToDescriptorEditor = this.$refs.StoreProductDescriptorEditor.buildDescriptors();

      const linkedItemsExist = appliedChangesToGroup.linkedItems;
      const linksGroup = linkedItemsExist
        ? {
            items: appliedChangesToGroup.linkedItems
          }
        : null;

      return new Promise((resolve, reject) => {
        if (!appliedChangesToGroup.validated) {
          reject('NotValidated');
          return;
        }

        UPDATE_PREMIUM_ITEM_GROUP({
          name: appliedChangesToGroup.name,
          linksGroup: linksGroup,
          storeProductDescriptors: appliedChangesToDescriptorEditor,
          localization: appliedChangesToLocalizations,
          availabilityLayer: appliedChangesToAvailabilityEditor.layer,
          priceModifier: appliedChangesToAvailabilityEditor.priceModifier,
          price: appliedChangesToAvailabilityEditor.price,
          id: this.item.id,
          parentId: this.item.parentId
        })
          .then(() => {
            this.item.name = appliedChangesToGroup.name;
            this.item.linksGroup = linkedItemsExist
              ? {
                  items: appliedChangesToGroup.linkedItems
                }
              : null;

            this.item.priceModifier =
              appliedChangesToAvailabilityEditor.priceModifier;
            this.item.availabilityLayer =
              appliedChangesToAvailabilityEditor.layer;
            this.item.price = appliedChangesToAvailabilityEditor.price;
            this.item.priceModifier =
              appliedChangesToAvailabilityEditor.priceModifier;
            this.item.localization = appliedChangesToLocalizations;

            this.item.storeProductDescriptors = appliedChangesToDescriptorEditor;
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    applyChangesToRange() {
      const appliedChangesToLocalizations = this.$refs.PremiumItemLocalizationsEditor.saveLocalizations();
      const appliedChangesToAvailabilityEditor = this.$refs.PremiumItemAvailabilityEditor.getChanges();
      const appliedChangesToDescriptorEditor = this.$refs.StoreProductDescriptorEditor.buildDescriptors();
      const appliedChangesToCurrentItem = this.$refs.rangeTableEditor.applyChanges();
      const appliedChangesToDiapason = this.$refs.itemRangeOptionsEditor.submit();

      return new Promise((resolve, reject) => {
        if (
          appliedChangesToCurrentItem.validated &&
          appliedChangesToDiapason.validated
        ) {
          reject('NotValidated');
          return;
        }

        UPDATE_PREMIUM_ITEM_DIAPASON({
          name: appliedChangesToDiapason.data.name,
          additionalProperties:
            appliedChangesToDiapason.data.additionalProperties,
          tableFormat: appliedChangesToDiapason.data.tableFormat,
          participants: appliedChangesToDiapason.data.participants,

          storeProductDescriptors: appliedChangesToDescriptorEditor,
          localization: appliedChangesToLocalizations,
          availabilityLayer: appliedChangesToAvailabilityEditor.layer,
          priceModifier: appliedChangesToAvailabilityEditor.priceModifier,
          price: appliedChangesToAvailabilityEditor.price,
          id: this.item.id,
          parentId: this.item.parentId,
          statistics: appliedChangesToCurrentItem.data.statistics,
          hands: appliedChangesToCurrentItem.data.hands,
          weights: []
        })
          .then(() => {
            this.item.priceModifier =
              appliedChangesToAvailabilityEditor.priceModifier;

            this.item.availabilityLayer =
              appliedChangesToAvailabilityEditor.layer;

            this.item.price = appliedChangesToAvailabilityEditor.price;

            this.item.localization = appliedChangesToLocalizations;
            this.item.storeProductDescriptors = appliedChangesToDescriptorEditor;
            this.item.statistics = appliedChangesToCurrentItem.data.statistics;

            this.item.diapason.hands = appliedChangesToCurrentItem.data.hands;
            this.item.diapason.participants =
              appliedChangesToDiapason.data.participants;
            this.item.diapason.additionalProperties =
              appliedChangesToDiapason.data.additionalProperties;

            resolve(true);
          })
          .catch(error => {
            reject(error);
          });

        this.$emit('currentItemChange', appliedChangesToAvailabilityEditor);
      });
    }
  }
};
</script>

<style lang="scss" src="./PremiumRangeEditor.scss" />
