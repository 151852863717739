<template>
  <Dialog
    ref="dialog"
    v-model="computedValue"
    class="range-preview-dialog range-preview-dialog--height-large range-preview-dialog--width-large"
  >
    <DialogHeader>
      {{ rangeName }}
    </DialogHeader>
    <DialogContent class="range-preview-dialog__content">

      <RangeTableEditor
        ref="rangeTableEditor"
        :actionColors="actionColors"
        :hands="rangeHands"
        :highlightHands="highlightHands"
        :is-read-only="true"
        :showStatistics="false"
      />

    </DialogContent>
    <DialogFooter>
      <Btn
        class="range-preview-dialog__close"
        @click="$refs.dialog.closeModal()"
      >
        {{ localization.general.Simple.Close }}
      </Btn>
    </DialogFooter>
  </Dialog>
</template>
<script>

import RangeTableEditor from "@/components/RangeTableEditor";

import {Dialog, DialogContent, DialogFooter, DialogHeader} from "@/components/UI/Dialog";
import {mapGetters} from "vuex";
import Btn from "@/components/UI/Btn";

export default {
  name: "RangePreviewDialog",
  data: function () {
    return {}
  },
  props: {
    range: {
      type: Object
    },
    highlightHands: Array,
    value: {
      type: Boolean
    }
  },
  components: {DialogFooter, Btn, Dialog, DialogContent, DialogHeader, RangeTableEditor},

  computed: {
    ...mapGetters({actionColors: 'user/actionColors'}),
    rangeExists() {
      return !!this.range
    },
    rangeName() {
      if (!this.rangeExists) return

      return this.range.name;
    },
    rangeValue() {
      if (!this.rangeExists) return
      return this.range.diapason;
    },
    rangeHands() {
      return this.rangeValue?.hands;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
}
</script>
<style lang="scss" src="./RangePreviewDialog.scss"/>