var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'card',
    {
      'card--undefined': _vm.undefinedCard,
      'card--no-card': !(_vm.name && _vm.suit)
    }
  ]},[(!_vm.undefinedCard)?[_c('img',{staticClass:"card__suit-icon card__suit-icon--top",attrs:{"src":_vm.iconUrl}}),_c('div',{staticClass:"card__name"},[_vm._v(" "+_vm._s(_vm.computedName)+" ")]),_c('img',{staticClass:"card__suit-icon card__suit-icon--bottom",attrs:{"src":_vm.iconUrl}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }