var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"main-row"},[_c('div',{class:[
        'ranges-list',
        {
          'hidden-sm': _vm.rangesFooterCurrentView !== _vm.rangesFooterViewList,
          'visible-sm': _vm.rangesFooterCurrentView === _vm.rangesFooterViewList
        }
      ]},[_c('div',{staticClass:"ranges-list__actions"},[_c('DropdownMenu',{staticClass:"ranges-list__actions__item",attrs:{"header-label":_vm.localization.general.MainPage.CreateItemButton,"items":_vm.menuItemsForCreating,"background-color":"blue","height":"38"}}),_c('button',{staticClass:"ranges-list__actions__item ranges-list__actions__item--delete",attrs:{"disabled":_vm.selectedItems.length === 0 && !_vm.currentItem},on:{"click":function($event){return _vm.openDeleteDialog()}}},[_vm._v(" "+_vm._s(_vm.localization.general.MainPage.DeleteItemButton)+" ")])],1),_c('List',{ref:"rangesList",staticClass:"ranges-list__items",attrs:{"childrenProvider":_vm.getItems,"sortFunction":_vm.sortItems},on:{"currentItemChange":_vm.onCurrentItemChange,"groupOpen":_vm.onGroupOpen,"itemParentChange":_vm.onItemParentChange,"selectedItemsChange":_vm.onSelectedItemsChange},scopedSlots:_vm._u([{key:"card-outer",fn:function(item){return [(_vm.linkingMode && item.type === _vm.ITEM_TYPE_RANGE)?_c('div',{staticClass:"ranges-list__items__disabled"}):_vm._e()]}},{key:"card-body-content",fn:function(item){return [(item.name === _vm.ITEM_TYPE_STORE)?_c('div',{staticClass:"store"}):_vm._e(),(item.purchaseInfo)?_c('div',{staticClass:"purchased"}):_vm._e()]}},{key:"card-inner",fn:function(item){return [(item.name === _vm.ITEM_TYPE_STORE)?[_c('div',{staticClass:"list__item__card__label"},[_vm._v(" "+_vm._s(_vm.localization.general.MainPage.PremiumRangesFolder)+" ")])]:[_c('div',{staticClass:"list__item__card__label"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.hasOwnProperty('diapason'))?_c('div',{staticClass:"list__item__card__diapason__info"},[(item.type === _vm.ITEM_TYPE_GROUP && _vm.linkingMode)?_c('div',{on:{"click":function($event){$event.stopPropagation();
                  _vm.linkItem(item);}}},[(_vm.itemIsLinked(item))?_c('img',{staticClass:"list__item__card__diapason__link-to-group",attrs:{"src":"/icons/close-wrapped.svg"}}):_c('img',{staticClass:"list__item__card__diapason__link-to-group",attrs:{"src":"/icons/plus.svg"}})]):_vm._e(),(item.hasOwnProperty('price') && item.price !== null)?_c('div',{staticClass:"list__item__card__diapason__info__price"},[_vm._v(" "+_vm._s(item.price.value)+"$ ")]):_vm._e(),(
                  item.statistics.correct > 0 || item.statistics.incorrect > 0
                )?_c('div',{staticClass:"list__item__card__diapason__info__answers"},[_c('div',{staticClass:"incorrect"},[_vm._v(" "+_vm._s(item.statistics.incorrect)+" ")]),_c('div',{staticClass:"correct"},[_vm._v(" "+_vm._s(item.statistics.correct)+" ")])]):_vm._e()]):_vm._e()]]}}],null,false,788169263)}),_c('div',{staticClass:"ranges-statistics hide-sm"},[_c('div',{staticClass:"ranges-statistics__values"},[_c('div',{staticClass:"ranges-statistics__values__item"},[_c('b',[_vm._v(_vm._s(_vm.localization.general.MainPage.RangesStatistics))]),_vm._v(" "+_vm._s(_vm.itemsStatistics.regular.diapasons)+" (+"+_vm._s(_vm.itemsStatistics.premium.diapasons)+") ")]),_c('div',{staticClass:"ranges-statistics__values__item"},[_c('b',[_vm._v(_vm._s(_vm.localization.general.MainPage.GroupsStatistics))]),_vm._v(" "+_vm._s(_vm.itemsStatistics.regular.groups)+" (+"+_vm._s(_vm.itemsStatistics.premium.groups)+") ")])])])],1),_c('div',{class:[
        'range-editor',
        {
          'hidden-sm': _vm.rangesFooterCurrentView !== _vm.rangesFooterViewEditor,
          'visible-sm': _vm.rangesFooterCurrentView === _vm.rangesFooterViewEditor
        }
      ]},[(_vm.itemIsPremium)?_c('StoreRangeViewer',{attrs:{"item":_vm.currentItem}}):_c('DefaultRangeEditor',{ref:"rangeEditor",attrs:{"is-blocked":_vm.isBlocked,"item":_vm.currentItem,"rangesOfGroup":_vm.rangesOfGroup},on:{"clearStatisticsOfGroup":_vm.onClearStatisticsOfGroup,"linkedItemsChange":_vm.onLinkedItemsChange,"linkingModeToggle":_vm.onLinkingModeToggle,"startTest":function($event){return _vm.startTest()},"setActiveParticipantsToPlay":_vm.setActiveParticipantsToPlay}})],1)]),_c('RangeViewChanger',{on:{"changeView":_vm.changeRangesFooterView}}),_c('CreateGroupDialog',{attrs:{"handler":_vm.createGroup},model:{value:(_vm.showCreateGroupDialog),callback:function ($$v) {_vm.showCreateGroupDialog=$$v},expression:"showCreateGroupDialog"}}),_c('CreateRangeDialog',{attrs:{"handler":_vm.createRange},model:{value:(_vm.showCreateRangeDialog),callback:function ($$v) {_vm.showCreateRangeDialog=$$v},expression:"showCreateRangeDialog"}}),_c('DeleteItemsDialog',{attrs:{"handler":_vm.deleteItems,"items":_vm.computedSelectedItems},on:{"itemsDelete":function($event){return _vm.onItemsDelete()}},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }