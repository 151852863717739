<template>
  <div class="container faq-container">
    <FaqExpanderList :items="items" />
  </div>
</template>

<script>
import FaqExpanderList from '../../components/FaqExpanderList/FaqExpanderList.vue';
import marked from 'marked';

export default {
  components: {
    FaqExpanderList
  },
  computed: {
    items() {
      let buffer = this.localization.faq.faqItems;
      const result = [];

      if (Array.isArray(buffer)) {
        buffer.forEach(value => {
          if (value.type === 'group') {
            if (value.nodes) {
              const group = value;
              value.nodes.forEach(topic => {
                let item = {};
                item.title = topic.name;
                console.log(topic.content, item.name, 'TOPIC CONTENT');
                item.value = marked(topic.content);
                item.category = group.name;

                result.push(item);
              });
            }
          }
        });
      }
      console.log('FAQ ITEMS', result);

      return result;
    }
  }
};
</script>

<style scoped>
.faq-container {
  margin: 43px auto;
  width: 80%;
}
</style>
