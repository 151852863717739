var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-toggler__row"},_vm._l((_vm.computedItems),function(item){return _c('button',{key:item.value,class:[
      'c-toggler__btn',
      {
        'c-toggler__btn--stretch': _vm.stretched,
        'c-toggler__btn--active': _vm.activeItem === item[_vm.itemValue]
      }
    ],on:{"click":function($event){return _vm.updateItem(item[_vm.itemValue])}}},[_vm._v(" "+_vm._s(item[_vm.itemLabel])+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }