<template>
  <div
    v-show="showAlertInfoAboutWar"
    class="popup"
  >
    <div
      class="popup__close"
      @click="showAlertInfoAboutWar = false"
    >
      &#x2715;
    </div>
    <div class="popup__wrapper">
      <h3 class="popup__title">Россия напала на Украину и ежедневно убивает мирных жителей</h3>
      <p>
        Об этом не рассказывают российские сайты и телеканалы:
      </p>
      <ul>
        <li>Российская армия разрушает жилые дома и <a href="https://rus.ozodi.org/a/31728241.html">расстреливает женщин
          и детей</a>.
        </li>
        <li>За первые дни войны <a
          href="https://ru.krymr.com/a/rossiya-ukraina-voyna-minoborony-rossii-poteri-ubitiye-rossiyskiye-soldaty/31726793.html">Россия
          потеряла</a> больше 14 000 солдат и тысячи единиц техники, солдаты <a href="https://200rf.com">добровольно
          сдаются в плен</a>. (<a href="https://t.me/rf200_now">Ищи своих</a>)
        </li>
        <li>Россия фактически отрезана от мировой финансовой системы, курс рубля стремительно <a
          href="https://www.bbc.com/russian/news-60531329">падает</a>.
        </li>
        <li>Ищите информацию в Telegram: <a href="https://t.me/nexta_live">NEXTA</a>, <a
          href="https://t.me/radiosvoboda">Радио Свобода</a>, <a href="https://t.me/meduzalive">Медуза</a>, <a
          href="https://t.me/mediazzzona">Медиазона</a>.
        </li>
        <li>Российское руководство ввело тотальную цензуру на все независимые источники информации.</li>
      </ul>

      <h4>Это не ваша война! Выходите в центр города и протестуйте, пока Путин не разрушил жизнь вашей семьи.</h4>

      <h4>Узнайте правду о войне:</h4>
      <ul>
        <li><a href="https://novayagazeta.ru/">Новая Газета</a></li>
        <li><a href="https://meduza.io/">Медуза</a></li>
        <li><a href="https://www.bbc.com/russian">Русская Служба BBC</a></li>
        <li><a href="https://zona.media/">Медиазона</a></li>
      </ul>

      <h4>Читайте каналы в Telegram:</h4>
      <ul>
        <li><a href="https://t.me/nexta_live">NEXTA</a></li>
        <li><a href="https://t.me/meduzalive">Медуза</a></li>
        <li><a href="https://t.me/radiosvoboda">Радио Свобода</a></li>
        <li><a href="https://t.me/mediazzzona">Медиазона</a></li>
      </ul>
      <hr>

      <p>
        <a href="https://github.com/piranha/infowarship">Исходный код</a>,
        если хотите улучшить свой сайт.
      </p>
    </div>

  </div>

</template>

<script>

export default {
  name: "WarPopup",
  data() {
    return {
      showAlertInfoAboutWar: false,
    }
  },
  async mounted() {
    function jsonp(url, callback) {
      let name = "_jsonp_" + (Math.random() + 1).toString(36).substring(7);
      let sep = url.match(/\?/) ? '&' : '?';
      url = url + sep + 'callback=' + name;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      // callback
      window[name] = function (data) {
        callback.call(window, data);
        document.head.removeChild(script);
        script = null;
        delete window[name];
      };
      // Load JSON
      document.head.appendChild(script);
    }

    if (this.$route.name === 'Main' || this.$route.name === 'Promo') {
      let countryOfUser = localStorage.getItem('countryOfUser');

      if (!countryOfUser) {
        jsonp('https://wcayf.piranha.workers.dev', (data) => {
          countryOfUser = data.country;
          localStorage.setItem('countryOfUser', countryOfUser);

          if (countryOfUser === "RU" || countryOfUser === "BLR") {
            this.showAlertInfoAboutWar = true;
          }
        });
      }
      if (countryOfUser === "RU" || countryOfUser === "BLR") {
        this.showAlertInfoAboutWar = true;
      }
    }

  },
}
</script>
<style lang="scss" src="./WarPopup.scss"/>