import http from '../utils/http'

export const GET_CURRENT_COURSE = async id => {
    const { data } = await http.get(`/Courses/Personal/${id}`)
    return data
}

export const GET_OUR_COURSES = async () => {
    const { data } = await http.get(`/Courses/Personal/`, {
        params: { primary: true },
    })
    return data
}

export const GET_PARTNERS_COURSES = async type => {
    const { data } = await http.get(`/Courses/Personal/`, {
        params: { primary: false, type },
    })
    return data
}
