// const getSecond = (type) => (type == "raise" ? "call" : "raise");

// export const GET_CURRENT_WEIGHT = (primary, size, oldWeight) => {
//     let result = {};
//     if (!oldWeight) {
//         result[primary] = size;
//         result[getSecond(primary)] = 0;
//         result["fold"] = 100 - size;
//         return result;
//     }
//     let weight = oldWeight;

//     if (weight == null) {
//         weight = {
//             raise: 0,
//             call: 0,
//             fold: 0,
//         };
//     }
//     let oldP = weight[primary];
//     let oldS = weight[getSecond(primary)];
//     let oldF = weight["fold"];

//     if (oldP == 0 && oldS == 0 && size == 100) {
//         result[primary] = size;
//         result[getSecond(primary)] = 0;
//         result["fold"] = 0;
//         return result;
//     }
//     if (oldP == 0 && oldS == 0 && size < 100) {
//         result[primary] = size;
//         result[getSecond(primary)] = 0;
//         result["fold"] = 100 - size;
//         return result;
//     }
//     if (oldP > size) {
//         result[primary] = size;
//         result[getSecond(primary)] = oldS;
//         result["fold"] = oldF + (oldP - size);
//         return result;
//     }
//     if (oldP < size) {
//         result[primary] = size;
//         if (oldF > size - oldP) {
//             result[getSecond(primary)] = oldS;
//             result["fold"] = oldF - (size - oldP);
//             return result;
//         } else {
//             result["fold"] = 0;
//             result[getSecond(primary)] = oldS - (size - oldP - oldF);

//             return result;
//         }
//     }
//     if (oldP == size) {
//         return weight;
//     }
// };

const getWeight = (type) => {
  switch (type) {
    case "allin":
      return ["allin", "raise", "call", "fold"];
    case "raise":
      return ["raise", "allin", "call", "fold"];
    case "call":
      return ["call", "allin", "raise", "fold"];
  }
};
export const GET_CURRENT_WEIGHT = (type, size, oldWeight) => {
  const [primary, secondary, third] = getWeight(type);
  let result = {
    [primary]: 0,
    [secondary]: 0,
    [third]: 0,
    fold: 0,
  };
  if (size > 100) {
    size = 100;
  }
  if (size == 100) {
    result[primary] = 100;
    return result;
  }
  if (!oldWeight) {
    result[primary] = size;
    result["fold"] = 100 - size;
    return result;
  }
  result = oldWeight;

  result[primary] = size;
  let total = result[primary] + result[secondary] + result[third] + result["fold"];
  let diff = 100 - total;
  if (diff == 0) {
    return result;
  } else if (diff > 0) {
    result["fold"] = diff;
    return result;
  } else {
    diff = Math.abs(diff);
    let order = ["fold", third, secondary];
    order.forEach(e => {
      if (diff == 0)
        return;
      let localValue = result[e];
      if (localValue > 0) {
        let globalDiff = diff - localValue;
        if (globalDiff > 0) {
          result[e] = result[e] - localValue;
          diff = diff - localValue;
        } else {
          result[e] = result[e] - diff;
          diff = 0;
        }
      }
    });
    return result;
  }
};
