<template>

  <div
    :class="[
      'modal-dialog',
      {
        'modal-dialog--active': value
      }
    ]"
  >

    <div class="modal-dialog__container">
      <div class="modal-dialog__overlay" @click="closeModal"/>
      <div class="modal-dialog__content">
        <slot/>
      </div>
    </div>

  </div>

</template>


<script>

export default {
  name: 'Dialog',
  data() {
    return {};
  },
  props: {
    value: {
      type: Boolean
    },
    persistent: {
      default: false,
      type: Boolean
    },
  },
  methods: {
    closeModal() {
      if (!this.persistent) this.$emit('input', false);
    }
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) this.$emit('input', false);
    });


  },
  destroyed() {
    document.onkeypress = null;
  }
}

</script>

<style lang="scss" src="./Dialog.scss"/>

