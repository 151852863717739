<template>
  <div class="premium-items-selector__row">
    <div
      v-for="item in items"
      :key="item.id"
      class="premium-items-selector__row__item"
    >
      <div class="premium-items-selector__row__item__checkbox">
        <label class="premium-items-selector__row__item__checkbox__container">
          <input
            v-model="selectedItems"
            :value="item.id"
            type="checkbox"
          >
          <span class="premium-items-selector__row__item__checkbox__container__checkmark"></span>
        </label>
      </div>
      <div class="premium-items-selector__row__item__card">
        <div class="premium-items-selector__row__item__card__content">
          <div
            v-if="item.type === 'Group'"
            class="list__item__card__group"
          >
            <img src="/img/group-icon_20.svg"/>
          </div>
          {{ item.name }}
        </div>

      </div>


    </div>
  </div>

</template>

<script>


export default {
  name: 'RangesRow',
  props: {
    passedItems: Array
  },
  data() {
    return {
      items: [],
      selectedItems: []
    };
  },
  methods: {
    emitSelectItems() {
      this.$emit('itemSelect', this.selectedItems);
    },
    getItems() {
      return this.selectedItems;
    },
    deleteSelectedItems() {
      this.selectedItems.forEach(selectedItem => {
        const itemIndex = this.items.findIndex(item => item.id === selectedItem)
        this.items.splice(itemIndex, 1);
      });
      this.selectedItems = [];
      this.emitSelectItems();
    }
  },

  watch: {
    passedItems(value) {
      this.items = value
    }
  },

  async mounted() {

  },

};
</script>
<style lang="scss" src="./RangesRow.scss"/>
